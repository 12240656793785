import { useState, useEffect } from "react";
import { getCompany } from "../../API/blog.api";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Image,
  Row,
} from "react-bootstrap";
import Swal from "sweetalert2";
import SelectSearch, { fuzzySearch } from "react-select-search";
const CompanyInput = ({ formData, setFormData }) => {
  const [companyList, setcompanyList] = useState("");
  const [companyData, setcompanyData] = useState([]);
  const [nocompany, setnocompany] = useState(false);

  useEffect(() => {
    getcompany();
  }, []);

  const getcompany = async () => {
    await getCompany()
      .then((response) => {
        console.log(response.data);
        setcompanyList(response.data.data);
        if (response.status === 200) {
          var temp = [];
          var temp2 = [];
          for (var i = 0; i < response.data.data.length; i++) {
            var obj = {};
            console.log(response.data.data[i].company_name);
            obj["name"] = response.data.data[i].company_name;
            obj["value"] = response.data.data[i]._id;
            temp.push(obj);
          }
          setcompanyData(temp);
        }
        // set_loader(false)
      })
      .catch((err) => [
        //    set_loader(false),
        Swal.fire("", err.response.data.message, "warning"),
      ]);
  };

  return (
    <Row className="my-4">
      <Col md={12} className="">
        <FormGroup className="srch_agent mb-0">
          <Form.Label className="contact-form-label">
            How did you hear about us?
          </Form.Label>
        </FormGroup>
      </Col>

      <Col xl={8} lg={12} md={12}>
        <SelectSearch
          options={companyData}
          value={formData.company_id}
          onChange={(e) => {
            setFormData({
              ...formData,
              company_id: e,
              company_name: "",
              comapany_email: "",
            });
          }}
          search
          disabled={nocompany}
          filterOptions={fuzzySearch}
          emptyMessage="No data found"
          placeholder="Search company"
        />
      </Col>
      <Col xl={4} lg={12} md={12} className="agent_not_found">
        <input
          type="checkbox"
          name="noagent"
          id="noagent"
          onChange={(e) => setnocompany(e.target.checked)}
        />
        <label
          for="nocompany"
          className="form-check-label company-txt"
          style={{ color: "#1D83FF" }}
        >
          {" "}
          Company not on list
        </label>
      </Col>

      {nocompany ? (
        // <Row>
        <>
          <Col md={6} className="mt-3 mb-0 col-md-6">
            <FormGroup>
              <Form.Label className="contact-form-label mb-0">
                Reference Company Name
              </Form.Label>
            </FormGroup>
          </Col>
          <Col xl={8} lg={12} md={12}>
            <Form.Control
              type="text"
              placeholder="Enter reference company name here"
              className="contact-form-input"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  company_name: e.target.value,
                  company_id: "",
                })
              }
              required
            ></Form.Control>
          </Col>
        </>
      ) : null}
    </Row>
  );
};

export default CompanyInput;
