import React, { useEffect, useState } from "react";
import {
  Button,
  Image,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  Dropdown,
  Accordion,
  Card,
  Collapse,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import reasources
import axios from "axios";
import Logo from "../../assets/images/logo/logo.png";
import "../../assets/css/header.css";
import { signout } from "../../app/api/auth/actions";

import DefaultAvatar from "../../assets/images/avatars/default.png";
import UserIcon from "../../assets/images/icons/user_icon.svg";
import EmailIcon from "../../assets/images/icons/email_icon.svg";
import RoleIcon from "../../assets/images/icons/user_role_icon.svg";
import test from "../../views/depreciation/order";
import { FlashOnRounded } from "@material-ui/icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import validator from "validator";
import QsModal from "../QsModal";
import DilapidationModal from "../Dilapidation";
import PoolInspectionModal from "../PoolInspection";
import HandOverInspectionModal from "../Handover-inspection";
import StageInspectionModal from "../stage-inspection";
import { Helmet } from 'react-helmet';

function Header() {
  /**
   * toggler
   */
  // console.log(localStorage.getItem('userId'));
  const [toggler, setToggler] = React.useState(false);
  /**
   * redux dispatch
   */
  const [User, setUser] = React.useState([]);
  const [show, set_show] = useState(false);
  const [dilapidationShow, setDilapidationShow] = useState(false);
  const [poolInspectionShow, setPoolInspectionShow] = useState(false);
  const [handoverInspectionShow, setHandoverInspectionShow] = useState(false);
  const [stageInspectionShow, setStageInspectionShow] = useState(false);
  const [UserInfo, setUserInfo] = React.useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  /**
   * set useHistroy of React-Redux
   */
  let history = useHistory();
  const user = useSelector((state) => state.auth.user);

  /**
   * set Mobile view or desktop view
   */
  const [openDrawer, setOpenDrawer] = React.useState(false);

  // const [formData, setFormData] = useState({
  //     full_name: '',
  //     mobile: "",
  //     email: "",
  //     message: ""
  // })

  /**
   * identify login or logout
   */
  const [isLogin, setLogin] = React.useState(
    localStorage.getItem("tokens") !== null
  );
  /**
   * if the user click logout button this function will be run
   */
  function handleLogOut() {
    setLogin(false);
    localStorage.clear();
    dispatch(signout());
    history.push("/login");
  }

  const handleValuation = (e, type) => {
    history.push(`/valuation?type=${type}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * rendering view
   */
  function handleModal() {
    set_show(true);
  }

  function handlDilapidationModal() {
    setDilapidationShow(true);
  }
  function handlPoolInspectionModal() {
    setPoolInspectionShow(true);
  }
  function handlHandoverInspectionModal() {
    setHandoverInspectionShow(true);
  }
  function handlStageInspectionModal() {
    console.log("---->",true)
    setStageInspectionShow(true);
  }

  return (
    <>
    <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div>
        <Navbar className="justify-content-between">
          <Link to="/">
            <Navbar.Brand>
              <Image
                src={window.location.origin + Logo}
                className="logo-image"
                alt="img"
              ></Image>
            </Navbar.Brand>
          </Link>

          <div className="navbar-desktop-menu w-100">
            <Nav className="mr-auto justify-content-between align-items-center nav_theme">
              <Link to={link_item[0].link} className={link_item[0].className}>
                {link_item[0].name}
              </Link>
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                  className={link_item[1].className}
                >
                  {link_item[1].name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as="div" className="navbar-desktop-link">
                    <Link
                      to={"/strata-report"}
                      className={link_item[1].className}
                    >
                      Strata report
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" className="navbar-desktop-link">
                    <Link to={"/Inspection"} className={link_item[1].className}>
                      Building & pest inspection
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="div"
                    className="navbar-desktop-link valuation-submenu"
                  >
                    <Link className={link_item[1].className}>
                      Valuation{" "}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Link>
                    <nav className="submenu">
                      <ul>
                        <li
                          onClick={(e) =>
                            handleValuation(e, "Family_Law/Property-Settlement")
                          }
                        >
                          Family law / Property settlement
                        </li>
                        <li
                          onClick={(e) =>
                            handleValuation(e, "Pre-purchase-advise")
                          }
                        >
                          Pre-purchase advise
                        </li>
                        <li
                          onClick={(e) =>
                            handleValuation(e, "Mortage-Security(First/Second)")
                          }
                        >
                          Mortgage security (First / Second)
                        </li>
                        <li
                          onClick={(e) =>
                            handleValuation(e, "Strata-unit-entitlement")
                          }
                        >
                          Strata unit entitlement
                        </li>
                        <li
                          onClick={(e) =>
                            handleValuation(e, "Taxation(Stamp_Duty_/_CGT)")
                          }
                        >
                          Taxation (Stamp Duty / CGT)
                        </li>
                        <li
                          onClick={(e) =>
                            handleValuation(e, "Internal-advise/Audit")
                          }
                        >
                          Internal advise / audit
                        </li>
                        <li
                          onClick={(e) =>
                            handleValuation(e, "insurance_assesment")
                          }
                        >
                          Insurance assessment{" "}
                        </li>
                        <li onClick={(e) => handleValuation(e, "desktop")}>
                          Desktop
                        </li>
                        <li onClick={(e) => handleValuation(e, "Others")}>
                          Others
                        </li>
                      </ul>
                    </nav>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" className="navbar-desktop-link">
                    <Link
                      to={"/depreciation-report"}
                      className={link_item[1].className}
                    >
                      Depreciation report
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" className="navbar-desktop-link">
                    <p
                      onClick={() => handleModal()}
                      style={{ cursor: "pointer" }}
                      className={link_item[1].className}
                    >
                      QS report
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" className="navbar-desktop-link">
                    <p
                      onClick={() => handlDilapidationModal()}
                      style={{ cursor: "pointer" }}
                      className={link_item[1].className}
                    >
                      Dilapidation report
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" className="navbar-desktop-link">
                    <p
                      onClick={() => handlPoolInspectionModal()}
                      style={{ cursor: "pointer" }}
                      className={link_item[1].className}
                    >
                      Pool inspection & certificate
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" className="navbar-desktop-link">
                    <p
                      onClick={() => handlHandoverInspectionModal()}
                      style={{ cursor: "pointer" }}
                      className={link_item[1].className}
                    >
                      Handover inspection
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" className="navbar-desktop-link">
                    <p
                      onClick={() => handlStageInspectionModal()}
                      style={{ cursor: "pointer" }}
                      className={link_item[1].className}
                    >
                      Stage inspection
                    </p>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Link to={link_item[2].link} className={link_item[2].className}>
                {link_item[2].name}
              </Link>
              <Link to={link_item[3].link} className={link_item[3].className}>
                {link_item[3].name}
              </Link>

              {localStorage.getItem("userId") != null ? (
                <Link to={link_item[5].link} className={link_item[5].className}>
                  {link_item[5].name}
                </Link>
              ) : (
                ""
              )}
              <Link to={link_item[8].link} className={link_item[8].className}>
                {link_item[8].name}
              </Link>
              <Link to={link_item[6].link} className={link_item[6].className}>
                {link_item[6].name}
              </Link>
            </Nav>
          </div>

          {/* Mobile View */}

          <div className="navbar-mobile-menu">
            <Button
              onClick={() => {
                setOpenDrawer(!openDrawer);
              }}
              className="navbar-mobile-menu-button"
              data-toggle="collapse"
              data-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="black"
                className="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </Button>
          </div>
        </Navbar>
        {openDrawer && (
          <div className="navbar-mobile-side-menu">
            <ListGroup>
              <Link to={link_item[0].link}>
                <ListGroupItem
                  onClick={() => {
                    setOpenDrawer(false);
                  }}
                  className="navbar-mobile-side-menu-item  text-muted"
                >
                  {link_item[0].name}
                </ListGroupItem>
              </Link>
              <ListGroupItem className="navbar-mobile-side-menu-item text-muted">
                <Accordion>
                  <Card className="border-0 px-0 bg-transparent">
                    <Card.Header className="border-0 bg-transparent px-0 py-1">
                      <Accordion.Toggle as={"div"} variant="link" eventKey="0">
                        {link_item[1].name}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="mobile-head">
                        <Link
                          to={"/strata-report"}
                          className="text-decoration-none text-muted"
                          onClick={() => {
                            setOpenDrawer(false);
                          }}
                        >
                          Strata report
                        </Link>
                        <Link
                          to={"/inspection"}
                          className="text-decoration-none text-muted"
                          onClick={() => {
                            setOpenDrawer(false);
                          }}
                        >
                          Building & pest inspection
                        </Link>
                        <Link
                          className="text-decoration-none text-muted valuation-mob"
                          onClick={() => setOpen(!open)}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                        >
                          Valuation
                          <i
                            class="fa fa-angle-down mobile-angle-down"
                            aria-hidden="true"
                          ></i>
                        </Link>
                        <Collapse in={open}>
                          <div id="example-collapse-text">
                            <ul className="mobile-valuation">
                              <li
                                onClick={(e) =>
                                  handleValuation(
                                    e,
                                    "Family_Law/Property-Settlement"
                                  )
                                }
                              >
                                Family law / Property settlement
                              </li>
                              <li
                                onClick={(e) =>
                                  handleValuation(e, "Pre-purchase-advise")
                                }
                              >
                                Pre-purchase advise
                              </li>
                              <li
                                onClick={(e) =>
                                  handleValuation(
                                    e,
                                    "Mortage-Security(First/Second)"
                                  )
                                }
                              >
                                Mortgage security (First / Second)
                              </li>
                              <li
                                onClick={(e) =>
                                  handleValuation(e, "Strata-unit-entitlement")
                                }
                              >
                                Strata unit entitlement
                              </li>
                              <li
                                onClick={(e) =>
                                  handleValuation(
                                    e,
                                    "Taxation(Stamp_Duty_/_CGT)"
                                  )
                                }
                              >
                                Taxation (Stamp Duty / CGT)
                              </li>
                              <li
                                onClick={(e) =>
                                  handleValuation(e, "Internal-advise/Audit")
                                }
                              >
                                Internal advise / audit
                              </li>
                              <li
                                onClick={(e) =>
                                  handleValuation(e, "insurance_assesment")
                                }
                              >
                                Insurance assessment{" "}
                              </li>
                              <li
                                onClick={(e) => handleValuation(e, "desktop")}
                              >
                                Desktop
                              </li>
                              <li onClick={(e) => handleValuation(e, "Others")}>
                                Others
                              </li>
                            </ul>
                          </div>
                        </Collapse>
                        <Link
                          to={"/depreciation-report"}
                          className="text-decoration-none text-muted"
                          onClick={() => {
                            setOpenDrawer(false);
                          }}
                        >
                          Depreciation report
                        </Link>
                        <Link
                          to={"#"}
                          className="text-decoration-none text-muted"
                          onClick={() => handleModal()}
                        >
                          QS report
                        </Link>
                        <Link
                          to={"#"}
                          className="text-decoration-none text-muted"
                          onClick={() => handlDilapidationModal()}
                        >
                          Dilapidation report
                        </Link>
                        <Link
                          to={"#"}
                          className="text-decoration-none text-muted"
                          onClick={() => handlPoolInspectionModal()}
                        >
                          Pool inspection & certificate
                        </Link>
                        <Link
                          to={"#"}
                          className="text-decoration-none text-muted"
                          onClick={() => handlHandoverInspectionModal()}
                        >
                          Handover inspection
                        </Link>
                        <Link
                          to={"#"}
                          className="text-decoration-none text-muted"
                          onClick={() => handlStageInspectionModal()}
                        >
                          Stage inspection
                        </Link>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </ListGroupItem>
              <Link to={link_item[2].link}>
                <ListGroupItem
                  onClick={() => {
                    setOpenDrawer(false);
                  }}
                  className="navbar-mobile-side-menu-item text-muted"
                >
                  {link_item[2].name}
                </ListGroupItem>
              </Link>
              <Link to={link_item[3].link}>
                <ListGroupItem
                  onClick={() => {
                    setOpenDrawer(false);
                  }}
                  className="navbar-mobile-side-menu-item text-muted"
                >
                  {link_item[3].name}
                </ListGroupItem>
              </Link>
              {localStorage.getItem("userId") != null ? (
                <Link to={link_item[5].link}>
                  <ListGroupItem
                    onClick={() => {
                      setOpenDrawer(false);
                    }}
                    className="navbar-mobile-side-menu-item text-muted"
                  >
                    {link_item[5].name}
                  </ListGroupItem>
                </Link>
              ) : (
                ""
              )}
              <Link to={link_item[8].link}>
                <ListGroupItem
                  onClick={() => {
                    setOpenDrawer(false);
                  }}
                  className="navbar-mobile-side-menu-item text-muted"
                >
                  {link_item[8].name}
                </ListGroupItem>
              </Link>
              <Link to={link_item[6].link}>
                <ListGroupItem
                  onClick={() => {
                    setOpenDrawer(false);
                  }}
                  className="navbar-mobile-side-menu-item text-muted"
                >
                  {link_item[6].name}
                </ListGroupItem>
              </Link>

              {/* <Link to={link_item[7].link}>
                            <ListGroupItem onClick={() => { setOpenDrawer(false) }} className="navbar-mobile-side-menu-item text-muted" >{link_item[7].name}</ListGroupItem>
                        </Link> */}
            </ListGroup>
          </div>
        )}
        <QsModal show={show} onHide={() => set_show(false)} />
        <DilapidationModal
          show={dilapidationShow}
          onHide={() => setDilapidationShow(false)}
        />
        <PoolInspectionModal
          show={poolInspectionShow}
          onHide={() => setPoolInspectionShow(false)}
        />
        <HandOverInspectionModal
          show={handoverInspectionShow}
          onHide={() => setHandoverInspectionShow(false)}
        />
        <StageInspectionModal
          show={stageInspectionShow}
          onHide={() => setStageInspectionShow(false)}
        />
      </div>
    </>
  );
}

const link_item = [
  {
    name: "Home",
    link: "/",

    className: "navbar-desktop-link",
  },
  {
    name: "Order report",
    link: "/search",
    hash: "/yo",
    className: "navbar-desktop-link btn-primary-nav bg-transparent",
  },
  {
    name: "About us",
    link: "/about",
    className: "navbar-desktop-link",
  },
  {
    name: "Find an agent",
    link: "/service",
    className: "navbar-desktop-link",
  },
  {
    name: "Insurance",
    link: "/#",
    className: "navbar-desktop-link",
  },
  {
    name: "Wall",
    link: "/wall",
    className: "navbar-desktop-link",
  },
  {
    name: "Articles",
    link: "/blog",
    className: "navbar-desktop-link",
  },
  {
    name: "Login",
    link: "/login",
    className: "navbar-desktop-link-login",
  },
  {
    name: "Free Property Insights",
    link: "/insights",
    className: "navbar-desktop-link",
  },
];
export default Header;
