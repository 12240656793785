/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Container, Form, Image, Row, Modal } from 'react-bootstrap'
import { Link, useHistory } from "react-router-dom";
import Autocomplete from 'react-google-autocomplete';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import '../assets/css/signup.css'
import Header from '../components/header'
import Footer from '../components/footer'
import BgPattern3 from '../assets/images/bg/pattern/footer_bg_pattern.svg'
import axios from 'axios';
import Login from './login';
import Swal from 'sweetalert2';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../cropImage';
import getCroppedImage from '../cropImage';
import MobileInput from '../components/common/MobileInput';

function SignUp() {


    const history = useHistory();
    const [btnCheck, setbtnCheck] = React.useState(true);
    const [errName, seterr] = React.useState("");
    const [show, setShow] = useState(false);
    const [croppedPixel, setCroppedPixels] = useState('')
    const [forCrop, setForCrop] = useState('')
    const handleShow = () => setShow(true);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)


    const onCropComplete = ((croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
        setCroppedPixels(croppedAreaPixels)

    })

    const handleClose = async () => {
        const croppedImage = await getCroppedImg(
            forCrop,
            croppedPixel,
        )
        setFormData({
            ...formData,
            imageurl: URL.createObjectURL(croppedImage),
            image: croppedImage
        })
        setForCrop('')
        setCrop({ x: 0, y: 0 })
        setZoom(1)
        setShow(false)

    };
    // 


    /**
     *  Declaration the form data to the state variable
     */
    const [formData, setFormData] = React.useState({
        action: "register",
        name: "",
        email: "",
        image: "",
        imageurl: "",
        password: "",
        role: "",
        mobile: "",
        abn: "",
        agency: "",
        address: "",
        lat: "",
        lon: "",
        place_id: "",
        // isEmailVerified:"false"
    })


    const [err, setErr] = React.useState("");
    const [errEmail, setErrEmail] = React.useState("");

    /**
     * input only number in ABN input box
     * @param {*} e 
     */

    const [anbPattern, setabnPattern] = React.useState(/^[0-9\b]+$/);
    const [abnErr, setabnErr] = React.useState('');

    const onChangeABN = (e) => {
        let abn = e.target.value;
        if (abn == "" || anbPattern.test(abn)) {
            setFormData({
                ...formData,
                abn: abn
            })
            setabnErr("")
        }
        else {
            setabnErr("Possible only number")
        }
    }
    useEffect(
        () => {
            if (formData.abn !== "") {
                axios.post(
                    process.env.REACT_APP_ABN_SERVICE_URL + "?guid=" + process.env.REACT_APP_ABN_SERVICE_GUID + "&abn=" + formData.abn
                ).then(async (res) => {
                    const step1 = res.data.split('(');
                    const step2 = step1[1].split(')');
                    const jsonData = JSON.parse(step2[0]);
                    if (jsonData?.AbnStatus == "Active") {
                        setabnErr(jsonData.EntityName)
                    }
                    else (
                        setabnErr("Invalid ABN number")
                    )
                })
            }
            // window.scrollTo(0, 0);
        }, [formData.abn]
    )
    /**
     * When the user click the submit button, this function will be run
     * @param {*} e 
     */
    async function handleSubmit(e) {
        const api_url = "https://proptiapi.ignatiuslab.in/test_api.php";
        e.preventDefault();
        try {
            axios.post(
                process.env.REACT_APP_ABN_SERVICE_URL + "?guid=" + process.env.REACT_APP_ABN_SERVICE_GUID + "&abn=" + formData.abn
            ).then(async (res) => {
                const step1 = res.data.split('(');
                const step2 = step1[1].split(')');
                const jsonData = JSON.parse(step2[0]);
                if (jsonData?.AbnStatus == "Active") {
                    setabnErr(jsonData.EntityName)
                    try {
                        axios.post(
                            api_url,
                            formData
                        ).then(
                            (res) => (
                                axios.post(
                                    "https://proptiapi.ignatiuslab.in/test_api.php",
                                    {
                                        action: "registerInfo",
                                        fname: formData.name,
                                        lname: "",
                                        number: formData.mobile,
                                        email: formData.email,
                                        company: "",
                                        abn: formData.abn,
                                        address: formData.address,
                                        city: "",
                                        state: "",
                                        postcode: "",
                                        registration_id: res.data
                                    }
                                ).then(
                                    (res) => {

                                        if (res.data != 'Failed to create') {
                                            axios.post(
                                                process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
                                                {
                                                    action: "active_ac",
                                                    email: formData.email,
                                                    name: formData.name,
                                                    id: res.data[0].maxID
                                                }
                                            ).then(
                                                (res1) => {
                                                    history.push('/login')
                                                });
                                        } else {

                                        }



                                        //
                                    }
                                )
                            )

                        )
                        // const report = await axios.post(

                        //     process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
                        //     {
                        //         action:"getReport",
                        //         address:address
                        //     }
                        // );

                    } catch (error) {
                        error.response.data.message !== "" ? setErr(error.response.data.message) : setErr("")
                    }
                }
                else {
                    setabnErr("Invalid ABN")
                }
            }
            )
        } catch {
            (e) =>
                setabnErr("Invalid ABN", e)
        }
    }

    /**
     * Save form data to the state variable.
     */
    const handleFormDataChange = async (e, elementID) => {

        if (elementID == "name") {
            const res = await axios.post(
                process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
                {
                    action: 'getUserByName',
                    agent: e.target.value
                }
            )
            if (res.data[0] == 'nodata') {
                setbtnCheck(false);
                seterr('');

            } else {
                setbtnCheck(true);
                seterr("User Name Already Exist!!!");
            }
        }

        if (elementID == "email") {
            const res = await axios.post(
                process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
                {
                    action: 'getUserByEmail',
                    agent: e.target.value
                }
            )
            if (res.data[0] == 'nodata') {
                setbtnCheck(false);
                setErrEmail('');

            } else {
                setbtnCheck(true);
                setErrEmail("Email Already Exist!!!");
            }
        }

        setFormData({
            ...formData,
            [elementID]: e.target.value
        })
    }

    /**
     * Redirect to portal when user logged in...
     */

    async function signup(e) {

        e.preventDefault()
        var emailRegx = /^[a-zA-Z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/
        if (formData.image === "") {
            setbtnCheck(true)
            seterr('Profile image is required.')
            return false;
        }
        if (formData.name.trim() == '') {
            setbtnCheck(true)
            seterr('Name is required.')
            return false;
        }
        if (formData.email == '') {
            setbtnCheck(true)
            seterr('Email is required.')
            return false;
        }
        if (!emailRegx.test(formData.email)) {
            setbtnCheck(true)
            seterr("Invalid email")
            return false;
        }
        if (formData.mobile == '') {
            setbtnCheck(true)
            seterr('Mobile is required.')
            return false;
        }
        if (formData.role == '') {
            setbtnCheck(true)
            seterr('Role is required.')
            return false;
        }
        if (formData.abn == '') {
            setbtnCheck(true)
            seterr('ABN is required.')
            return false;
        }
        if (formData.agency.trim() == '') {
            setbtnCheck(true)
            seterr('Agency is required.')
            return false;
        }
        if (formData.address == '') {
            setbtnCheck(true)
            seterr('Address is required.')
            return false;
        }
        if (abnErr === 'Invalid ABN number') {
            seterr("")
            setbtnCheck(true)
            return false;
        }
        // setbtnCheck(false)
        seterr("")
        var data = new FormData()
        data.append('profile_image', formData.image)
        data.append('name', formData.name)
        data.append('email', formData.email)
        data.append('password', formData.password)
        data.append('agent_type', formData.role)
        data.append('phone_number', formData.mobile)
        data.append('abn_no', formData.abn)
        data.append('agency', formData.agency)
        data.append('address', formData.address)
        data.append('location_latitude', formData.lat)
        data.append('location_longitude', formData.lon)
        data.append('place_id', formData.place_id)
        data.append('role', 'Web')

        await axios.post(process.env.REACT_APP_NODE_BACKEND_API_URL + '/auth/register', data).then(response => {
            if (response.status == 200) {
                Swal.fire(
                    'Successful',
                    'User created successfully.',
                    'success'
                ).then(result => {
                    if (result.isConfirmed) {
                        history.push('/')
                    }
                    if (result.isDismissed) {
                        history.push('/')
                    }
                })
            }

        }).catch(err => {
            // console.log({res})
            Swal.fire(
                '',
                err.response.data.message,
                'warning'
            )
        })

    }

    useEffect(
        () => {
            if (localStorage.getItem('tokens') !== null) {
                history.push('/portal/reporter')
            }
        }, []
    )

    // const validateEmail = (e) => {
    //     var email = e.target.value

    //     if (validator.isEmail(email)) {
    //         setEmailError('')
    //         setFormData({
    //             ...formData,
    //             email: e.target.value
    //         })
    //         setbtnCheck(false)
    //     } else {
    //         seterr('Invalid')
    //         setbtnCheck(true)
    //     }
    // }

    /**
     * Rendering view
     */
    return (
        <div>
            <Image src={BgPattern3} className="position-absolute w-100" style={{ marginTop: '100px', zIndex: '-1' }}></Image>
            <Container>
                <Header></Header>
                <div className="text-center my-xl-5 my-md-3">
                    <h1 className="login-title" name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">Create account</h1>
                </div>
            </Container>
            <Container className="d-flex justify-content-center">
                <Form className='signup-form' onSubmit={handleSubmit}>
                    <div className='uploadimage-box'>
                        <div className='upload-image'>

                            <>
                                {formData.imageurl === '' ?
                                    <img className='user-image' src={require('../assets/images/download_img-new.png').default} alt="img"></img> :
                                    <img src={formData.imageurl} alt="img"></img>}
                                <input type="file" className='upload-link' accept='image/*' onClick={(e) => e.target.value = ''} onChange={(e) => {
                                    if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                                        setForCrop(URL.createObjectURL(e.target.files[0]))
                                        // setFormData({
                                        //     ...formData,
                                        //     imageurl: URL.createObjectURL(e.target.files[0]),
                                        //     image: e.target.files[0]
                                        // })
                                        setShow(true)
                                    }
                                    else {
                                        Swal.fire('', 'Please select image of format Jpeg or png.')
                                    }
                                }}></input>
                            </>

                            {/* :

                                <img src={formData.imageurl}></img> */}


                        </div>
                    </div>
                    <Form.Group controlid="signUpName">
                        <Form.Label className='signup-label ml-2'>Full name*</Form.Label>
                        <Form.Control
                            maxLength={30}
                            type="text"
                            placeholder='Enter your name here'
                            className="login-text signup-input"
                            name="name"
                            required

                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    name: e.target.value.trimStart()
                                })
                            }}
                        />

                    </Form.Group>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlid="signUpEmail">
                                <Form.Label className='signup-label ml-2'>Email*
                                    {
                                        err == "Email already taken" ?
                                            <span className="ml-2 app-text text-danger">{err}</span> : ""
                                    }
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder='Enter your email here'
                                    className="login-text signup-input"
                                    name="email"
                                    style={{ border: err == "Email already taken" ? "solid 1px red" : "none" }}
                                    required
                                    onChange={(e) => setFormData({
                                        ...formData,
                                        email: e.target.value
                                    })}
                                />
                                <span style={{ color: "red", paddingLeft: "20px" }}> {errEmail} </span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlid="signUpMobile">
                                <Form.Label className='signup-label ml-2'>
                                    Mobile number*
                                </Form.Label>
                                <MobileInput value={formData.mobile} onChange={e => setFormData({ ...formData, mobile: e })} />
                                <span className="ml-2 app-text text-danger">
                                        {
                                            formData.mobile !== ""
                                                ?
                                                formData.mobile && isPossiblePhoneNumber(formData.mobile) ? formData.mobile && isValidPhoneNumber(formData.mobile) ? '' : 'Invalid Phone Number' : 'Impossible Phone Number'
                                                : ""
                                        }
                                    </span>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* <Form.Group controlid="signUpName">
                        <Form.Label className='signup-label ml-2'>
                            Password*
                            {
                                err.includes('password') ? <span className="ml-2 app-text text-danger">{err} </span> : ""
                            }
                        </Form.Label>
                        <Form.Control
                            type="password"
                            style={{ border: err.includes('password') ? "solid 1px red" : "none" }}
                            placeholder='Enter Your Password Here'
                            className="login-text signup-input"
                            name="password"
                            required
                            onChange={(e) => setFormData({
                                ...formData,
                                password: e.target.value
                            })}
                        />
                    </Form.Group> */}
                    <Row className="my-5 ml-1 text-left ">
                        <Col md={4} className="px-0 mb-3">
                            <Form.Check
                                type="radio"
                                inline
                                id='inline-check-1'
                                name="role"
                                value="R"
                                label="Real estate agent"
                                className="signup-label"
                                onChange={(e) => setFormData({
                                    ...formData,
                                    role: e.target.value
                                })}
                            // checked={(formData.role == "real_estate_agent")}
                            />
                        </Col>
                        <Col md={4} className="px-0 signup-radio mb-3">
                            <Form.Check
                                type="radio"
                                inline
                                id='inline-check-2'
                                name="role"
                                value="B"
                                label="Buyers agent"
                                className="signup-label"
                                onChange={(e) => setFormData({
                                    ...formData,
                                    role: e.target.value
                                })}
                            // checked={(formData.role == "reporter")}
                            />
                        </Col>
                        <Col md={4} className="px-0 signup-radio mb-3">
                            <Form.Check
                                type="radio"
                                inline
                                id='inline-check-3'
                                name="role"
                                value="CO"
                                label="Conveyancer"
                                className="signup-label"
                                onChange={(e) => setFormData({
                                    ...formData,
                                    role: e.target.value
                                })}
                            // checked={(formData.role == "reporter")}
                            />
                        </Col>
                        <Col md={4} className="px-0 signup-radio mb-3">
                            <Form.Check
                                type="radio"
                                inline
                                id='inline-check-4'
                                name="role"
                                value="BR"
                                label="Broker"
                                className="signup-label"
                                onChange={(e) => setFormData({
                                    ...formData,
                                    role: e.target.value
                                })}
                            // checked={(formData.role == "reporter")}
                            />
                        </Col>
                        <Col md={4} className="px-0 signup-radio mb-3">
                            <Form.Check
                                type="radio"
                                inline
                                id='inline-check-5'
                                name="role"
                                value="A"
                                label="Accountant"
                                className="signup-label"
                                onChange={(e) => setFormData({
                                    ...formData,
                                    role: e.target.value
                                })}
                            // checked={(formData.role == "buyer_agent")}
                            />
                        </Col>
                        <Col md={4} className="px-0 signup-radio mb-3">
                            <Form.Check
                                type="radio"
                                inline
                                id='inline-check-6'
                                name="role"
                                value="C"
                                label="Solicitor"
                                className="signup-label"
                                onChange={(e) => setFormData({
                                    ...formData,
                                    role: e.target.value
                                })}
                            // checked={(formData.role == "conveyancer")}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlid="signUpABN">
                                <Form.Label className='signup-label ml-2'>
                                    ABN*
                                    <span className="ml-2 app-text text-danger">{abnErr}</span>
                                </Form.Label>
                                <Form.Control
                                    maxLength={12}
                                    type="text"
                                    value={formData.abn}
                                    placeholder='Enter your ABN here'
                                    name="abn"
                                    className="login-text signup-input"
                                    required
                                    style={{ border: (abnErr == "Invalide ABN number") ? "solid 1px red" : "none" }}
                                    // onChange={(e)=>handleFormDataChange(e, "abn")}
                                    onChange={onChangeABN}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlid="signUpAgency">
                                <Form.Label className='signup-label ml-2'>Agency*</Form.Label>
                                <Form.Control
                                    maxLength={35}
                                    type="text"
                                    placeholder='Enter your agency name here'
                                    name="agency"
                                    className="login-text signup-input"
                                    onChange={(e) => setFormData({
                                        ...formData,
                                        agency: e.target.value
                                    })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlid="signUpAddress">
                        <Form.Label className='signup-label ml-2'>Address*</Form.Label>
                        <Form.Control
                            maxLength={2000}
                            type="text"
                            placeholder='Enter your address here'
                            name="agency"
                            className="login-text signup-input"
                            onChange={(e) => setFormData({
                                ...formData,
                                address: e.target.value,
                                lat: 0,
                                lon: 0,
                                place_id: 0
                            })}
                        />
                        {/* <Autocomplete
                            style={{
                                width: '100%',
                                padding: '15px',
                                borderRadius: '55px',
                                border: 'none',
                                boxShadow: '5px 5px 15px rgb(67 127 236 / 10%)',
                                outline: 'none',
                                background: '#FCFDFD',
                                fontFamily: 'Poppins',
                                transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out"
                            }}
                            onPlaceSelected={(place) => {
                                // console.log(place.place_id)
                                setFormData({
                                    ...formData,
                                    address: place.formatted_address,
                                    lat: place.geometry.location.lat(),
                                    lon: place.geometry.location.lng(),
                                    place_id: place.place_id
                                })


                            }}
                            onselect={(place) => console.log(place)}
                            types={['address']}
                            componentRestrictions={{ country: "au" }}
                            placeholder="Enter your address here"
                            className="login-text signup-input form-control"
                        /> */}
                    </Form.Group>
                    <Form.Group className="text-center">
                        <p style={{ color: "red" }}> {errName} </p>
                        <Button type="submit" className="login-text signup-continue bluebtn" onClick={(e) => signup(e)}  >Continue</Button>
                        {/* <p className='login-text'>Already Have an Account? <Link to='/login' className="logo-link">Log in</Link></p> */}
                    </Form.Group>
                </Form>
                {forCrop !== '' &&
                    <Modal show={show} onHide={() => setShow(false)}>
                        <Modal.Header className='border-0'>
                            {/* <Modal.Title>Modal heading</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body>
                            <div className='cropper_body'>
                                <Cropper
                                    image={forCrop}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={4 / 4}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />

                            </div>
                            <div className='text-right mt-3'>
                                <button className='btn btn-success px-4' onClick={() => handleClose()}>Crop</button>
                            </div>
                        </Modal.Body>

                    </Modal>


                }

            </Container>

            <Footer></Footer>
        </div>
    )
}
{/* <Modal show={show} onHide={handleClose}>
<Modal.Header closeButton className='border-0'>
    {/* <Modal.Title>Modal heading</Modal.Title> */}
// </Modal.Header>
// <Modal.Body>
//    <div className='cropper_body'>
//    <Cropper
//         image={formData.imageurl}
//         crop={crop}
//         zoom={zoom}
//         // aspect={5 / 4}
//         onCropChange={setCrop}
//         onCropComplete={onCropComplete}
//         onZoomChange={setZoom}
//     />
//    </div>
// </Modal.Body>

// </Modal> */}

{/* <Modal show={show} onHide={handleClose} centered>
{/* <Modal.Header closeButton>
    <Modal.Title>Modal heading</Modal.Title>
</Modal.Header> */}
{/* <Modal.Body>
    <div className='cropper_body'>
    <Cropper style={{ width: "122px"}}
    image={forCrop}
    crop={crop}
    zoom={zoom}
    // aspect={5 / 4}
    onCropChange={setCrop}
    onCropComplete={onCropComplete}
    onZoomChange={setZoom}
/>
</div>
 <Button className="btn-primary signup-continue" variant="primary" onClick={handleClose}>
        Ok
    </Button>
</Modal.Body> */}




// </Modal> */}
export default SignUp
