import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './app/store'
import { Provider } from 'react-redux'

// import  css
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';


ReactDOM.render(

  <Provider store={store}>
    <Helmet>
      {/* <title>Propti – The home of property reports and valuations</title> */}
      <title>Propti | comprehensive database of strata reports, building and pest reports, valuations, depreciation reports, and QS reports </title>
      <meta name="description" content="An all-in-one platform for real estate agents, solicitors, mortgage brokers, accountants, buyers and homeowners to get property reports." />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
