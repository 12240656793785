import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Form, FormGroup, Row, Col, Button } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import validator from "validator";
import MobileInput from "./common/MobileInput";
import CompanyInput from "./common/CompanyInput";

const DilapidationModal = (props) => {
  // console.log(props)
  let history = useHistory();

  const [formData, setFormData] = useState({
    order_report_type: "DI",
    order_type: "N",
    place_id: "",
    address: "",
    location_latitude: "",
    location_longitude: "",
    full_name: "",
    mobile: "",
    email: "",
    message: "",
    company_name: "",
    company_id: "",
    tc:false
  });
  useEffect(() => {
    if (props.address !== "" && props.address != undefined) {
      setFormData({
        ...formData,
        place_id: props.address.place_id,
        address: props.address.prop_address,
        location_latitude: props.address.latitude,
        location_longitude: props.address.longitude,
      });
    }
  }, [props]);

  function HandleQsSubmission() {
    var emailRegx =
      /^[a-zA-Z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/;
    if (formData.address.trim() === "") {
      Swal.fire("", "Please select address.", "warning");
      return false;
    }
    if (formData.full_name.trim() === "") {
      Swal.fire("", "Full name is required.", "warning");
      return false;
    }
    if (formData.email === "") {
      Swal.fire("", "Email is required.", "warning");
      return false;
    }
    if (!emailRegx.test(formData.email)) {
      Swal.fire("", "Invalid email.", "warning");
      return false;
    }
    if (formData.mobile === "") {
      Swal.fire("", "Mobile number is required.", "warning");
      return false;
    }
    if (!validator.isMobilePhone(formData.mobile)) {
      Swal.fire("", "Invalid mobile number.", "warning");
      return false;
    }

    // if (
    //   formData.company_name.trim() === "" &&
    //   formData.company_id.trim() == ""
    // ) {
    //   Swal.fire("", "Company field is required.", "warning");
    //   return false;
    // }
    if (formData.message.trim() === "") {
      Swal.fire("", "Message field is required.", "warning");
      return false;
    }
    if (formData.tc=== false) {
      Swal.fire("", "Please agree to term and condition.", "warning");
      return false;
    }
    let data = {
      order_report_type: formData.order_report_type,
      order_type: formData.order_type,
      place_id: formData.place_id,
      address: formData.address,
      location_latitude: formData.location_latitude,
      location_longitude: formData.location_longitude,
      full_name_for_dilapidation_report: formData.full_name,
      email_for_dilapidation_report: formData.email,
      phone_number_for_dilapidation_report: formData.mobile,
      message_for_dilapidation_report: formData.message,
      company_name_ref: formData.company_name,
      company_id: formData.company_id,
      tc:formData.tc,
    };

    axios
      .post(process.env.REACT_APP_NODE_BACKEND_API_URL + "/report", data, {
        headers: {
          Authorization: "vw7kddgha5j30fz1r7ev",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          props.onHide();

          setFormData({
            ...formData,
            place_id: "",
            address: "",
            location_latitude: "",
            location_longitude: "",
            full_name: "",
            mobile: "",
            email: "",
            message: "",
          });

          Swal.fire("", "Successfully submitted.", "success");
          setTimeout(() => {
            if (history.location.pathname == "/") {
              window.location.href = "/";
            }
          }, 1000);
        }
      })
      .catch((err) => {
        Swal.fire("", err.response.data.message, "warning");
      });
  }

  return (
    <Modal className="qs-modal" show={props.show} onHide={() => props.onHide()}>
      <Helmet>
        <title>Quick, easy and affordable quantity surveyor reports</title>
        <meta
          name="description"
          content="With a comprehensive QS report, assess the wear and tear of your investment property to see how much tax you can claim."
        />
      </Helmet>
      <Modal.Header closeButton>
        <Modal.Title>Dilapidation report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div
            className="px-1 hero-place-autocomplete mb-4"
            style={{ position: "relative" }}
          >
            <Form.Label className="contact-form-label">
              Select address
            </Form.Label>
            <Form.Control
              as={Autocomplete}
              types={["address"]}
              componentRestrictions={{ country: "au" }}
              type="text"
              placeholder="Find your property"
              value={
                props.address !== "" && props.address !== undefined
                  ? props.address.prop_address
                  : formData.address
              }
              className="contact-form-input starta_home_search"
              onPlaceSelected={(place) => {
                setFormData({
                  ...formData,
                  place_id: place?.place_id,
                  address: place?.formatted_address,
                  location_latitude: place?.geometry?.location.lat(),
                  location_longitude: place?.geometry?.location.lng(),
                });
              }}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  place_id: "",
                  address: e.target.value,
                  location_latitude: "",
                  location_longitude: "",
                })
              }
              required
            ></Form.Control>
          </div>
        </>

        <Form>
          <FormGroup>
            <Form.Label className="contact-form-label">Full name</Form.Label>
            <Form.Control
              type="text"
              maxLength={20}
              placeholder="Enter your name here"
              className="contact-form-input"
              required
              onChange={(e) =>
                setFormData({
                  ...formData,
                  full_name: e.target.value,
                })
              }
              value={formData.name}
            />
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Form.Label className="contact-form-label">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email here"
                  className="contact-form-input"
                  required
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      email: e.target.value,
                    })
                  }
                  value={formData.email}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Form.Label className="contact-form-label">
                  Mobile number
                </Form.Label>
                <MobileInput
                  value={formData.mobile}
                  onChange={(e) => setFormData({ ...formData, mobile: e })}
                />
              </FormGroup>
            </Col>
          </Row>
          <CompanyInput formData={formData} setFormData={setFormData} />
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label className="contact-form-label">Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              maxLength={1000}
              className="contact-form-text-input"
              placeholder="Enter message"
              required
              onChange={(e) =>
                setFormData({
                  ...formData,
                  message: e.target.value,
                })
              }
              value={formData.message}
            />
          </Form.Group>
          <Row>
                            <Col md={12}>
                            <FormGroup>
                              <Form.Check
                             
                                className="agree-checkbox app-text contact-form-label"
                                name="tt"
                                label="I agree to terms and conditions*"
                                type="checkbox"
                                id="agree"
                                style={{color:"#1D83FF"}}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    tc: e.target.checked,
                                   
                                  })
                                }
                              ></Form.Check>
                              </FormGroup>
                              {/* <span className="error">{formDataErr.tc}</span> */}
                            </Col>
                            </Row>

          {/* <div className="submit_data">
                            <Button variant="primary" type="submit" className="contact-form-submit">
                                Submit
                            </Button>
                           
                        </div> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ borderRadius: "30px" }}
          variant="primary"
          onClick={HandleQsSubmission}
          disabled={formData.place_id == "" ? true : false}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DilapidationModal;
