import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, FormGroup, Image, Row, } from 'react-bootstrap'
import Footer from '../components/footer'
import Header from '../components/header'
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment'
import axios from 'axios'
import BgPattern1 from '../assets/images/bg/pattern/hero_bg_pattern.svg';
import PropertyImage from '../assets/images/svg/property_image.jpg'
import Avatar from '../assets/images/avatars/avatar3.svg'
import Swal from 'sweetalert2';
import ReactDatePicker from 'react-datepicker';
import DatePicker from "react-datepicker"
import CardIamge1 from '../assets/images/svg/notfound.png';
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery"
function Payment() {
    const [loader, set_loader] = useState(false)
    const all_date = useLocation();
    const [errName, seterrName] = useState('')
    const history = useHistory();
    console.log(all_date.state);
    console.log(all_date);
    const [formData, setFormData] = React.useState({
        action: "purchase",
        name: "",
        number: "",
        expire: "",
        cvv: "",
    })

    if (all_date.state == undefined) {
        history.push({
            pathname: '/search/strata-report',
        });
    }
    // var now = new Date(),
    // until = new Date(now.getFullYear() + 10, now.getMonth());

    // $('#picker').mobiscroll().datepicker({
    //     controls: ['date'],
    //     dateFormat: 'MM/YYYY',
    //     dateWheels: '|MMMM YYYY|',
    //     min: now,
    //     max: until,
    //     touchUi: true
    // });

    // async function handleSubmit(e){
    //     e.preventDefault();

    //     var creatOrderData = {
    //         action:"creatOrder",
    //         address:all_date.state.detail.address,
    //         type:'strata',
    //         reporter:'test men',
    //         report:'test',
    //         agent:all_date.state.detail.name == 'other' ? all_date.state.detail.agentname : all_date.state.detail.name,
    //         agentemail:all_date.state.detail.agentemail,
    //         mobile:all_date.state.detail.mobile,
    //         email:all_date.state.detail.email,
    //         name:all_date.state.detail.firstName + " " + all_date.state.detail.lastName,
    //         file:all_date.state.image,
    //         forWhat:all_date.state.detail.status,
    //         recommendedAgent:all_date.state.detail.recommandedAgent,
    //         usingBName:all_date.state.detail.usingbuyerAgentName,
    //         usingBEmail:all_date.state.detail.usingbuyerAgentEmail,
    //         solicitorName:all_date.state.detail.usingSolicitorAgentName,
    //         solicitorEmail:all_date.state.detail.usingSolicitorAgentEmail,
    //         solicitorFound:all_date.state.detail.theSolicitoragentfound,
    //         trustedBroker:all_date.state.detail.trustedBroker,
    //         authorityLetter:all_date.state.letter,
    //         onbehalf:all_date.state.detail.onBehlaf,
    //         referenceID:all_date.state.number,
    //         agreed:'yes',
    //         paid:'yes',
    //         price:'250',
    //         status:'pending'
    //     }

    //     const res = await axios.post(
    //         process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
    //         creatOrderData
    //     )


    //     //mail send
    //     if(all_date.state.detail.theSolicitoragentfound == "yes"){
    //         const sendmail1 = await axios.post(
    //             process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
    //             {
    //                 action:"foundSolicitor",
    //                 address:all_date.state.detail.address,
    //                 agent:all_date.state.detail.name,
    //                 mobile:all_date.state.detail.mobile,
    //                 email:all_date.state.detail.email,
    //                 name:all_date.state.detail.name,
    //                 status:all_date.state.detail.status,
    //             }
    //         )
    //     }
    //     if(all_date.state.detail.trustedBroker == "yes"){
    //         const sendmail2 = await axios.post(
    //             process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
    //             {
    //                 action:"requestingBroker",
    //                 address:all_date.state.detail.address,
    //                 agent:all_date.state.detail.name,
    //                 mobile:all_date.state.detail.mobile,
    //                 email:all_date.state.detail.email,
    //                 name:all_date.state.detail.name,
    //                 status:all_date.state.detail.status,
    //             }
    //         )
    //     }



    //     var id = res.data;
    //     var today = new Date();
    //     const createCustomer = await axios.post(
    //         process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
    //         {
    //             action:"CreateCustomerOrder",
    //             address:all_date.state.detail.address,
    //             agent:all_date.state.detail.name,
    //             name:all_date.state.detail.firstName + " " + all_date.state.detail.lastName,
    //             email:all_date.state.detail.email,
    //             mobile:all_date.state.detail.mobile,
    //             date:all_date.state.detail.inspectionDate != '' ? all_date.state.detail.inspectionDate : '',
    //             order_id:id,
    //             formated_address:all_date.state.formated_address,
    //             file:all_date.state.image,
    //             admin_approve:'false'
    //         }
    //     )




    //     // starta_send_mail
    //     const res1 = await axios.post(
    //         process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
    //         {
    //             action:"starta_send_mail",
    //             address:all_date.state.detail.address,
    //             agent:all_date.state.detail.name,
    //             mobile:all_date.state.detail.mobile,
    //             email:all_date.state.detail.email,
    //             name:all_date.state.detail.name,
    //             status:all_date.state.detail.status,
    //             code:all_date.state.number
    //         }
    //     )

    //     if(all_date.state.report != ""){
    //         console.log("customer mail send");
    //         // CUSTOMER MAIL SEND
    //         const res1 = await axios.post(
    //             process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
    //             {
    //                 action:"order_approve",
    //                 address:all_date.state.detail.address,
    //                 email:all_date.state.detail.email,
    //                 name:all_date.state.detail.name,
    //             }
    //         )

    //         var email = "";
    //         all_date.state.agent.map((item)=>(
    //             all_date.state.detail.name == item.name ? email=item.email : ''
    //             ));
    //             // CUSTOMER MAIL SEND
    //             const aget_mail = await axios.post(
    //                 process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
    //                 {
    //                     action:"agent_notify",
    //                     address:all_date.state.detail.address,
    //                     email:email,
    //                     name:all_date.state.detail.name,
    //                 }
    //             )
    //             console.log("agent mail send");
    //     }

    //     // new agent send mail
    //     if(all_date.state.detail.agentemail != "" && all_date.state.detail.agentname != ""){
    //         const res1 = await axios.post(
    //             process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
    //             {
    //                 action:"new_agent",
    //                 name:all_date.state.detail.name,
    //                 email:all_date.state.detail.email,
    //                 address:all_date.state.detail.address,
    //                 agentname:all_date.state.detail.agentname,
    //                 agentemail:all_date.state.detail.agentemail,
    //             }
    //         )
    //     }


    //     // emailjs.send("service_2ejqknc","template_kc1pic6",{
    //             //     name:formData.name,
    //             //     address:formData.address,
    //             //     email:formData.email,
    //             //     mobile:formData.mobile,
    //             //     purchase_price: "$" + formData.purchase_price,
    //             //     purchase_date:date_frame,
    //             //     settlement_date:date_frame2,
    //             //     manager_name:formData.manager_name,
    //             // },"user_wWCNhCethmwLr19htdKio")
    //             //     .then((result)=>{
    //             //         console.log(result);
    //             history.push(
    //                 {
    //                     pathname:"../success",
    //                     state:{
    //                         order:'Strata Report', number:all_date.state.number,
    //                         text:'A link confirming your order will be sent to you via email'
    //                     }
    //                 }
    //             );
    //         // }, (error) => {
    //         //     console.log(error);
    //         // });

    // }
    // Only digit apply code
    async function validate(evt) {

        var theEvent = evt || window.event;

        if (theEvent.type === 'paste') {
            key = window.event.clipboardData.getData('text/plain');
        } else {
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    if (all_date.state === undefined) {
        history.push('/search/strata-report')
    }

    if (history.action === 'POP') {
        // alert('')
    }

    var date = new Date()
    async function submit_data(e) {
        e.preventDefault()

        if (formData.name === '') {
            seterrName("Name is required.")
            return false;
        }
        if (formData.number === '') {
            seterrName("Number is required.")
            return false;
        }
        if (formData.expire === '') {
            seterrName("Expire date is required.")
            return false;
        }
        if (formData.cvv === '') {
            seterrName("CVV is required.")
            return false;
        }
        console.log(all_date.state.details)
     
        set_loader(true)
        let data = new FormData()
        data.append('order_report_type', all_date.state.details.order_report_type)
        data.append('place_image_url', all_date.state.image)
        data.append('place_id', all_date.state.details.place_id)
        data.append('address', all_date.state.details.address)
        data.append('location_latitude', all_date.state.details.location_latitude)
        data.append('location_longitude', all_date.state.details.location_longitude)
        data.append('company_id', all_date.state.details.company_id)
        data.append('company_name_ref', all_date.state.details.company_name)
        
        if (all_date.state.Agent_on_list === "none") {

            data.append('agent_id', all_date.state.details.agent_id)
        }
        else {
            data.append('agent_id', "")
        }
        if (all_date.state.Agent_on_list === "block") {

            data.append('agent_first_name', all_date.state.details.agent_first_name)
            data.append('agent_email', all_date.state.details.agent_email)
        }
        else {
            data.append('agent_first_name', "")
            data.append('agent_email', "")
        }
        data.append('requesting_person_first_name', all_date.state.details.requesting_person_first_name)
        data.append('requesting_person_last_name', all_date.state.details.requesting_person_last_name)
        data.append('requesting_person_email', all_date.state.details.requesting_person_email)
        data.append('requesting_person_mobile_number', all_date.state.details.requesting_person_mobile_number)
        data.append('inspection_date', all_date.state.details.inspection_date)
        data.append('do_have_buyers_agent', all_date.state.details.do_have_buyers_agent)
        data.append('need_us_to_recommend', all_date.state.details.need_us_to_recommend)
        if (all_date.state.buyer_agent_on_list === "none") {

            data.append('buyers_agent_id', all_date.state.details.buyers_agent_id)
        }
        else {
            data.append('buyers_agent_id', '')
        }
        if (all_date.state.buyer_agent_on_list === "block") {

            data.append('buyers_agent_first_name', all_date.state.details.buyers_agent_first_name)
            data.append('buyers_agent_email', all_date.state.details.buyers_agent_email)
        }
        else {

            data.append('buyers_agent_first_name', '')
            data.append('buyers_agent_email', "")
        }
        data.append('do_have_solicitor_agent', all_date.state.details.do_have_solicitor_agent)
        data.append('need_us_to_recommend_solicitor_agent', all_date.state.details.need_us_to_recommend_solicitor_agent)
        if (all_date.state.solicitor_agent_on_list === "none") {

            data.append('solicitor_agent_id', all_date.state.details.solicitor_agent_id)
        }
        else {
            data.append('solicitor_agent_id', "")
        }
        if (all_date.state.solicitor_agent_on_list === "block") {

            data.append('solicitor_agent_first_name', all_date.state.details.solicitor_agent_first_name)
            data.append('solicitor_agent_email', all_date.state.details.solicitor_agent_email)
        }
        else {

            data.append('solicitor_agent_first_name', "")
            data.append('solicitor_agent_email', "")
        }
        if (all_date.state.profession_agent_on_list === "none") {
            data.append('professional_agent_id', all_date.state.details.profession_agent_id)
        }
        else {
            data.append("professional_agent_first_name", all_date.state.details.profession_agent_name)
            data.append("professional_agent_email", all_date.state.details.profession_agent_email)
        }
        data.append('have_organised_finanace', all_date.state.details.have_organised_finanace)
        data.append('like_us_to_reccomend_broker', all_date.state.details.like_us_to_reccomend_broker)
        data.append('purchasing_report_on_behalf_on_someone', all_date.state.details.purchasing_report_on_behalf_on_someone)
        // data.append('agent_id_on_whom_behalf', all_date.state.details.agent_id_on_whom_behalf)

        data.append('profession_of_person_on_whom_behalf', all_date.state.details.your_profession)
        data.append('profession_other_desc_if_selected', all_date.state.details.other_profession)
        data.append('current_living_situation', all_date.state.details.living_situation)
        data.append('reason_of_report', all_date.state.details.reason_of_report)
        data.append('order_type', all_date.state.details.order_type)
        data.append('authority_letter_for_report', all_date.state.details.authority_letter_for_report)
        data.append('card_no', formData.number)
        data.append('exp_date', moment(formData.expire).format("MM/yyyy"))
        data.append('cvv', formData.cvv)




        await axios.post(process.env.REACT_APP_NODE_BACKEND_API_URL + '/report', data, {
            headers: {
                "Authorization": 'vw7kddgha5j30fz1r7ev',
                // 'Content-Type': 'multipart/form-data; '
            }
        }).then(response => {
            set_loader(false)
            if (response.status === 200) {
                Swal.fire('Successfully submitted', 'Thank you for reaching out. We value your business and will work to assist you promptly.', 'success').then((result) => {
                    if (result.isConfirmed) {
                        history.push('/strata-report')
                    }
                    else if (result.isDenied) {
                        history.push('/payment')
                    }
                })
                // setTimeout(() => {
                //     history.push('/search/strata-report')
                // }, 1000)
            }
        }).catch(err => {
            set_loader(false)
            Swal.fire('', err.response.data.message, 'warning')
            // history.push('/payment')
        })
    }

    useEffect(

        async () => {
            if (all_date.state === undefined) {
                history.push('/search/strata-report');
            }
            window.scrollTo(0, 0);
        }, []

    )
    // console.log(new Date())
    // console.log(moment(date).add(-1, 'months').format());

    return (
        <>
            {loader === true &&
                <div className='loader-main-div'>
                    <div className='loader-second'>
                        <div class="loader"></div></div>
                </div>}
            <div>

                <Image src={BgPattern1} className="position-absolute w-100" style={{ zIndex: "-1" }} alt="img"></Image>
                <Container>
                    <Header />
                    <div className="text-center set_page_title">
                        <h1 className="app-title" name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">Order Report</h1>
                    </div>
                    <Row className='strata-Rep-section'>
                        <Col md={4}>
                            <Image alt="img" src={all_date.state?.image == "" || all_date.state == undefined ? CardIamge1 : all_date.state.image} style={{ borderRadius: '40px' }} className="w-100"></Image>
                            <div className="text-center">
                                <p className="strata-img-title">{all_date.state?.address}</p>
                                <p className="strata-img-date">Estimated Inspection Date : {all_date.state?.date}</p>
                            </div>
                            {/* {
                                all_date.state.pvtAgent != undefined ?
                                    <div className="d-flex justify-content-between px-md-2 align-items-center report-author-block">
                                        <div className="d-flex align-items-center">
                                            <Image src={all_date.state.pvtAgent.avatar != "" ? all_date.state.pvtAgent.avatar : Avatar} className="admin-avatar"></Image>
                                            <div className="ml-3 report-users">
                                                <p className="app-text my-0 text-dark">Reported By:</p>
                                                <p className="app-text my-0">{all_date.state == undefined ? '' : all_date.state.pvtAgent.name}</p>
                                            </div>
                                        </div>
                                        <p className="app-title text-primary my-0">$50</p>
                                    </div>
                                    :
                                    ""

                            } */}
                        </Col>
                        <Col md={8} className="strata-rep-form ">
                            <div className="text-center">
                                <p className="payment-title">Credit card</p>
                            </div>
                            <Form>
                                <FormGroup>
                                    <Form.Label className="contact-form-label">Card holder name*</Form.Label>
                                    <Form.Control type='text' placeholder="Enter your name here" className='contact-form-input' required

                                        onChange={
                                            (e) => setFormData({
                                                ...formData,
                                                name: e.target.value
                                            })
                                        }
                                    ></Form.Control>
                                </FormGroup>
                                <FormGroup>
                                    <Form.Label className="contact-form-label">Card number*</Form.Label>
                                    <Form.Control type='text' placeholder="Enter your card number here" className='contact-form-input' required
                                        maxLength="16"
                                        minLength="16"
                                        onKeyPress={
                                            (e) => validate(e)
                                        }
                                        onChange={
                                            (e) => setFormData({
                                                ...formData,
                                                number: e.target.value
                                            })
                                        }
                                    ></Form.Control>
                                </FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Form.Label className="contact-form-label">Expiration date*</Form.Label>
                                        {/* <Form.Control type='text' id='picker' min={moment(date).format("YYYY-MM")}  placeholder="Enter card expiration date Here" className='contact-form-input' required
                                            onChange={
                                                (e) => 
                                                setFormData({
                                                    ...formData,
                                                    expire:moment(e.target.value).format("MM/YYYY")
                                                })
                                            }
                                            
                                        ></Form.Control> */}

                                        {/* <label for="formGroupExampleInput">Start date</label> */}
                                        <ReactDatePicker
                                            showMonthYearPicker
                                            selected={formData.expire}
                                            // showFullMonthYearPicker
                                            className="text-field"
                                            placeholderText="Enter card expiration date here"
                                            id={"sdate"}
                                            minDate={new Date().setMonth(date.getMonth() - 1)}
                                            // selected={formData.expire}
                                            // value={formData.expire}
                                            // maxDate={new Date()}
                                            // showDisabledMonthNavigation
                                            dateFormat={"MM/yyyy"}
                                            // placeholderText="MM/YYYY"
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                expire: e
                                            })}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                        {/* <DatePicker
                                        showMonthYearPicker
                                            dateFormat="MM/YYYY"
                                            dateWheels="mm - MMMM  YYY"
                                            min={date}
                                            max={new Date(date.getFullYear() + 10, date.getMonth())}
                                            // value="12/2025"
                                            label="Expiration"
                                            placeholder="Required"
                                            touchUi={true}
                                        /> */}

                                    </Col>
                                    <Col md={6}>
                                        <Form.Label className="contact-form-label">CVV*</Form.Label>
                                        <Form.Control type='text' placeholder="Enter your cvv number here" className='contact-form-input' required
                                            maxLength="3"
                                            minLength="3"
                                            onKeyPress={
                                                (e) => validate(e)
                                            }
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    cvv: e.target.value
                                                })
                                            }
                                        ></Form.Control>
                                    </Col>
                                </Row>
                                <div className="my-5 text-center" style={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <Button className="navigation_button app-text" style={{ borderRadius: '1.5rem', width: '100%' }}
                                        onClick={
                                            () => {
                                                Swal.fire({
                                                    title: 'Form data will be lost. Do you want to continue',
                                                    showDenyButton: true,
                                                    // showCancelButton: true,
                                                    confirmButtonText: 'continue',
                                                    denyButtonText: `cancel`,
                                                }).then((result) => {
                                                    /* Read more about isConfirmed, isDenied below */
                                                    if (result.isConfirmed) {
                                                        //   Swal.fire('Saved!', '', 'success')
                                                        history.push({
                                                            pathname: './purchase',
                                                            state: {
                                                                address: all_date.state.address,
                                                                agent: undefined,
                                                                formated_address: all_date.state.formated_address,
                                                                image: all_date.state.image,
                                                                inspection: all_date.state.inspection,
                                                                method: all_date.state.method,
                                                                number: all_date.state.number,
                                                                report: all_date.state.report,
                                                                report_by: all_date.state.report_by,
                                                                typeOfReport: all_date.state.typeOfReport,
                                                                details: all_date.state.details
                                                            }
                                                        });
                                                    } else if (result.isDenied) {
                                                        Swal.close()
                                                    }
                                                })


                                            }
                                        }
                                    >Go back</Button>
                                    <p style={{ color: "red" }}> {errName} </p>
                                    <Button type="submit" className="app-text navigation_button" onClick={(e) => submit_data(e)} style={{ borderRadius: '1.5rem' }}>

                                        {all_date.state.report_type == "Old" ?
                                            "Pay $50"
                                            :
                                            'Pay $295'

                                        }  </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>

                </Container>
                <Footer />
            </div></>
    )
}

export default Payment
