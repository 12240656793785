import moment from 'moment';
import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
function BlogCard(props) {
    const history = useHistory();
    const MEDIA_URL = process.env.REACT_APP_NODE_BACKEND_MEDIA_API_URL

    return (
        <div>
            <Card className="blog-card my-1 blog-card-block">
                <Card.Img src={MEDIA_URL + props.image} variant="top" className="blog-card-image main_card_img" />
                <Card.Body>
                    <p className="app-title blog-card-title cliptext">{props.title}</p>
                    <div className="d-flex justify-content-between">
                        {/* <p className="my-1 app-text blog-card-author">{props.author}</p> */}
                        <p className="my-1 app-text blog-card-date">{moment(props.date).format("DD-MM-yyyy") }</p>
                    </div>
                    <p className="mt-1 app-text blog-card-content blog_desc"><div className='blog-data' dangerouslySetInnerHTML={{ __html: props.content }} /></p>
                    <div className='d-flex justify-content-center'>
                        <Button
                            className="app-text px-5 blog-card-readmore"
                            style={{ borderRadius: '1.5rem' }}
                            onClick={
                                () => {
                                    // history.push(`/blogdetail/${props.slug}`)
                                    window.location.href= `/blogdetail/${props.slug}`
                                }
                            }
                        >
                            Read More
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default BlogCard
