import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// import reasources
import "../../../assets/css/aboutus.css";
import Icon1 from "../../../assets/images/stratareports.svg";
import Icon2 from "../../../assets/images/Building-and-pest-reports.svg";
import Icon3 from "../../../assets/images/valuations.svg";
import Icon4 from "../../../assets/images/depreciationreports.svg";
import Icon6 from "../../../assets/images/strata.png";
import Icon8 from "../../../assets/images/handover.png";
import Icon9 from "../../../assets/images/pool.png";
import Icon5 from "../../../assets/images/surveys.png";
import Icon7 from "../../../assets/images/stage.png";
import QsModal from "../../QsModal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DilapidationModal from "../../Dilapidation";
import PoolInspectionModal from "../../PoolInspection";
import HandOverInspectionModal from "../../Handover-inspection";
import StageInspectionModal from "../../stage-inspection";

function AboutUs() {
  const [showQsModal, set_showQsModal] = useState(false);
  const [dilapidationShow, setDilapidationShow] = useState(false);
  const [poolInspectionShow, setPoolInspectionShow] = useState(false);
  const [handoverInspectionShow, setHandoverInspectionShow] = useState(false);
  const [stageInspectionShow, setStageInspectionShow] = useState(false);

  function handleModal() {
    set_showQsModal(true);
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="aboutus-root">
      <p className="home-section-title-1">Go all in with propti</p>
      <div className="d-flex justify-content-between align-items-center set_aboutus_root">
        <h2 className="home-section-title">
          The perfect place for property <br />
          searches
        </h2>
        {/* <p className='about-title-content'>
                    Established in 2020, Propti is a property portal that offers <br />
                    solutions to your property reporting needs. We can save you <br />
                    time, money, and stress whether you're a solicitor, buyers <br />
                    agent, real estate agent, or buyer.
                </p> */}
        <div>
          <p className="about-title-content">
            Without an established network, the process of researching,
            reviewing and requesting property reports from relevant providers
            can take weeks - or even months.{" "}
          </p>
        </div>
      </div>
      <p className="about-title-content about-title-content1 mt-3">
        Propti flipped the traditional property reporting process on its head to
        provide a comprehensive database of strata reports, building and pest
        reports, valuations, depreciation reports, and QS reports in one place.
        Whether you’re a solicitor, conveyancer, real estate agent, buyers
        agent, accountant or broker, our trusted inspectors and reporters are
        here to save you time, money and admin.{" "}
      </p>
      <p className="about-title-content about-title-content1">
        Now, you can find reports, get context and make better decisions without
        having to chase down people or information.{" "}
      </p>
      <Carousel
        swipeable={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        transitionDuration={1000}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-60-px"
        className="about-us-slider"
      >
        <div>
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2">
                <Card.Img
                  variant="top"
                  src={Icon1}
                  className="aboutus-card-icon"
                ></Card.Img>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">
                Strata reports
              </Card.Title>
              <Card.Text className="aboutus-card-text">
                Find a breakdown of a property's history with respect to body
                corporate records
              </Card.Text>
              <Link to="./strata-report" className="aboutus-card-link">
                Order now
              </Link>
            </div>
          </Card>
        </div>
        <div>
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2">
                <Card.Img
                  variant="top"
                  src={Icon3}
                  className="aboutus-card-icon"
                ></Card.Img>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">Valuations</Card.Title>
              <Card.Text className="aboutus-card-text">
                See how you assets stack up and discover how much you can borrow
              </Card.Text>
              <Link to="./valuation" className="aboutus-card-link">
                Order now
              </Link>
            </div>
          </Card>
        </div>
        <div>
          {" "}
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2 set_building_icon">
                <Card.Img
                  variant="top"
                  src={Icon2}
                  className="aboutus-card-icon"
                ></Card.Img>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">
                Building and Pest reports
              </Card.Title>
              <Card.Text className="aboutus-card-text">
                Uncover the potential issues and weaknesses of a property – both
                inside and out
              </Card.Text>
              <Link to="./Inspection" className="aboutus-card-link">
                Order now
              </Link>
            </div>
          </Card>
        </div>
        <div>
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2">
                <Card.Img
                  variant="top"
                  src={Icon4}
                  className="aboutus-card-icon"
                ></Card.Img>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">
                Depreciation reports
              </Card.Title>
              <Card.Text className="aboutus-card-text">
                Discover how much you can claim on tax for the depreciation of
                your residential or commercial property
              </Card.Text>
              <Link to="./depreciation-report" className="aboutus-card-link">
                Order now
              </Link>
            </div>
          </Card>
        </div>
        <div>
          {" "}
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2">
                {/* <Card.Img variant="top" src={Icon5} className="aboutus-card-icon"></Card.Img> */}
                <svg width="27" height="23" viewBox="0 0 27 23" fill="none">
                  <path
                    d="M18.7753 10.1489C17.2394 10.7916 16.7165 11.053 16.7383 11.1729C16.771 11.3362 17.4028 12.8939 17.4681 12.9593C17.5008 13.0029 21.5748 11.3471 21.6946 11.2382C21.7817 11.162 21.0192 9.26658 20.9103 9.28837C20.8776 9.28837 19.919 9.68052 18.7753 10.1489Z"
                    fill="white"
                  />
                  <path
                    d="M6.62964 13.7981V14.8329H8.97163H11.3136V13.7981V12.7632H8.97163H6.62964V13.7981Z"
                    fill="white"
                  />
                  <path d="M3 14V15H4.5H6V14V13H4.5H3V14Z" fill="white" />
                  <path d="M3 19V20H4.5H6V19V18H4.5H3V19Z" fill="white" />
                  <path
                    d="M11.6404 13.7981V14.8329H13.9824H16.3244V13.7981V12.7632H13.9824H11.6404V13.7981Z"
                    fill="white"
                  />
                  <path
                    d="M4.23318 16.249V17.3383H6.57517H8.91716V16.249V15.1597H6.57517H4.23318V16.249Z"
                    fill="white"
                  />
                  <path
                    d="M9.13504 16.249V17.3383H11.477H13.819V16.249V15.1597H11.477H9.13504V16.249Z"
                    fill="white"
                  />
                  <path
                    d="M14.1458 16.249V17.3383H16.4878H18.8298V16.249V15.1597H16.4878H14.1458V16.249Z"
                    fill="white"
                  />
                  <path
                    d="M19.0476 16.249V17.3383H20.1914H21.3351V16.249V15.1597H20.1914H19.0476V16.249Z"
                    fill="white"
                  />
                  <path
                    d="M6.62964 18.6454V19.7347H8.97163H11.3136V18.6454V17.5561H8.97163H6.62964V18.6454Z"
                    fill="white"
                  />
                  <path
                    d="M11.6404 18.6454V19.7347H13.9824H16.3244V18.6454V17.5561H13.9824H11.6404V18.6454Z"
                    fill="white"
                  />
                  <path
                    d="M16.6512 18.6454V19.7347H18.9931H21.3351V18.6454V17.5561H18.9931H16.6512V18.6454Z"
                    fill="white"
                  />
                  <rect
                    x="1.85181"
                    y="22.4579"
                    width="1.2"
                    height="23.5288"
                    transform="rotate(-90 1.85181 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="1.85181"
                    y="22.4579"
                    width="1.2"
                    height="23.5288"
                    transform="rotate(-90 1.85181 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="1.85181"
                    y="22.4579"
                    width="1.2"
                    height="23.5288"
                    transform="rotate(-90 1.85181 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="2.28751"
                    y="22.4579"
                    width="1.2"
                    height="16.43"
                    transform="rotate(180 2.28751 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="2.28751"
                    y="22.4579"
                    width="1.2"
                    height="16.43"
                    transform="rotate(180 2.28751 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="2.28751"
                    y="22.4579"
                    width="1.2"
                    height="16.43"
                    transform="rotate(180 2.28751 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="25.5984"
                    y="22.4579"
                    width="1.2"
                    height="16.43"
                    transform="rotate(180 25.5984 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="25.5984"
                    y="22.4579"
                    width="1.2"
                    height="16.43"
                    transform="rotate(180 25.5984 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="25.5984"
                    y="22.4579"
                    width="1.2"
                    height="16.43"
                    transform="rotate(180 25.5984 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="13.4891"
                    y="-0.00012207"
                    width="1.2"
                    height="14.7231"
                    transform="rotate(66.3746 13.4891 -0.00012207)"
                    fill="white"
                  />
                  <rect
                    width="1.2"
                    height="14.7231"
                    transform="matrix(0.430619 -0.902534 -0.902534 -0.430619 26.2881 7.4231)"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">QS reports</Card.Title>
              <Card.Text className="aboutus-card-text">
                Assess the wear and tear of your investment property to see how
                much tax you can claim{" "}
              </Card.Text>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => handleModal()}
                className="aboutus-card-link"
              >
                Order now
              </a>
            </div>
          </Card>
        </div>
        <div>
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2">
                <Card.Img
                  variant="top"
                  src={Icon1}
                  className="aboutus-card-icon"
                ></Card.Img>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">
                Dilapidation report
              </Card.Title>
              <Card.Text className="aboutus-card-text">
                Record the condition of a property before construction or
                significant renovations.
              </Card.Text>
              {/* <Link to="./strata-report" className="aboutus-card-link">
                Order now
              </Link> */}
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setDilapidationShow(true)}
                className="aboutus-card-link"
              >
                Order now
              </a>
            </div>
          </Card>
        </div>
        <div>
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2">
                <Card.Img
                  variant="top"
                  src={Icon9}
                  className="aboutus-card-icon"
                ></Card.Img>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">
                Pool inspection & certificate
              </Card.Title>
              <Card.Text className="aboutus-card-text">
                Ensure your swimming pool is up to certification under
                regulation before you sell your property.
              </Card.Text>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setPoolInspectionShow(true)}
                className="aboutus-card-link"
              >
                Order now
              </a>
            </div>
          </Card>
        </div>
        <div>
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2">
                <Card.Img
                  variant="top"
                  src={Icon8}
                  className="aboutus-card-icon"
                ></Card.Img>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">
                Handover inspection
              </Card.Title>
              <Card.Text className="aboutus-card-text">
                Before taking ownership, inspect the property and confirm that
                the new construction meets all specified requirements.
              </Card.Text>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setHandoverInspectionShow(true)}
                className="aboutus-card-link"
              >
                Order now
              </a>
            </div>
          </Card>
        </div>
        <div>
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2">
                <Card.Img
                  variant="top"
                  src={Icon7}
                  className="aboutus-card-icon"
                ></Card.Img>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">
                Stage inspection
              </Card.Title>
              <Card.Text className="aboutus-card-text">
                Plan, prepare, monitor and approve by inspecting each stage of
                your home renovations.
              </Card.Text>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setStageInspectionShow(true)}
                className="aboutus-card-link"
              >
                Order now
              </a>
            </div>
          </Card>
        </div>
      </Carousel>

      {/* <Row className="my-5 about_row">
        <Col>
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2">
                <Card.Img
                  variant="top"
                  src={Icon1}]
                  className="aboutus-card-icon"
                ></Card.Img>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">
                Strata reports
              </Card.Title>
              <Card.Text className="aboutus-card-text">
                Find a breakdown of a property's history with respect to body
                corporate records
              </Card.Text>
              <Link to="./strata-report" className="aboutus-card-link">
                Order now
              </Link>
            </div>
          </Card>
        </Col>
        <Col>
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2 set_building_icon">
                <Card.Img
                  variant="top"
                  src={Icon2}
                  className="aboutus-card-icon"
                ></Card.Img>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">
                Building and Pest reports
              </Card.Title>
              <Card.Text className="aboutus-card-text">
                Uncover the potential issues and weaknesses of a property – both
                inside and out
              </Card.Text>
              <Link to="./Inspection" className="aboutus-card-link">
                Order now
              </Link>
            </div>
          </Card>
        </Col>
        <Col>
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2">
                <Card.Img
                  variant="top"
                  src={Icon3}
                  className="aboutus-card-icon"
                ></Card.Img>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">Valuations</Card.Title>
              <Card.Text className="aboutus-card-text">
                See how you assets stack up and discover how much you can borrow
              </Card.Text>
              <Link to="./valuation" className="aboutus-card-link">
                Order now
              </Link>
            </div>
          </Card>
        </Col>
        <Col>
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2">
                <Card.Img
                  variant="top"
                  src={Icon4}
                  className="aboutus-card-icon"
                ></Card.Img>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">
                Depreciation reports
              </Card.Title>
              <Card.Text className="aboutus-card-text">
                Discover how much you can claim on tax for the depreciation of
                your residential or commercial property
              </Card.Text>
              <Link to="./depreciation-report" className="aboutus-card-link">
                Order now
              </Link>
            </div>
          </Card>
        </Col>
        <Col>
          <Card className="aboutus-card">
            <div className="aboutus-card-icon-border-1 mr-auto d-flex">
              <div className="aboutus-card-icon-border-2">
                <svg width="27" height="23" viewBox="0 0 27 23" fill="none">
                  <path
                    d="M18.7753 10.1489C17.2394 10.7916 16.7165 11.053 16.7383 11.1729C16.771 11.3362 17.4028 12.8939 17.4681 12.9593C17.5008 13.0029 21.5748 11.3471 21.6946 11.2382C21.7817 11.162 21.0192 9.26658 20.9103 9.28837C20.8776 9.28837 19.919 9.68052 18.7753 10.1489Z"
                    fill="white"
                  />
                  <path
                    d="M6.62964 13.7981V14.8329H8.97163H11.3136V13.7981V12.7632H8.97163H6.62964V13.7981Z"
                    fill="white"
                  />
                  <path d="M3 14V15H4.5H6V14V13H4.5H3V14Z" fill="white" />
                  <path d="M3 19V20H4.5H6V19V18H4.5H3V19Z" fill="white" />
                  <path
                    d="M11.6404 13.7981V14.8329H13.9824H16.3244V13.7981V12.7632H13.9824H11.6404V13.7981Z"
                    fill="white"
                  />
                  <path
                    d="M4.23318 16.249V17.3383H6.57517H8.91716V16.249V15.1597H6.57517H4.23318V16.249Z"
                    fill="white"
                  />
                  <path
                    d="M9.13504 16.249V17.3383H11.477H13.819V16.249V15.1597H11.477H9.13504V16.249Z"
                    fill="white"
                  />
                  <path
                    d="M14.1458 16.249V17.3383H16.4878H18.8298V16.249V15.1597H16.4878H14.1458V16.249Z"
                    fill="white"
                  />
                  <path
                    d="M19.0476 16.249V17.3383H20.1914H21.3351V16.249V15.1597H20.1914H19.0476V16.249Z"
                    fill="white"
                  />
                  <path
                    d="M6.62964 18.6454V19.7347H8.97163H11.3136V18.6454V17.5561H8.97163H6.62964V18.6454Z"
                    fill="white"
                  />
                  <path
                    d="M11.6404 18.6454V19.7347H13.9824H16.3244V18.6454V17.5561H13.9824H11.6404V18.6454Z"
                    fill="white"
                  />
                  <path
                    d="M16.6512 18.6454V19.7347H18.9931H21.3351V18.6454V17.5561H18.9931H16.6512V18.6454Z"
                    fill="white"
                  />
                  <rect
                    x="1.85181"
                    y="22.4579"
                    width="1.2"
                    height="23.5288"
                    transform="rotate(-90 1.85181 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="1.85181"
                    y="22.4579"
                    width="1.2"
                    height="23.5288"
                    transform="rotate(-90 1.85181 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="1.85181"
                    y="22.4579"
                    width="1.2"
                    height="23.5288"
                    transform="rotate(-90 1.85181 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="2.28751"
                    y="22.4579"
                    width="1.2"
                    height="16.43"
                    transform="rotate(180 2.28751 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="2.28751"
                    y="22.4579"
                    width="1.2"
                    height="16.43"
                    transform="rotate(180 2.28751 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="2.28751"
                    y="22.4579"
                    width="1.2"
                    height="16.43"
                    transform="rotate(180 2.28751 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="25.5984"
                    y="22.4579"
                    width="1.2"
                    height="16.43"
                    transform="rotate(180 25.5984 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="25.5984"
                    y="22.4579"
                    width="1.2"
                    height="16.43"
                    transform="rotate(180 25.5984 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="25.5984"
                    y="22.4579"
                    width="1.2"
                    height="16.43"
                    transform="rotate(180 25.5984 22.4579)"
                    fill="white"
                  />
                  <rect
                    x="13.4891"
                    y="-0.00012207"
                    width="1.2"
                    height="14.7231"
                    transform="rotate(66.3746 13.4891 -0.00012207)"
                    fill="white"
                  />
                  <rect
                    width="1.2"
                    height="14.7231"
                    transform="matrix(0.430619 -0.902534 -0.902534 -0.430619 26.2881 7.4231)"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <Card.Title className="aboutus-card-title">QS reports</Card.Title>
              <Card.Text className="aboutus-card-text">
                Assess the wear and tear of your investment property to see how
                much tax you can claim{" "}
              </Card.Text>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => handleModal()}
                className="aboutus-card-link"
              >
                Order now
              </a>
            </div>
          </Card>
        </Col>
      </Row> */}
      <QsModal show={showQsModal} onHide={() => set_showQsModal(false)} />
      <DilapidationModal
        show={dilapidationShow}
        onHide={() => setDilapidationShow(false)}
      />
      <PoolInspectionModal
        show={poolInspectionShow}
        onHide={() => setPoolInspectionShow(false)}
      />
      <HandOverInspectionModal
        show={handoverInspectionShow}
        onHide={() => setHandoverInspectionShow(false)}
      />
      <StageInspectionModal
        show={stageInspectionShow}
        onHide={() => setStageInspectionShow(false)}
      />
    </div>
  );
}

export default AboutUs;
