import { React, useState, useRef, useEffect } from 'react'
import { Button, Card } from 'react-bootstrap'

import Avatar from '../../assets/images/avatars/user_dummy.png'
import location from '../../assets/images/svg/location.svg';
import address from '../../assets/images/svg/address.svg';
import mobile from '../../assets/images/svg/mobile.svg';
import mail from '../../assets/images/svg/mail.svg';
import close from '../../assets/images/svg/close.svg';
import PartnerBg from '../../assets/images/bg/pattern/partner.png';
import axios from 'axios'
function ServiceCard(props) {
    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
        setIsComponentVisible(true);
        if (modal) {
            document.body.style.overflow = "";
        } else {
            document.body.style.overflow = "hidden";
        }
    };

    if (modal) {
        document.body.classList.add('active-modal')
    } else {
        document.body.classList.remove('active-modal')
    }

    const [isComponentVisible, setIsComponentVisible] = useState(true);
    const modalRef = useRef(null);
    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setIsComponentVisible(false);
            setModal(false);
            document.body.style.overflow = "";
        }

    };


    // User extra information
    const [user, setUser] = useState([]);
    console.log('user');
    console.log(user);
    async function getUserInfo() {
        const users = await axios.post(

            process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
            {
                action: "GetRegisterInfo",
                id: props.id
            }
        );

        if (users.data[0] != undefined) {
            // console.log(users.data[0]);
            setUser(users.data[0]);
        }

    }
    const Media_url = process.env.REACT_APP_NODE_BACKEND_MEDIA_API_URL

    useEffect(
        () => {
            getUserInfo();
            document.addEventListener('click', handleClickOutside, true);
            return () => {
                document.removeEventListener('click', handleClickOutside, true);
            };
        }, []
    )

    return (
        <>

            <div>
                <Card className="service-card my-1 set_service_card">
                    <div className="d-flex justify-content-center">
                        <Card.Img className="service-avatar" src={
                            props.image === '' ? Avatar : Media_url + props.image

                        } />
                    </div>
                    <Card.Body className="px-2 service_card_body">
                        <Card.Title className="app-title text-center service-title">
                            {user.fname != undefined ? user.fname + " " + user.lname
                                : props.title
                            }
                        </Card.Title>
                        <Card.Text className="app-text text-center">
                            {props.text === "R" ? "Real estate agent" : props.text === "S" ? "Strata reporter" : props.text === "C" ? "Solicitor" : props.text === "B" ? "Buyer's agent" : props.text === "CO" ? "Conveyancer" : props.text === "BR" ? "Broker" : props.text === "A" ? "Accountant" : ''}
                        </Card.Text>
                        <div className="d-flex justify-content-center p-2 mt-4">
                            <Button className="app-title app-button f_service_btn"
                                onClick={toggleModal}
                            >Contact</Button>
                        </div>
                    </Card.Body>
                </Card>
                {modal && (

                    <div ref={modalRef}>
                        <div onClick={toggleModal} className="overlay1"></div>
                        {isComponentVisible && (

                            <div className="modal1">
                                <div className="bg_img">
                                    <img className="partner_background" src={PartnerBg} alt="img"></img>
                                </div>

                                <div className="modal1-content">
                                    <img alt="img" src={
                                        props.image === '' ? Avatar : Media_url + props.image
                                    }
                                        className="card-partner-avatar my-3" ></img>
                                    <h2 className='client_text'>
                                        {
                                            user.fname != undefined
                                                ?
                                                user.fname + " " + user.lname
                                                :
                                                props.title
                                        }
                                    </h2>
                                    <h5 className='silent_text'>{props.text === "R" ? "Real estate agent" : props.text === "S" ? "Strata reporter" : props.text === "C" ? "Solicitor" : props.text === "B" ? "Buyer's agent" : props.text === "CO" ? "Conveyancer" : props.text === "BR" ? "Broker" : props.text === "A" ? "Accountant" : ''}</h5>
                                    <div className="private_details">
                                        <div className="location">
                                            <div className="icon"><img src={location} alt="img"></img></div>
                                            <div className="cnt">{
                                                user.address != undefined
                                                    ?
                                                    user.address
                                                    :
                                                    props.address
                                            }</div>
                                        </div>
                                        <div className="address">
                                            <div className="icon"><img src={address} alt="img"></img></div>
                                            <div className="cnt">
                                                {
                                                    props.agency
                                                }
                                            </div>
                                        </div>
                                        <div className="email">
                                            <div className="icon"><img src={mail} alt="img"></img></div>
                                            <div className="cnt">
                                                {
                                                    user.email != undefined
                                                        ?
                                                        user.email
                                                        :
                                                        props.email
                                                }
                                            </div>
                                        </div>
                                        <div className="mobile">
                                            <div className="icon"><img src={mobile} alt="img"></img></div>
                                            <div className="cnt">

                                                {
                                                    user.number != undefined
                                                        ?
                                                        user.number
                                                        :
                                                        props.phone
                                                }

                                            </div>
                                        </div>

                                    </div>
                                    <img className="close_toggle" onClick={toggleModal} src={close} alt="img"></img>
                                </div>
                            </div>

                        )}

                    </div>
                )}
            </div>
        </>
    )
}

export default ServiceCard
