import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

const MobileInput = ({value, onChange}) => {
    console.log("value, onChange=====",value, onChange)

    return (
        <>
            <PhoneInput
                international
                placeholder="Enter phone number"
                countryCallingCodeEditable={false}
                defaultCountry="AU"
                value={value ? value : ""}
                error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                onChange={onChange}
                required
                limitMaxLength={10}
            />
        </>
    )
}

export default MobileInput