import React, { useEffect, useState, useRef } from 'react';
import { Navbar, Button, Col, Container, Form, FormGroup, Image, Row } from 'react-bootstrap'
import Footer from '../components/footer'
import Header from '../components/header'
import moment from 'moment';
// import BgPattern1 from '../assets/images/bg/pattern/hero_bg_pattern.svg';
// // import FooterBgPattern from '../assets/images/bg/pattern/footer_bg_pattern.svg';
// import HeroImage from '../assets/images/svg/document.png';
// import { Link, useHistory } from 'react-router-dom';
// import Autocomplete from 'react-google-autocomplete';
import { jsPDF } from "jspdf";
import Logo from '../assets/images/logo/logo.png';
import Line from '../assets/images/pdf/line.png';
import call from '../assets/images/pdf/call.png';
import mail from '../assets/images/pdf/mail.png';
import site from '../assets/images/pdf/internet.png';
import loaction from '../assets/images/pdf/loaction.png';
import bottom from '../assets/images/pdf/bottom.png';
import bg1 from '../assets/images/pdf/background1.png';
import bg2 from '../assets/images/pdf/background2.png';

const Test = () => {

    const [formData, setFormData] = useState({
        date: "",
        address: "",
        company: "",
        managementAddr: "",
        plan: "",
        currentHome: "",
        currentHomeRep: "",
        intrestedParty: "",
        intrestedhome: ""
    });
    return (
        <div id="pdfFile" style={{ width: '595px', position: "relative", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "15px", paddingRight: "15px" }} >
            <h1 className='h1_tag' name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">Heading3</h1>
            <table style={{ width: "100%" }}>
                <tr>
                    <td>
                        <Image src={Logo} className="logo-image" alt="img"></Image>
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                        <div style={{ position: "relative" }}>
                            <Image src={Line} style={{
                                width: " 68%",
                                float: "right",

                            }} alt="img"></Image>
                            <Image src={bg1} className="logo-image" style={{ position: "absolute", right: "14%", top: "5%" }} alt="img"></Image>

                        </div>
                    </td>
                </tr>
            </table>
            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ width: "60%" }}>
                        <div style={{ fontSize: "12px", marginTop: "40px" }}>
                            Propti Pty Ltd
                        </div>
                        <div style={{ fontSize: "12px" }}>
                            45 Denison Street
                        </div>
                        <div style={{ fontSize: "12px" }}>
                            BONDI JUNCTION, NSW, 2022
                        </div>
                    </td>
                    <td style={{ fontSize: "12px" }} align="right">
                        <div>Date: {moment(new Date()).format("DD MMM, YYYY")}</div>
                    </td>
                </tr>
            </table>
            <table style={{ width: "100%" }}>
                <tr>
                    <td align='center'>
                        <div style={{ fontSize: "20px", fontWeight: "bold", color: "#313131", marginTop: "50px" }}>
                            AUTHORITY FOR STRATA RECORD
                        </div>
                    </td>
                </tr>
                <tr>
                    <td align='center'>
                        <div style={{ fontSize: "14px", marginBottom: "35px" }}>
                            {formData.address}
                        </div>
                    </td>
                </tr>
            </table>
            <table style={{ width: "100%" }}>
                <tr>
                    <td>
                        <div style={{ fontSize: "19px", fontWeight: "bold", color: "#1D83FF" }}>
                            Strata Plan: {formData.plan}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="drawLine" style={{ width: "100%", height: "1px", backgroundColor: "#616161" }} ></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style={{ color: "#313131", fontSize: "12px", fontWeight: "600", paddingTop: "9px" }}>

                            We/ I, <span style={{ color: "#1D83FF" }}>{formData.currentHome}</span> the owners of the above property hereby authorise <span style={{ color: "#1D83FF" }}>{formData.intrestedParty}</span> or their representative to inspect the strata records for our property and obtain copies of such records as they may require.

                        </div>
                        <div style={{ position: "relative" }}>
                            <Image src={bg2} className="logo-image" style={{ position: "absolute", top: "0%" }} alt="img"></Image>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style={{ textAlign: "right", paddingTop: "30px" }}>
                            <div style={{ fontWeight: "500", color: "#313131", fontSize: "14px", margin: "0" }} >Yours faithfully</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="drawLine" style={{ width: "100%", height: "1px", backgroundColor: "#616161", marginTop: "40px" }} ></div>
                        <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                            {formData.currentHomeRep}&nbsp;, Acting &nbsp;on behalf &nbsp;of the owner &nbsp;of the property &nbsp;or {formData.intrestedhome}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style={{ paddingTop: '24px' }}>
                            <div style={{ marginBottom: "10px" }}>
                                <div style={{ fontSize: "11px" }} ><Image src={call} style={{ width: "19px", height: "19px", marginRight: "12px" }} alt="img"></Image> 02 9389 1077</div>
                            </div>
                            <div style={{ marginBottom: "10px" }}>

                                <div style={{ fontSize: "11px" }} ><Image src={mail} style={{ width: "19px", height: "19px", marginRight: "12px" }} alt="img"></Image> Support@propti.com.au</div>
                            </div>
                            <div style={{ marginBottom: "10px" }}>

                                <div style={{ fontSize: "11px" }} ><Image src={site} style={{ width: "19px", height: "19px", marginRight: "12px" }} alt="img"></Image> propti.com.au</div>
                            </div>
                            <div style={{ marginBottom: "10px" }}>

                                <div style={{ fontSize: "11px" }} ><Image src={loaction} style={{ width: "14px", height: "18px", marginLeft: "2px", marginRight: "12px" }} alt="img"></Image>45 Denison Street, Bondi Junction, NSW, 2022</div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <div style={{ position: "fixed", bottom: "60px", left: "0", right: "0" }}>
                <table style={{ width: "100%" }}>
                    <tr>
                        <td>
                            <div style={{ marginTop: "15px" }}>
                                <Image src={bottom} style={{ width: "100%" }} alt="img"></Image>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    )
}
export default Test;