import { useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BgPattern1 from "../assets/images/bg/pattern/hero_bg_pattern.svg";
import propertyImg1 from "../assets/images/svg/property-1.svg";
import propertyImg2 from "../assets/images/svg/property-2.svg";
import propertyImg3 from "../assets/images/svg/property-3.svg";
import date2 from "../assets/images/svg/date.svg";
import Header from "../components/header/header";
import Autocomplete from "react-google-autocomplete";
import GetProperties from "./getProperties";
import MobileInput from "../components/common/MobileInput";
import validator from "validator";
import Footer from "../components/footer/footer";
import { inSightApi } from "../API/blog.api";
import { useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
const Insights = () => {
  const [loader, set_loader] = useState(false);
  const [images, setImages] = useState("");
  const [place_id, set_place_id] = useState("");
  const [latitude, set_latitude] = useState("");
  const [longitude, set_longitude] = useState("");
  const history = useHistory();
  const [show_form, set_show_form] = useState("none");
  const [prop_address, set_prop_address] = useState("");
  const [address, setupcomingAddress] = useState(null);
  const [addressComponent, setAddressComponent] = useState([]);
  const [search, setSearch] = useState("block");
  const [searchProperty, setSearchProperty] = useState({
    address: address,
    method: "",
    type: "",
  });
  const Initialstate = {
    report_type: "",
    property_type: "",
    refinance: "",
    considering_refinance: "",
    bed_rooms: "",
    plan_to_sell: "",
    purchase_another_property: "",
    full_name: "",
    email: "",
    contact_number: "",
    tc: false,
    pp: false,
    address: ""
  };
  const [formData, setFormData] = useState(Initialstate);
  const errInitialstate = {
    report_type: "",
    property_type: "",
    refinance: "",
    considering_refinance: "",
    bed_rooms: "",
    plan_to_sell: "",
    purchase_another_property: "",
    full_name: "",
    email: "",
    contact_number: "",
    tc: "",
    pp: "",
  };
  const [formDataErr, setFormDataErr] = useState(errInitialstate);
  const onClickSearch = (e) => {
    e.preventDefault();
    setImages(
      <GetProperties address={searchProperty} component={addressComponent} />,
      set_loader(false)
    );
    setSearch("none");
    set_show_form(true);
  };

  const validateMobile = (e) => {
    var mobile = e ? e : "";
    setFormDataErr({
      ...formDataErr,
      contact_number: "",
    });
    if (mobile && validator.isMobilePhone(mobile, "any")) {
      setFormData({
        ...formData,
        contact_number: e,
      });
    } else {
      setFormDataErr({
        ...formDataErr,
        contact_number: "Please add valid number.",
      });
    }
  };
  const validateTosell = (formData) => {
    if (formData.property_type == "") {
      setFormDataErr({
        ...formDataErr,
        property_type: "Please select a property type.",
      });
      return false;
    } else if (formData.property_type != "LAND" && formData.bed_rooms == "") {
      setFormDataErr({
        ...formDataErr,
        bed_rooms: "Please select a bedroom type.",
        property_type: "",
      });
      return false;
    } else if (formData.plan_to_sell == "") {
      setFormDataErr({
        ...formDataErr,
        plan_to_sell: "Please select a plan to sell.",
        bed_rooms: "",
      });
      return false;
    } else if (formData.purchase_another_property == "") {
      setFormDataErr({
        ...formDataErr,
        purchase_another_property: "Please select a purchase property plan.",
        plan_to_sell: "",
      });
      return false;
    } else {
      return validatecommonField(formData);
    }
  };
  const validatecommonField = (formData) => {
    if (formData.full_name.trim() == "") {
      setFormDataErr({
        ...formDataErr,
        full_name: "Please enter a full name.",
        purchase_another_property: "",
      });
      return false;
    } else if (formData.email.trim() == "") {
      setFormDataErr({
        ...formDataErr,
        email: "Please enter  email.",
        full_name: "",
      });
      return false;
    } else if (formData.contact_number == "") {
      setFormDataErr({
        ...formDataErr,
        contact_number: "Please enter a  mobile number.",
        email: "",
      });
      return false;
    } else if (formData.tc == false) {
      setFormDataErr({
        ...formDataErr,
        tc: "Please agree to term and condition.",

        email: "",
      });
      return false;
    } else if (formData.pp == false) {
      setFormDataErr({
        ...formDataErr,
        pp: "Please agree to privacy policy.",
        email: "",
      });
      return false;
    } else {
      setFormDataErr(errInitialstate);
      return true;
    }
  };
  const validateToBuy = (formData) => {
    if (formData.plan_to_sell == "") {
      setFormDataErr({
        ...formDataErr,
        plan_to_sell: "Please select a plan to buy.",
        bed_rooms: "",
      });
      return false;
    } else {
      return validatecommonField(formData);
    }
  };

  const validateToRefin = (formData) => {
    if (formData.refinance == "") {
      setFormDataErr({
        ...formDataErr,
        refinance: "Please select refinance.",
      });
      return false;
    } else if (formData.plan_to_sell == "") {
      setFormDataErr({
        ...formDataErr,
        plan_to_sell: "Please select a plan to sell.",
        refinance: "",
      });
      return false;
    } else {
      return validatecommonField(formData);
    }
  };

  const submitForm = async () => {
    setFormDataErr({
      ...formDataErr,
      errInitialstate,
    });
    let validate = false;
    if (formData.report_type == "") {
      setFormData({
        ...formData,
        report_type: "Please select a report type.",
      });
      return false;
    } else {
      if (formData.report_type == "SELL") {
        validate = validateTosell(formData);
        //  if(validate){
        //   await inSightApi(formData);
        //  }
      } else if (formData.report_type == "BUY") {
        validate = validateToBuy(formData);
        // if(validate){
        //   await inSightApi(formData);
        //  }
      } else if (formData.report_type == "REFINANCE") {
        validate = validateToRefin(formData);
      }
      if (validate) {
        set_loader(true);
        await inSightApi(formData)
          .then((response) => {
            set_loader(false);
            if (response.status === 200) {
              Swal.fire(
                "Successfully Submitted.",
                "Admin will contact you shortly",
                "success"
              ).then((result) => {
                if (result.isConfirmed) {
                  history.push("/");
                }
              });
              setTimeout(() => {
                history.push("/");
              }, 1000);
            }
          })
          .catch((err) => [
            set_loader(false),
            Swal.fire("", err.response.data.message, "warning"),
          ]);
      }
      // await inSightApi(formData);
    }
  };
  return (
    <>
      <Helmet>
        <title
          name="keywords"
          content="comprehensive database of strata reports, building and pest reports, valuations, depreciation reports, and QS reports in one place"
        >
          {" "}
          The fastest way to get a strata report
        </title>
        <meta
          name="description"
          content="With one click, generate a new strata report or download a pre-existing strata report for any property with Propti."
        />
      </Helmet>
      {/* {loader === true && (
        <div className="loader-main-div">
          <div className="loader-second">
            <div class="loader"></div>
          </div>
        </div>
      )} */}
      <div className="d-flex flex-wrap" style={{ minHeight: "100vh" }}>
        <Image
          src={BgPattern1}
          className="position-absolute w-100"
          style={{ zIndex: "-1" }}
          alt="img"
        ></Image>
        <Container>
          <Header />

          {show_form == true ? (
            //  Property Insight Form
            <div className="set_building_title px-3 margintop0">
              <div>
                <p className="app-title align_center">Free Property Insight</p>
                <Row>
                  <Col md={4}>
                    {images}
                    <div className="text-center">
                      <p className="strata-img-title">
                        {searchProperty.address}
                      </p>
                      {/* <p className="strata-img-date px-3">
                        {" "}
                        Price and estimated time will be sent to you via email
                        once order is confirmed
                      </p> */}
                    </div>
                  </Col>
                  <Col md={8}>
                    <Form>
                      <Row>
                        <p className="build-form-title px-3 w-100">
                          Please select your report type*
                        </p>

                        <Col
                          md={4}
                          className="my-3"
                          onClick={() => {
                            setFormData(Initialstate);
                            setFormData({
                              ...formData,
                              report_type: "SELL",
                            });
                          }}
                        >
                          <div
                            className={`border-box ${
                              formData.report_type === "SELL" && "selected-opt"
                            }`}
                          >
                            <Form.Check
                              className="lable_color"
                              label="Looking to sell"
                              type="radio"
                              id="SELL"
                              name="inspection-radiogroup"
                              // onChange={(e) => {
                              //   setFormData(Initialstate);
                              //   setFormData({
                              //     ...formData,
                              //     report_type: e.target.id,
                              //   });
                              // }}
                            />
                            <Image
                              src={propertyImg1}
                              className="d-block propert-img"
                              style={{ zIndex: "-1" }}
                              alt="img"
                            ></Image>
                          </div>
                        </Col>
                        <Col
                          md={4}
                          className="my-3"
                          onClick={() => {
                            setFormData(Initialstate);
                            setFormData({
                              ...formData,
                              report_type: "BUY",
                            });
                          }}
                        >
                          <div
                            className={`border-box ${
                              formData.report_type === "BUY" && "selected-opt"
                            }`}
                          >
                            <Form.Check
                              className="lable_color"
                              label="Looking to buy"
                              type="radio"
                              id="BUY"
                              name="inspection-radiogroup"
                              // onChange={(e) => {
                              //   setFormData(Initialstate);
                              //   setFormData({
                              //     ...formData,
                              //     report_type: e.target.id,
                              //   });
                              // }}
                            />
                            <Image
                              src={propertyImg2}
                              className="d-block propert-img"
                              style={{ zIndex: "-1" }}
                              alt="img"
                            ></Image>
                          </div>
                        </Col>
                        <Col
                          md={4}
                          className="my-3"
                          onClick={() => {
                            setFormData(Initialstate);
                            setFormData({
                              ...formData,
                              report_type: "REFINANCE",
                            });
                          }}
                        >
                          <div
                            className={`border-box ${
                              formData.report_type === "REFINANCE" &&
                              "selected-opt"
                            }`}
                          >
                            <Form.Check
                              className="lable_color"
                              label="Looking to refinance"
                              type="radio"
                              id="REFINANCE"
                              name="inspection-radiogroup"
                              // onChange={(e) => {
                              //   setFormData(Initialstate);
                              //   setFormData({
                              //     ...formData,
                              //     report_type: e.target.id,
                              //   });
                              // }}
                            />
                            <Image
                              src={propertyImg3}
                              className="d-block propert-img"
                              style={{ zIndex: "-1" }}
                              alt="img"
                            ></Image>
                          </div>
                        </Col>
                      </Row>

                      {formData.report_type === "REFINANCE" ? (
                        <Row>
                          <p className="build-form-title px-3 w-100">
                            Why are you looking to refinance?*
                          </p>
                          <Col md={4} className="my-3">
                            <div className="inner-box">
                              <Form.Check
                                className="lable_color"
                                label="Save money"
                                type="radio"
                                id="SAVE_MONEY"
                                name="refinance_data"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    refinance: e.target.id,
                                  });
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={4} className="my-3">
                            <div className="inner-box">
                              <Form.Check
                                className="lable_color"
                                label="Reduce repayments"
                                type="radio"
                                id="REDUCE_PAYMENT"
                                name="refinance_data"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    refinance: e.target.id,
                                  });
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={4} className="my-3">
                            <div className="inner-box">
                              <Form.Check
                                className="lable_color"
                                label="Buy next property"
                                type="radio"
                                id="BUY_NEXT_PROPERTY"
                                name="refinance_data"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    refinance: e.target.id,
                                  });
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={4} className="">
                            <div className="inner-box">
                              <Form.Check
                                className="lable_color"
                                label="Consolidate debt"
                                type="radio"
                                id="CONSOLIDATE_DEBT"
                                name="refinance_data"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    refinance: e.target.id,
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <span className="error">{formDataErr.refinance}</span>
                      {formData.report_type == "SELL" ? (
                        <Row>
                          <p className="build-form-title px-3 w-100">
                            Please select your property type*
                          </p>

                          <Col md={4} className="my-1">
                            <div className="inner-box">
                              <Form.Check
                                className="lable_color"
                                label="House"
                                type="radio"
                                id="HOUSE"
                                name="inspection-radiogroup-1"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    property_type: e.target.id,
                                  });
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={4} className="my-1">
                            <div className="inner-box">
                              <Form.Check
                                className="lable_color"
                                label="Apartment"
                                type="radio"
                                id="APARTMENT"
                                name="inspection-radiogroup-1"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    property_type: e.target.id,
                                  })
                                }
                              />
                            </div>
                          </Col>
                          <Col md={4} className="my-1">
                            <div className="inner-box">
                              <Form.Check
                                className="lable_color"
                                label="Townhouse"
                                type="radio"
                                id="TOWNHOUSE"
                                name="inspection-radiogroup-1"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    property_type: e.target.id,
                                  })
                                }
                              />
                            </div>
                          </Col>
                          <Col md={4} className="my-1">
                            <div className="inner-box">
                              <Form.Check
                                className="lable_color"
                                label="Land"
                                type="radio"
                                id="LAND"
                                name="inspection-radiogroup-1"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    property_type: e.target.id,
                                  })
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <span className="error">{formDataErr.property_type}</span>
                      {formData.report_type == "SELL" &&
                      formData.property_type != "LAND" ? (
                        <>
                          <p className="build-form-title w-100 mt-3">
                            How many bed rooms?*
                          </p>
                          <Row>
                            <Col md={4} className="my-1">
                              <div className="inner-box">
                                <Form.Check
                                  className="lable_color"
                                  label="Studio"
                                  type="radio"
                                  id="STUDIO"
                                  name="inspection-radiogroup-2"
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      bed_rooms: e.target.id,
                                    })
                                  }
                                />
                              </div>
                            </Col>
                            <Col md={4} className="my-1">
                              <div className="inner-box">
                                <Form.Check
                                  className="lable_color"
                                  label="1 Bedroom"
                                  type="radio"
                                  id="1BEDROOM"
                                  name="inspection-radiogroup-2"
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      bed_rooms: e.target.id,
                                    })
                                  }
                                />
                              </div>
                            </Col>

                            <Col md={4} className="my-1">
                              <div className="inner-box">
                                <Form.Check
                                  className="lable_color"
                                  label="2 Bedroom"
                                  type="radio"
                                  id="2BEDROOM"
                                  name="inspection-radiogroup-2"
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      bed_rooms: e.target.id,
                                    })
                                  }
                                />
                              </div>
                            </Col>
                            <Col md={4} className="my-1">
                              <div className="inner-box">
                                <Form.Check
                                  className="lable_color"
                                  label="3 Bedroom"
                                  type="radio"
                                  id="3BEDROOM"
                                  name="inspection-radiogroup-2"
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      bed_rooms: e.target.id,
                                    })
                                  }
                                />
                              </div>
                            </Col>
                            <Col md={4} className="my-1">
                              <div className="inner-box">
                                <Form.Check
                                  className="lable_color"
                                  label="4 Bedroom"
                                  type="radio"
                                  id="4BEDROOM"
                                  name="inspection-radiogroup-2"
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      bed_rooms: e.target.id,
                                    })
                                  }
                                />
                              </div>
                            </Col>
                            <Col md={4} className="my-1">
                              <div className="inner-box">
                                <Form.Check
                                  className="lable_color"
                                  label="5 Bedroom"
                                  type="radio"
                                  id="5BEDROOM"
                                  name="inspection-radiogroup-2"
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      bed_rooms: e.target.id,
                                    })
                                  }
                                />
                              </div>
                            </Col>
                            <Col md={4} className="my-1">
                              <div className="inner-box">
                                <Form.Check
                                  className="lable_color"
                                  label="6+ Bedroom"
                                  type="radio"
                                  id="6+BEDROOM"
                                  name="inspection-radiogroup-2"
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      bed_rooms: e.target.id,
                                    })
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                          <span className="error">{formDataErr.bed_rooms}</span>
                        </>
                      ) : (
                        ""
                      )}

                      {formData.report_type ? (
                        <Row>
                          <p className="build-form-title px-3 w-100 mt-3">
                            {formData.report_type === "REFINANCE" ? (
                              "When are you considering refinance"
                            ) : (
                              <>
                                When do you plan to{" "}
                                {formData.report_type == "BUY" ? "buy" : "sell"}
                              </>
                            )}
                            ? *
                          </p>
                          {formData.report_type === "REFINANCE" ? (
                            <>
                              <Col md={4} className="my-1">
                                <div className="inner-box months-section">
                                  <Image
                                    src={date2}
                                    className="d-block date-img"
                                    style={{ zIndex: "-1" }}
                                    alt="img"
                                  ></Image>
                                  <span className="month-txt">0-2</span>
                                  <Form.Check
                                    className="lable_color"
                                    label="Months"
                                    type="radio"
                                    id="0-2"
                                    name="inspection-radiogroup-3"
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        plan_to_sell: e.target.id,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={4} className="my-1">
                                <div className="inner-box months-section">
                                  <Image
                                    src={date2}
                                    className="d-block date-img"
                                    style={{ zIndex: "-1" }}
                                    alt="img"
                                  ></Image>
                                  <span className="month-txt">3-5</span>
                                  <Form.Check
                                    className="lable_color"
                                    label="Months"
                                    type="radio"
                                    id="3-5"
                                    name="inspection-radiogroup-3"
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        plan_to_sell: e.target.id,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={4} className="my-1">
                                <div className="inner-box months-section">
                                  <Image
                                    src={date2}
                                    className="d-block date-img"
                                    style={{ zIndex: "-1" }}
                                    alt="img"
                                  ></Image>
                                  <span className="month-txt">6+</span>
                                  <Form.Check
                                    className="lable_color"
                                    label="Months"
                                    type="radio"
                                    id="6+"
                                    name="inspection-radiogroup-3"
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        plan_to_sell: e.target.id,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col md={4} className="my-1">
                                <div className="inner-box months-section">
                                  <Image
                                    src={date2}
                                    className="d-block date-img"
                                    style={{ zIndex: "-1" }}
                                    alt="img"
                                  ></Image>
                                  <span className="month-txt">0-3</span>
                                  <Form.Check
                                    className="lable_color"
                                    label="Months"
                                    type="radio"
                                    id="0-3"
                                    name="inspection-radiogroup-3"
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        plan_to_sell: e.target.id,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={4} className="my-1">
                                <div className="inner-box months-section">
                                  <Image
                                    src={date2}
                                    className="d-block date-img"
                                    style={{ zIndex: "-1" }}
                                    alt="img"
                                  ></Image>
                                  <span className="month-txt">4-6</span>
                                  <Form.Check
                                    className="lable_color"
                                    label="Months"
                                    type="radio"
                                    id="4-6"
                                    name="inspection-radiogroup-3"
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        plan_to_sell: e.target.id,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={4} className="my-1">
                                <div className="inner-box months-section">
                                  <Image
                                    src={date2}
                                    className="d-block date-img"
                                    style={{ zIndex: "-1" }}
                                    alt="img"
                                  ></Image>
                                  <span className="month-txt">7+</span>
                                  <Form.Check
                                    className="lable_color"
                                    label="Months"
                                    type="radio"
                                    id="7+"
                                    name="inspection-radiogroup-3"
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        plan_to_sell: e.target.id,
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                            </>
                          )}
                        </Row>
                      ) : (
                        ""
                      )}
                      <span className="error">{formDataErr.plan_to_sell}</span>
                      {formData.report_type == "SELL" ? (
                        <Row>
                          <p className="build-form-title px-3 w-100 mt-3">
                            Are you looking to purchase another property?*
                          </p>
                          <Col md={4} className="my-1">
                            <div className="inner-box">
                              <Form.Check
                                className="lable_color"
                                label="Yes"
                                type="radio"
                                id="YES"
                                name="inspection-radiogroup-4"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    purchase_another_property: e.target.id,
                                  });
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={4} className="my-1">
                            <div className="inner-box">
                              <Form.Check
                                className="lable_color"
                                label="No"
                                type="radio"
                                id="NO"
                                name="inspection-radiogroup-4"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    purchase_another_property: e.target.id,
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <span className="error">
                        {formDataErr.purchase_another_property}
                      </span>

                      {formData.report_type ? (
                        <>
                          <Row>
                            <p className="build-form-title px-3 w-100 my-3">
                              Enter full name*
                            </p>
                            <Col md={12}>
                              <Form.Control
                                type="text"
                                placeholder="Enter full name here"
                                className="contact-form-input"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    full_name: e.target.value,
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                          <span className="error">{formDataErr.full_name}</span>
                          <Row>
                            <Col md={6}>
                              <p className="build-form-title px-3 w-100 my-3">
                                Enter E-mail*
                              </p>
                              <Form.Control
                                type="text"
                                placeholder="Enter email here"
                                className="contact-form-input"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    email: e.target.value,
                                  });
                                }}
                              />
                              <span className="error">{formDataErr.email}</span>
                            </Col>

                            <Col md={6}>
                              <p className="build-form-title px-3 w-100 my-3">
                                Contact number*
                              </p>
                              <MobileInput
                                value={formData.number}
                                onChange={(e) => validateMobile(e)}
                              />
                              <span className="error">
                                {formDataErr.contact_number}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <Form.Check
                                className="agree-checkbox app-text"
                                name="tt"
                                label="I agree to terms and conditions*"
                                type="checkbox"
                                id="agree"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    tc: e.target.checked,
                                    pp: e.target.checked,
                                  })
                                }
                              ></Form.Check>
                              <span className="error">{formDataErr.tc}</span>
                            </Col>
                            {/* <Col md={6}>
                              <Form.Check
                                className="agree-checkbox app-text"
                                name="pp"
                                label="I agree to privacy policy*"
                                type="checkbox"
                                id="agree"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    pp: e.target.checked,
                                  })
                                }
                              ></Form.Check>
                              <span className="error">{formDataErr.pp}</span>
                            </Col> */}
                          </Row>

                          <Row className="navigations my-5">
                            <Button
                              className="app-text navigation_button insight-btn"
                              style={{ borderRadius: "1.5rem", width: "100%" }}
                              onClick={() => {
                                submitForm()
                              }}
                            >
                              Request Insights
                            </Button>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            //  Search Address Field
            <div
              className="text-center set_report_title"
              style={{ position: "relative" }}
            >
              <h1
                className="app-title "
                name="keywords"
                content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more."
              >
                Free Property Insights
              </h1>
              <Row className="">
                <Col md={12}>
                  <Form.Control
                    as={Autocomplete}
                    types={["address"]}
                    componentRestrictions={{ country: "au" }}
                    type="text"
                    placeholder="Enter property address"
                    value={prop_address != "" ? prop_address : ""}
                    className="contact-form-input starta_search"
                    onPlaceSelected={(place, e) => {
                      set_place_id(place?.place_id);
                      set_prop_address(place?.formatted_address);
                      var lat = place?.geometry?.location.lat();
                      set_latitude(lat);
                      var long = place?.geometry?.location.lng();
                      set_longitude(long);
                      setSearchProperty({
                        ...searchProperty,
                        address: place?.formatted_address?.replaceAll(
                          "Rd,",
                          "Road"
                        ),
                      });
                      setupcomingAddress(
                        place?.formatted_address?.replaceAll("Rd,", "Road")
                      );
                      setFormData({
                        ...formData,
                        address: place?.formatted_address?.replaceAll("Rd,", "Road")
                      })
                    }}
                    onChange={(e) => {
                      setSearchProperty({
                        ...searchProperty,
                        address: e.target.value,
                      });
                      set_prop_address(e.target.value);
                      set_place_id("");
                      setupcomingAddress(e.target.value);
                    }}
                    required
                  ></Form.Control>
                </Col>
                <Button
                  className="app-text btn btn-outline-primary search_button strata_search"
                  style={{
                    borderRadius: "1.5rem",
                    width: "100%",
                    color: "#fff",
                  }}
                  disabled={place_id ? false : true}
                  onClick={(e) => onClickSearch(e)}
                >
                  Search
                </Button>
              </Row>
            </div>
          )}
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Insights;
