import { Container, Row, Image, Col } from "react-bootstrap"
import BgPattern1 from './assets/images/bg/pattern/hero_bg_pattern.svg';
import Footer from "./components/footer";
import Header from "./components/header";

const ListReport = () => {

    return (
        <div className="w-100">
            <Image src={BgPattern1} className="position-absolute w-100" ></Image>
            <Container>
                <Header />
                <div className="d-flex w-100 justify-content-center set_page_title">
                    <h1 className='aboutus-title' name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">List of all reports</h1>
                </div>
                <Row className="about-section">
                    <Col xl={6} md={12} className="w-100 about-us-image">
                        <Image src={require('./assets/images/Mask Group.png').default} className=" aboutus-image" style={{ left: '0' }} alt="img" />

                    </Col>
                    <Col xl={6} md={12}>
                        <h3><b>{'\u25CF'} Strata report</b></h3><br />
                        <h3><b>{'\u25CF'} Building & pest report</b></h3><br />
                        <h3><b>{'\u25CF'} Depreciation report</b></h3><br />
                        <h3><b>{'\u25CF'} QS report</b>:</h3>
                        <ul>
                            <li>Detailed cost report (budget estimates and tendering)</li>
                            <li>Initial cost report (pre-funding for construction loans)</li>
                            <li>Progress drawdown report</li>
                            <li>Council report</li>
                            <li>Building insurance replacement valuation report</li>
                        </ul><br />
                        <h3><b>{'\u25CF'} Valuations</b>:</h3>
                        <ul>
                            <li>Mortgage security</li>
                            <li>Family law /  Property settlement</li>
                            <li>Taxation – stamp duty and capital gains</li>
                            <li>Litigation – joint reports and shadow reports</li>
                            <li>Acquisition & resumption</li>
                            <li>Rental determinations</li>
                            <li>Development feasibility</li>
                            <li>Unit entitlement</li>
                            <li>Asset & insurance</li>
                        </ul><br />
                        <h3><b>{'\u25CF'} Dilapidation report</b></h3><br />
                        <h3><b>{'\u25CF'} Pool inspection & certificate</b></h3><br />
                        <h3><b>{'\u25CF'} Handover inspection</b></h3><br />
                        <h3><b>{'\u25CF'} Stage inspection</b></h3><br />
                        {/* <h3><b>{'\u25CF'} Insurances</b>:</h3>
                        <ul>
                            <li>Building</li>
                            <li>Strata</li>
                        </ul> */}
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

export default ListReport;