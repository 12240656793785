import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'
import axios from "axios";
import CardIamge1 from '../../assets/images/svg/notfound.png';
import Cookies from 'universal-cookie';
import { Breathing, Image, Shimmer } from 'react-shimmer'


function OrderPropertyCard(props) {
    const history = useHistory();
    const cookies = new Cookies();
    const [properties, setProperties] = React.useState({});
    let tmp = 1;
    console.log("props");
    console.log(props);
    const [agent, setAgent] = React.useState([]);
    const onClickOrder = async () => {

        history.push({
            pathname: '/purchase',
            // state: { 
            //     address: props.streetname,
            //     formated_address:props.formatted_address,
            //     report_by: props.reportType == "existing" ? '' : '',
            //     number: props.number,
            //     image: props.reportType == "noFound" ? props.image : props.reportType == 'existing' ? props.image : properties != '' ? properties.photos != "" ? properties.photos[0].fullUrl : CardIamge1 : '',
            //     report:props.report,
            //     inspection: props.reportType == "existing" ? props.date : properties != '' ? 'TBC' : 'TBC',
            //     method: props.reportType == "existing" ? "existing" : props.reportType == "noFound" ? 'newReport' : 'independed' ,
            //     report:props.strataReport != undefined || props.strataReport != null || props.strataReport != "" ? props.strataReport : '',
            //     agent:props.reportType == "existing" ? agent : undefined,
            //     typeOfReport: props.reportType
            // }

            state: {
                address: props.address,
                image: properties != '' ? (properties.photos  &&properties.photos.length!==0) ? properties.photos[0].fullUrl : CardIamge1 : '',
                place_id: props.place_id,
                long: props.long,
                lat: props.lat,
                report_type: props.report_type
                // method: props.report_type == "Old" ? "existing" : props.report_type == "New" ? 'newReport' : props.report_tyype == "New" ? 'independed':'' ,
                // image: props.reportType == "noFound" ? props.image : props.reportType == 'existing' ? props.image : properties != '' ? properties.photos != "" ? properties.photos[0].fullUrl : CardIamge1 : '',
            }
        });
    }



    async function getPropertie() {
        
        var token = cookies.get("tokens");

        if (tmp == 1) {


            if (props.reportType == 'moreNew') {
                tmp = 0;
            }

            if (props.reportType == 'existing') {
                const data = {
                    images: props.image,
                    descrition: props.descrition
                }

                const res = await axios.post(
                    process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
                    {
                        action: 'getUserByName',
                        agent: props.reportby
                    }
                )
                console.log("user")
                console.log(props.reportby)
                console.log(res.data[0])
                setAgent(res?.data[0]);
                tmp = 0;
            } else {
                if (props.id != undefined) {
                    var config = {
                        method: 'get',
                        url: 'https://api.domain.com.au/v1/properties/' + props.id,
                        headers: {
                            'Authorization': 'Bearer ' + token,
                        }
                    };

                    axios(config)
                        .then(function (response) {
                            
                            setProperties(response.data);
                        })
                        .catch(function (error) {
                        });
                    tmp = 0;
                }
            }
        }


    }



    useEffect(
        () => {
            getPropertie();
        }, []
    )

    return (
        <div>
            <div className="p-2 report_card" style={{ textAlign: "center" }}>
                {
                    props.report_type == "New" ?
                        <h3 className='report_title'>New report</h3>
                        :
                        props.report_type == 'Old' ?
                            <h3 className='report_title'>Existing report</h3>
                            :
                            props.report_type == 'New Independent' ?
                                <h3 className='report_title'>New independent report</h3> : ''
                }

                <Image alt="img" variant="top" src={props.reportType == "noFound" ? props.image : props.reportType == 'existing' ? props.image : properties != '' && (properties.photos  &&properties.photos.length!==0)? properties?.photos[0].fullUrl : CardIamge1} fallback={<Shimmer maxwidth={300} height={270} />} />

                <div className="report_detail">
                    <h2>{props.streetname}</h2>
                    {
                        props.report_type == 'Old' ?
                            <>
                                <p className="app-text"> {props.descrition} </p>
                                <p className="price-text">$50</p>
                            </>
                            :

                            <>
                                <p className="app-text"> {props.descrition} </p>
                                <p className="price-text">$295</p>
                            </>
                        // : 
                        // <>
                        //     <p className="app-text"> {props.descrition} </p>
                        //     <p className="price-text">$295</p>
                        // </>
                    }

                    <div className="btn btn-primary ordernow_btn" onClick={onClickOrder}>Order now</div>
                </div>
            </div>
        </div>
    )
}

export default OrderPropertyCard
