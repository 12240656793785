/* eslint-disable jsx-a11y/alt-text */
import { Instagram } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Card,
  Col,
  Button,
  Image,
  Row,
  Container,
  Form,
  Modal,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Brand1 from "../../assets/images/logo/brands/12.png";
// import reasources
import { useHistory, useLocation } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import "../../assets/css/footer.css";
import Logo from "../../assets/images/logo/logo.png";
import DilapidationModal from "../Dilapidation";
import HandOverInspectionModal from "../Handover-inspection";
import PoolInspectionModal from "../PoolInspection";
import QsModal from "../QsModal";
import StageInspectionModal from "../stage-inspection";

function Footer() {
  /*global FB*/
  const [show, setShow] = useState(false);
  const [name, set_name] = useState("");
  const [rating, set_rating] = useState(0);
  const [Profile, set_profile] = useState("");
  const [loader, set_loader] = useState(false);
  const [image, set_image] = useState("");
  const [rate, set_rate] = useState(0);
  const [content, set_content] = useState("");
  const handleClose = () => setShow(false);
  const history = useHistory();
  const [dilapidationShow, setDilapidationShow] = useState(false);
  const [poolInspectionShow, setPoolInspectionShow] = useState(false);
  const [handoverInspectionShow, setHandoverInspectionShow] = useState(false);
  const [stageInspectionShow, setStageInspectionShow] = useState(false);
  const [currentYear, setcurrentYear] = useState("");
  const location = useLocation();
  const d = new Date();

  useEffect(() => {
    if (typeof window.FB != "undefined") {
      window.FB.XFBML.parse();
    }
    if (location.hash == "#review") {
      setShow(true);
    }
    let year = d.getFullYear();
    setcurrentYear(year);
  }, []);

  const handleShow = () => {
    set_profile("");
    set_image("");
    set_rate("");
    set_rating("");
    setShow(true);
    history.push({
      pathname: location.pathname,
      hash: "review",
    });
  };

  function add_testimonial() {
    // alert(rate)
    //     alert(typeof (rate))
    toast.dismiss();
    // if (Profile === "") {
    //     toast.error('Select image to upload.')
    //     return false;
    // }
    if (name == "") {
      toast.error("Please enter name.");
      return false;
    }

    if (content === "") {
      toast.error("Please enter the description.");
      return false;
    }
    if (rate <= 0) {
      // alert('')
      toast.error("Please select rating.");
      return false;
    } else {
      const data = new FormData();
      data.append("user_image", Profile);
      data.append("user_type", "Client");
      data.append("name", name);
      data.append("testimonial_rating", rating);
      data.append("testimonial_desc", content);
      data.append("addedBy", "Web");
      set_loader(true);
      return axios
        .post(
          process.env.REACT_APP_NODE_BACKEND_API_URL + "/testimonial/",
          data,
          {
            headers: {
              Authorization: "vw7kddgha5j30fz1r7ev",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setShow(false);
            set_loader(false);
            Swal.fire(
              "",
              "Your rating request submitted successfully.",
              "success"
            );
          }
        })
        .catch((err) => {
          setShow(false);
          set_loader(false);
          Swal.fire("", err.response.data.message, "warning");
        });
    }
  }
  function handleRating(e) {
    set_rating(e);
    set_rate(e / 20);
  }

  function handlDilapidationeModal() {
    setDilapidationShow(true);
  }

  function handlPoolInspectionModal() {
    setPoolInspectionShow(true);
  }

  function handlHandoverInspectionModal() {
    setHandoverInspectionShow(true);
  }

  function handlStageInspectionModal() {
    setStageInspectionShow(true);
  }

  async function ContactUs() {
    await history.push("/");

    // history.push({
    //     pathname: '/',
    //     state: {
    //         scrollTo:{}

    //     },

    // });

    setTimeout(() => {
      var ele = document.getElementById("contact_part");
      console.log(ele);
      if (ele) {
        ele.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, "1000");
  }
  const [showQsModal, set_showQsModal] = useState(false);

  return (
    <div className="w-100 mt-auto">
      <Container>
        <Helmet></Helmet>
        <div className="footer-root">
          <div className="footer-desktop">
            <Row>
              <Col className="col-lg-3 col-md-4">
                <Link to="/">
                  <Image
                    src={window.location.origin + Logo}
                    className="footer-logo"
                    alt="img"
                  ></Image>
                </Link>
                <p className="footer-text my-3">
                  Property reporting without limits <br />
                </p>
                <div className="d-flex align-items-center">
                  <i
                    class="fa fa-linkedin-square"
                    aria-hidden="true"
                    onClick={() =>
                      (window.location.href =
                        "https://www.linkedin.com/company/proptiaus/")
                    }
                    style={{
                      fontSize: "40px",
                      color: "#0077b0",
                      cursor: "pointer",
                    }}
                  ></i>
                  <div style={{ position: "relative" }}>
                    <a
                      href="https://www.facebook.com/propti.com.au"
                      target={"_blank"}
                    >
                      <i
                        class="fa fa-facebook-square mx-2"
                        aria-hidden="true"
                        style={{
                          fontSize: "40px",
                          cursor: "pointer",
                          color: "#0b4cc3",
                        }}
                      ></i>
                    </a>

                    {/* <div class="fb-like" data-href="https://www.facebook.com/rachelsdigestiverelieftea/?ref=pages_you_manage" data-width="50" data-layout="button_count" data-action="like" data-size="large" data-share="false"></div> */}
                    {/* <div
                      class="fb-like like-bt-box"
                       data-href="https://www.facebook.com/propti.com.au"
                      data-width="50"
                      data-layout="button"
                      data-action="like"
                      data-share="false"
                    ></div> */}
                  </div>
                  <a
                    href="https://www.instagram.com/propti.com.au/"
                    target={"_blank"}
                  >
                    <img src={"/instagram.svg"} style={{ height: "36px" }} />
                  </a>
                </div>
                <img className="mt-3" src={Brand1} alt={"tm"} width={150} />
              </Col>

              <Col className="col-lg-2 col-md-4 col-sm-6 no_space">
                <p className="footer-title">About</p>
                <Link className="footer-link" to="/about">
                  <p className="footer-text py-1">About us</p>
                </Link>
                {/* {
                                    localStorage.getItem('userId') != null ?
                                        <Link className="footer-link" to="/wall"><p className="footer-text py-1">Wall</p></Link>
                                        : ''
                                } */}
                <Link className="footer-link" to="/faqs">
                  <p className="footer-text py-1">FAQS</p>
                </Link>
                {/* <Link className="footer-link" onClick={handleShow}>
                  <p className="footer-text py-1">Review</p>
                </Link> */}
                <a className="footer-link" href="https://g.page/r/CTy2guga7pz-EAE/review" target="_blank" rel="noreferrer">
                  <p className="footer-text py-1">Review</p>
                </a>
                <a
                  className="footer-link"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc7c_-D_SaXXZCk3NR_9bqSXBUaO7pSBd-rr5DJH7bgiuYqqA/viewform"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="footer-text py-1">Feedback</p>
                </a>
              </Col>
              <Col lg={2} md={6}>
                <p className="footer-title">Order report</p>
                <div className="strata-scroll">
                  <Link className="footer-link" to="/strata-report">
                    <p className="footer-text py-1">Strata report</p>
                  </Link>
                  <Link className="footer-link" to="/depreciation-report">
                    <p className="footer-text py-1">Depreciation report</p>
                  </Link>
                  <Link className="footer-link" to="/Inspection">
                    <p className="footer-text py-1">Building & Pest report</p>
                  </Link>
                  <Link className="footer-link" to="/valuation">
                    <p className="footer-text py-1">Valuation report</p>
                  </Link>
                  {/* <Link className="footer-link" onClick={() => handleModal()}><p className="footer-text py-1">QS report</p></Link> */}
                  <Link
                    className="footer-link"
                    onClick={() => handlDilapidationeModal()}
                  >
                    <p className="footer-text py-1">Dilapidation report</p>
                  </Link>
                  <Link
                    className="footer-link"
                    onClick={() => handlPoolInspectionModal()}
                  >
                    <p className="footer-text py-1">
                      Pool inspection & certificate
                    </p>
                  </Link>
                  <Link
                    className="footer-link"
                    onClick={() => handlHandoverInspectionModal()}
                  >
                    <p className="footer-text py-1">Handover inspection</p>
                  </Link>
                  <Link
                    className="footer-link"
                    onClick={() => handlStageInspectionModal()}
                  >
                    <p className="footer-text py-1">Stage inspection</p>
                  </Link>
                </div>
              </Col>
              <Col
                className="col-lg-2 col-md-4 col-sm-6 padd_left"
                style={{ paddingLeft: "60px" }}
              >
                <p className="footer-title">Support</p>
                <Link className="footer-link" to="/signup">
                  <p className="footer-text py-1">Partner with us</p>
                </Link>
                <Link className="footer-link" to="./service">
                  <p className="footer-text py-1">Find an agent</p>
                </Link>
                <a className="footer-link" style={{ cursor: "pointer" }}>
                  <p onClick={() => ContactUs()} className="footer-text py-1">
                    Contact us
                  </p>
                </a>
              </Col>
              <Col
                className="col-lg-3 col-md-4 col-sm-6"
                style={{ paddingLeft: "68px" }}
              >
                <p className="footer-title">Resources</p>
                <Link className="footer-link" to="/authority-document">
                  <p className="footer-text py-1">Authority document</p>
                </Link>
                <Link className="footer-link" to="/blog">
                  <p className="footer-text py-1">Articles</p>
                </Link>
                <Link className="footer-link" to="/report_list">
                  <p className="footer-text py-1">List of reports</p>
                </Link>
                <Link className="footer-link" to="/insights">
                  <p className="footer-text py-1">Property Insights</p>
                </Link>
                {/* <Link className="footer-link" to="/term-use"><p className="footer-text py-1">Terms of Use</p></Link> */}
              </Col>
            </Row>
          </div>
          <div className="footer-mobile">
            <Image src={window.location.origin + Logo} alt="img"></Image>
            <p className="footer-text my-3">The home of property reports</p>
            <Accordion accessKey={0}>
              <Card className="border-0">
                <Card.Header className="bg-white">
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    <p className="footer-title px-0">About</p>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Link className="footer-link" to="/about">
                      <p className="footer-text py-1">About Us</p>
                    </Link>
                    {/* {
                                            localStorage.getItem('userId') != null ?
                                                <Link className="footer-link" to="/wall"><p className="footer-text py-1">Wall </p></Link>
                                                : ''
                                        } */}
                    <Link className="footer-link" to="/faqs">
                      <p className="footer-text py-1">FAQS</p>
                    </Link>
                    <Link className="footer-link" onClick={handleShow}>
                      <p className="footer-text py-1">Feedback</p>
                    </Link>
                    {/* <Link className="footer-link" to="/blog"><p className="footer-text py-1">Blogs</p></Link> */}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="border-0">
                <Card.Header className="bg-white">
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    <p className="footer-title px-0">Order Report</p>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="strata-scroll">
                    <Link className="footer-link" to="/strata-report">
                      <p className="footer-text py-1">Strata report</p>
                    </Link>
                    <Link className="footer-link" to="/depreciation-report">
                      <p className="footer-text py-1">Depreciation report</p>
                    </Link>
                    <Link className="footer-link" to="/Inspection">
                      <p
                        className="footer-text py-1"
                        style={{ whiteSPace: "nowrap" }}
                      >
                        Building & Pest report
                      </p>
                    </Link>
                    <Link className="footer-link" to="/valuation">
                      <p className="footer-text py-1">Valuation report</p>
                    </Link>
                    {/* <Link className="footer-link" onClick={() => handleModal()}><p className="footer-text py-1">QS report</p></Link> */}
                    <Link
                      className="footer-link"
                      onClick={() => handlDilapidationeModal()}
                    >
                      <p className="footer-text py-1">Dilapidation report</p>
                    </Link>
                    <Link
                      className="footer-link"
                      onClick={() => handlPoolInspectionModal()}
                    >
                      <p className="footer-text py-1">
                        Pool inspection & certificate
                      </p>
                    </Link>
                    <Link
                      className="footer-link"
                      onClick={() => handlHandoverInspectionModal()}
                    >
                      <p className="footer-text py-1">Handover inspection</p>
                    </Link>
                    <Link
                      className="footer-link"
                      onClick={() => handlStageInspectionModal()}
                    >
                      <p className="footer-text py-1">Stage inspection</p>
                    </Link>
                    {/* <Link className="footer-link" to="/search/strata-report"><p className="footer-text py-1">Order Report</p></Link>
                                        <Link className="footer-link" to="/signup"><p className="footer-text py-2">Partner With Us</p></Link>
                                        {
                                            localStorage.getItem('userId') != null ?
                                                <Link className="footer-link" to="/wall"><p className="footer-text py-1">Wall</p></Link>
                                                : ''
                                        }
                                        <Link className="footer-link" to="/service"><p className="footer-text py-1">Find an Agent</p></Link> */}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="border-0">
                <Card.Header className="bg-white">
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    <p className="footer-title px-0">Support</p>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Link className="footer-link" to="/signup">
                      <p className="footer-text py-1">Partner with us</p>
                    </Link>
                    <Link className="footer-link" to="./service">
                      <p className="footer-text py-1">Find an agent</p>
                    </Link>
                    <a
                      className="footer-link"
                      style={{ cursor: "pointer" }}
                      onClick={() => ContactUs()}
                    >
                      <p className="footer-text py-1">Contact us</p>
                    </a>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="border-0">
                <Card.Header className="bg-white">
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    <p className="footer-title px-0s">Resources</p>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    {/* <Link className="footer-link" to="/privacy-policy"><p className="footer-text py-1">Privacy policy</p></Link> */}
                    {/* <Link className="footer-link" to="/term-use"><p className="footer-text py-1">Terms of Use</p></Link> */}
                    <Link className="footer-link" to="/authority-document">
                      <p className="footer-text py-1">Authority document</p>
                    </Link>
                    <Link className="footer-link" to="/blog">
                      <p className="footer-text py-1">Articles</p>
                    </Link>
                    <Link className="footer-link" to="/report_list">
                      <p className="footer-text py-1">List of reports</p>
                    </Link>
                    <Link className="footer-link" to="/insights">
                      <p className="footer-text py-1">Property Insights</p>
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </Container>
      <div className="w-100 footer-bottom-line ">
        <Container>
          <Row>
            <Col className="text-left">
              <p>Copyright {currentYear} Propti Pty Ltd</p>
            </Col>
            <Col className="text-right">
              <p>
                <a href="/privacy-policy">Privacy Policy</a> |{" "}
                <a href="/term-use">T&C</a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className='add-img-box'>
                        <img src={image == '' ? require('../../assets/images/download_img.png').default : image} />
                        <input type='file' onChange={handle_profile} onClick={(e) => e.target.value = ''} className='upload-img-box'></input>
                    </div> */}
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                maxLength={15}
                onChange={(e) => set_name(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                maxLength={150}
                rows={3}
                onChange={(e) => set_content(e.target.value)}
              />
            </Form.Group>
          </Form>

          <Rating
            allowHover={false}
            onClick={(e) => handleRating(e)}
            ratingValue={rating}
            readonly={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ borderRadius: "30px" }}
            variant="primary"
            disabled={loader}
            onClick={add_testimonial}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <QsModal show={showQsModal} onHide={() => set_showQsModal(false)} />
      <DilapidationModal
        show={dilapidationShow}
        onHide={() => setDilapidationShow(false)}
      />
      <PoolInspectionModal
        show={poolInspectionShow}
        onHide={() => setPoolInspectionShow(false)}
      />
      <HandOverInspectionModal
        show={handoverInspectionShow}
        onHide={() => setHandoverInspectionShow(false)}
      />
      <StageInspectionModal
        show={stageInspectionShow}
        onHide={() => setStageInspectionShow(false)}
      />
    </div>
  );
}

export default Footer;
