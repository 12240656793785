import React, { useEffect } from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import axios from 'axios'
import Footer from '../components/footer'
import Header from '../components/header'
import BgPattern1 from '../assets/images/bg/pattern/hero_bg_pattern.svg';
import BgPattern2 from '../assets/images/bg/pattern/ourwork_bg_pattern.svg'
import BgPattern3 from '../assets/images/bg/pattern/footer_bg_pattern.svg';
import '../assets/css/service.css'
import ServiceCard from '../components/service/servicecard';
import { ListItemIcon } from '@material-ui/core';
import { Helmet } from 'react-helmet';

function Service() {
    const [sortby, set_sortby] = React.useState("All");
    const [agents_list, set_agents_list] = React.useState([])
    window.scrollTo(0, 0);
    // let filtered_data = userRole.filter(Data=>Data.user_type==sortby)


    async function get_agents_list(type) {
        if (type === "") {
            set_sortby("All")
        }
        else if (type === "R") {
            set_sortby("Real Estate Agent")
        }
        else if (type === "S") {
            set_sortby("Strata Reporter")
        }
        else if (type === "C") {
            set_sortby("Solicitor")
        }
        else if (type === "B") {
            set_sortby("Buyers Agent")
        }
        else if (type == "CO") {
            set_sortby("Conveyancer")
        }
        else if (type == "BR") {
            set_sortby("Broker")
        }
        else if (type == "A") {
            set_sortby("Accountant")
        }
        const agents = await axios.get(process.env.REACT_APP_NODE_BACKEND_API_URL + `/auth/?agent_type=${type}`, {
            headers: {
                "Authorization": 'vw7kddgha5j30fz1r7ev'
            }
        })

        set_agents_list(agents.data.data)
    }

    // .then(response=>{
    //     if(response.status==200){
    //         set_agents_list(response.data)
    //     }
    // })
    // if(sortby=="All"){filtered_data = Data}

    // async function getExpert(){
    //     const users = await axios.post(

    //         process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
    //         {
    //             action:"getUserRole",
    //             role:sortby
    //         }
    //     );
    //     setUserRole(users.data);
    // }

    // async function clickFetch(role){
    //     sortby = role;
    //     getExpert();
    // }

    useEffect(
        () => {
            get_agents_list("");
        }, []
    )


    return (
        <div style={{ position: "relative" }}>
                <Helmet>
                <title name="keywords" content="comprehensive database of strata reports, building and pest reports, valuations, depreciation reports, and QS reports in one place">Australia's Real Estate Agents & Brokers | Propti</title>
                </Helmet>
            <Image src={BgPattern1} className="position-absolute w-100" alt="img"></Image>
            <Container>
                <Header></Header>
                <div className="text-center set_page_title">
                    <h1 className="app-title" name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">Find a service</h1>
                </div>
                <Row className="service_section">
                    <Col lg={3}>
                        <p className="app-title sorting_block">Short by</p>
                        <div className='services_list'>
                            <div className={`${sortby == "All" ? "service-active" : ""} service-select-category my-1`} onClick={() => { get_agents_list("") }} >
                                <Link to="#"><p className="app-text my-2">All </p></Link>
                                {/* <Link  to="#"><p className="app-text my-2">All ({userRole.length}) </p></Link> */}
                            </div>
                            {/* <div className={`${sortby=="Reporter"?"service-active":""} service-select-category my-1`} onClick={()=>{setSortby('Reporter')}} >
                            <Link  to="#"><p className="app-text my-2">Reporters ({Data.filter(Data=>Data.user_type=="Reporter").length}) </p></Link>
                        </div> */}
                            <div className={`${sortby == "Real Estate Agent" ? "service-active" : ""} service-select-category my-1`} onClick={() => { get_agents_list("R") }}>
                                <Link to="#"><p className="app-text my-2">Real estate agents</p></Link>
                                {/* <Link  to="#"><p className="app-text my-2">Real Estate Agents ({userRole.filter(Data=>Data.role=="real_estate_agent").length})</p></Link> */}
                            </div>
                            {/* <div className={`${sortby=="Strata Reporter"?"service-active":""} service-select-category my-1`} onClick={()=>{get_agents_list("S")}}>                            
                            <Link  to="#"><p className="app-text my-2">strata Reporters</p></Link> */}
                            {/* <Link  to="#"><p className="app-text my-2">Real Estate Agents ({userRole.filter(Data=>Data.role=="real_estate_agent").length})</p></Link> */}
                            {/* </div> */}
                            <div className={`${sortby == "Solicitor" ? "service-active" : ""} service-select-category my-1`} onClick={
                                () => { get_agents_list("C") }}>
                                <Link to="#"><p className="app-text my-2">Solicitors</p></Link>
                                {/* <Link  to="#"><p className="app-text my-2">Solicicitors ({userRole.filter(Data=>Data.role=="administator").length})</p></Link> */}
                            </div>
                            <div className={`${sortby == "Buyers Agent" ? "service-active" : ""} service-select-category my-1`} onClick={() => { get_agents_list("B") }}>
                                <Link to="#"><p className="app-text my-2">Buyer’s agents</p></Link>
                                {/* <Link  to="#"><p className="app-text my-2">Buyer’s Agents ({userRole.filter(Data=>Data.role=="buyer_agent").length})</p></Link> */}
                            </div>
                            <div className={`${sortby == "Conveyancer" ? "service-active" : ""} service-select-category my-1`} onClick={() => { get_agents_list("CO") }}>
                                <Link to="#"><p className="app-text my-2">Conveyancers</p></Link>
                                {/* <Link  to="#"><p className="app-text my-2">Buyer’s Agents ({userRole.filter(Data=>Data.role=="buyer_agent").length})</p></Link> */}
                            </div>
                            <div className={`${sortby == "Broker" ? "service-active" : ""} service-select-category my-1`} onClick={() => { get_agents_list("BR") }}>
                                <Link to="#"><p className="app-text my-2">Brokers</p></Link>
                                {/* <Link  to="#"><p className="app-text my-2">Buyer’s Agents ({userRole.filter(Data=>Data.role=="buyer_agent").length})</p></Link> */}
                            </div>
                            <div className={`${sortby == "Accountant" ? "service-active" : ""} service-select-category my-1`} onClick={() => { get_agents_list("A") }}>
                                <Link to="#"><p className="app-text my-2">Accountants</p></Link>
                                {/* <Link  to="#"><p className="app-text my-2">Buyer’s Agents ({userRole.filter(Data=>Data.role=="buyer_agent").length})</p></Link> */}
                            </div>
                        </div>
                    </Col>
                    <Col lg={9} style={{ position: "static" }}>
                        {agents_list.length !== 0 ?
                            <Row>
                                {
                                    agents_list.map(
                                        (item) => (
                                            <Col key={item.id} xl={4} md={6} sm={6} style={{ position: "static" }}><ServiceCard id={item._id} agency={item.agency} image={item.profile_image} title={item.name} text={item.agent_type} email={item.email} address={item.address} phone={item.phone_number}></ServiceCard></Col>
                                        )
                                    )
                                }
                            </Row>
                            :
                            <p style={{ fontSize: "20px", textAlign: "center" }}> No data found</p>
                        }
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

const Data = [
    {
        id: '1',
        name: 'Dr. Mathew Wadonal',
        user_type: 'Real Estate Agent'
    },
    {
        id: '2',
        name: 'Dr. Mathew Wadonal',
        user_type: 'Reporter'
    },
    {
        id: '3',
        name: 'Dr. Mathew Wadonal',
        user_type: 'Real Estate Agent'
    },
    {
        id: '4',
        name: 'Dr. Mathew Wadonal',
        user_type: 'Solicicitor'
    },
    {
        id: '5',
        name: 'Dr. Mathew Wadonal',
        user_type: 'Real Estate Agent'
    },
    {
        id: '6',
        name: 'Dr. Mathew Wadonal',
        user_type: 'Buyer’s Agent'
    },
    {
        id: '7',
        name: 'Dr. Mathew Wadonal',
        user_type: 'Real Estate Agent'
    },
    {
        id: '8',
        name: 'Dr. Mathew Wadonal',
        user_type: 'Solicicitor'
    },
    {
        id: '9',
        name: 'Dr. Mathew Wadonal',
        user_type: 'Real Estate Agent'
    },
    {
        id: '10',
        name: 'Dr. Mathew Wadonal',
        user_type: 'Buyer’s Agent'
    },
]
export default Service
