/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Image,
  Row,
} from "react-bootstrap";
import Footer from "../components/footer";
import { useHistory } from "react-router-dom";
import Header from "../components/header";
import { useLocation } from "react-router-dom";
import BgPattern1 from "../assets/images/bg/pattern/hero_bg_pattern.svg";
import axios from "axios";
import { toast } from "react-toastify";
import SelectSearch, { fuzzySearch } from "react-select-search";
import validator from "validator";
import moment from "moment";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";
import MobileInput from "../components/common/MobileInput";
import CompanyInput from "../components/common/CompanyInput";
import { inSightApi } from "../API/blog.api";

function Purchase() {
  const all_date = useLocation();
  const history = useHistory();
  console.log(all_date);

  if (all_date.state == undefined) {
    history.push({
      pathname: "/strata-report",
    });
  }

  const [emailError, setEmailError] = useState("");
  const [emailError2, setEmailError2] = useState("");
  const [emailError1, setEmailError1] = useState("");
  const [mobileError, setmobileError] = useState("");
  const [LetterUrl, setLetterUrl] = useState("");

  const [newInspectionDate, setNewInspectionDate] = useState("TBC");
  const [thebuyeragent, setthebuyeragent] = useState("");
  const [theSolicitoragent, settheSolicitoragent] = useState("");
  const [organizeFinane, setorganizeFinane] = useState("");
  // const [theSolicitoragentfound, settheSolicitoragentfound] = useState('no');
  const [disclimer, setdisclimer] = useState("none");
  const [agent, setAgent] = React.useState([]);
  const [OnBehalf, setOnBehalf] = React.useState("no");
  const [buyeragent, setbuyerAgent] = React.useState([]);
  const [solicitoragent, setsolicitorAgent] = React.useState([]);
  const [btnCheck, setbtnCheck] = React.useState(true);
  const [privateAgent, setPrivateAgent] = React.useState(
    all_date.state != undefined
      ? all_date.state.agent != undefined
        ? all_date.state.agent
        : undefined
      : ""
  );
  const [agentOption, setagentOption] = React.useState([]);
  const [realEstateAgentOption, setRealEstateAgentOption] = React.useState([]);
  const [changeLetter, setChangeLetter] = React.useState([]);

  const [agentBuyerOption, setagentBuyerOption] = React.useState([]);
  const [agentsociliorOption, setagentsociliorOption] = React.useState([]);

  const [selectedProfessionList, setSelectedProfessionList] = useState([]);
  const [abnErr, setabnErr] = React.useState("");
  const [realUser, setrealUser] = React.useState("");
  const [value, setValue] = useState("");
  const [value1, setValue1] = useState("");
  // const [formData, setFormData] = React.useState({
  //     action: "purchase",
  //     address: all_date.state == undefined ? '' : all_date.state.address,
  //     name: "",
  //     agentemail: "",
  //     agentname: "",
  //     email: "",
  //     mobile: "",
  //     firstName: "",
  //     lastName: "",
  //     status: "",
  //     agree: "",
  //     inspectionDate: "NULL",
  //     recommandedAgent: "NULL",
  //     usingbuyerAgentName: "NULL",
  //     usingbuyerAgentEmail: "NULL",
  //     usingSolicitorAgentName: "NULL",
  //     usingSolicitorAgentEmail: "NULL",
  //     theSolicitoragentfound: 'NULL',
  //     authorityLetter: 'NULL',
  //     trustedBroker: 'NULL',
  //     onBehlaf: 'NULL'

  // })
  console.log(all_date.state !== undefined);
  const [formData, setFormData] = React.useState({
    order_report_type: "S",
    place_id:
      all_date.state !== undefined &&
      (all_date.state.place_id
        ? all_date.state.place_id
        : all_date.state.details.place_id
        ? all_date.state.details.place_id
        : ""),
    address:
      all_date.state !== undefined &&
      (all_date.state.address
        ? all_date.state.address
        : all_date.state.details.address
        ? all_date.state.details.address
        : ""),
    location_latitude:
      all_date.state !== undefined &&
      (all_date.state.lat
        ? all_date.state.lat
        : all_date.state.details.location_latitude
        ? all_date.state.details.location_latitude
        : ""),
    location_longitude:
      all_date.state !== undefined &&
      (all_date.state.long
        ? all_date.state.long
        : all_date.state.details.location_longitude
        ? all_date.state.details.location_longitude
        : ""),
    agent_id: "",
    agent_first_name: "",
    agent_email: "",
    requesting_person_first_name: "",
    requesting_person_last_name: "",
    requesting_person_email: "",
    requesting_person_mobile_number: "",
    inspection_date: "",
    do_have_buyers_agent: "",
    need_us_to_recommend: "",
    buyers_agent_id: "",
    buyers_agent_first_name: "",
    buyers_agent_email: "",
    do_have_solicitor_agent: "",
    need_us_to_recommend_solicitor_agent: "",
    solicitor_agent_id: "",
    solicitor_agent_first_name: "",
    solicitor_agent_email: "",
    have_organised_finanace: "",
    like_us_to_reccomend_broker: "",
    purchasing_report_on_behalf_on_someone: "NO",
    your_profession: "",
    other_profession: "",
    agent_id_on_whom_behalf: "",
    authority_letter_for_report: "",
    living_situation: "",
    reason_of_report: "",
    profession_agent_id: "",
    profession_agent_name: "",
    profession_agent_email: "",
    company_id: "",
    company_name: "",
    // comapny_email:"",

    order_type:
      all_date.state !== undefined &&
      (all_date.state.report_type === "Old" ? "O" : "N"),
  });
  const [errName, seterrName] = useState("");
  const [Agent_on_list, set_Agent_on_list] = useState("none");
  const [buyer_agent_on_list, set_buyer_agent_on_list] = useState("none");
  const [profession_agent_on_list, set_profession_agent_on_list] =
    useState("none");
  const [solicitor_agent_on_list, set_solicitor_agent_on_list] =
    useState("none");
  const [Agree, setAgree] = useState(false);
  const notify = (message) => toast(message);
  const [profession_placeholder_name, set_profession_placeholder_name] =
    useState("Select solicitor agent name");
  // async function sendMail(e) {
  //     e.preventDefault();
  //     setFormData({
  //         ...formData,
  //         name: value
  //     });

  //     const data = new FormData();
  //     data.append("file", changeLetter);
  //     data.append("fileName", 'fileName');
  //     data.append("action", 'uploadAutherityLetter');
  //     const res = await axios.post(
  //         process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
  //         data,
  //         {
  //             "encType": "multipart/form-data"
  //         }
  //     )

  //     setLetterUrl(process.env.REACT_APP_BACKEND_API_URL + '/' + res.data.url);

  //     // uploadletter(changeLetter);

  //     history.push({
  //         pathname: '/payment',
  //         // state: {
  //         //     detail: formData,
  //         //     number: all_date.state.number,
  //         //     image:all_date.state.image,
  //         //     address:formData.address,
  //         //     formated_address:all_date.state.formated_address,
  //         //     inspection:newInspectionDate != "TBC" ? dateFormat(newInspectionDate , "dd/mm/yyyy") : dateFormat(all_date.state.inspection, "dd/mm/yyyy"),
  //         //     report:all_date.state.report != '' ? all_date.state.report : '',
  //         //     agent:agent,
  //         //     pvtAgent:privateAgent,
  //         //     typeOfReport:all_date.state.typeOfReport,
  //         //     letter:process.env.REACT_APP_BACKEND_API_URL + '/' + res.data.url,
  //         //     method: all_date.state.method,
  //         //     report_by: all_date.state.report_by,
  //         // }
  //         state:{
  //             detail:formData,
  //             image:all_date.state.image
  //         }
  //     });

  // }

  const date = new Date();
  const newDate = moment(newInspectionDate).add(7, "days").format("DD/MM/YYYY");

  async function validate(evt) {
    var theEvent = evt || window.event;

    if (theEvent.type === "paste") {
      key = window.event.clipboardData.getData("text/plain");
    } else {
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  async function get_agents() {
    //  alert(all_date.state.method)
    // sessionStorage.setItem('method' , all_date.state.method)
    await axios
      .get(process.env.REACT_APP_NODE_BACKEND_API_URL + "/auth/?agent_type=", {
        headers: {
          Authorization: "vw7kddgha5j30fz1r7ev",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          var temp = [];
          var temp2 = [];
          for (var i = 0; i < response.data.data.length; i++) {
            var obj = {};
            if (response.data.data[i].agent_type === "R") {
              obj["name"] = response.data.data[i].name;
              obj["value"] = response.data.data[i]._id;
              temp.push(obj);
            }
            obj["name"] = response.data.data[i].name;
            obj["value"] = response.data.data[i]._id;
            temp2.push(obj);
          }
          setRealEstateAgentOption(temp);
          setagentOption(temp2);
          var temp2 = [];
          response.data.data
            .filter((obj) => obj.agent_type == "B")
            .map((item) => {
              var obj = {};
              obj["name"] = item.name;
              obj["value"] = item._id;
              temp2.push(obj);
            });
          setagentBuyerOption(temp2);
          var temp3 = [];
          response.data.data
            .filter((obj) => obj.agent_type == "C" || obj.agent_type == "CO")
            .map((item) => {
              console.log(item);
              var obj = {};
              obj["name"] = item.name;
              obj["value"] = item._id;
              temp3.push(obj);
            });
          setagentsociliorOption(temp3);
        }
      });
  }

  const [emailError4, setEmailError4] = useState("");

  useEffect(() => {
    get_agents();
    // getUser();
    // getAllAgent();
    window.scrollTo(0, 0);
  }, []);

  async function changeDate(e) {
    setNewInspectionDate(e.target.value);
    setdisclimer("block");
    setFormData({
      ...formData,
      inspection_date: e.target.value,
    });
  }

  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
      setFormData({
        ...formData,
        requesting_person_email: e.target.value.toLowerCase(),
      });
      setbtnCheck(false);
    } else {
      setEmailError("Invalid");
      setbtnCheck(true);
    }
  };

  const validateMobile = (e) => {
    var mobile = e;
    if (mobile && validator.isMobilePhone(mobile, "any")) {
      setmobileError("");
      setFormData({
        ...formData,
        requesting_person_mobile_number: e,
      });
      setbtnCheck(false);
    } else {
      setmobileError("Invalid");
      setbtnCheck(true);
    }
  };

  const ProfessionAgentList = (e) => {
    var type = "";

    if (e.target.value === "real state agent") {
      type = "R";
    } else if (e.target.value === "solicitor") {
      type = "C";
    } else if (e.target.value === "buyers agent") {
      type = "B";
    } else if (e.target.value === "conveyancer") {
      type = "CO";
    }
    axios
      .get(
        process.env.REACT_APP_NODE_BACKEND_API_URL +
          "/auth/?agent_type=" +
          type,
        {
          headers: {
            Authorization: "vw7kddgha5j30fz1r7ev",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.data);
          var temp = [];
          res.data.data.map((agent) => {
            var obj = {};
            obj.name = agent.name;
            obj.value = agent._id;
            temp.push(obj);
          });
          setSelectedProfessionList(temp);
        }
      });
  };

  const [first_agent_check, set_first_agent_check] = useState(false);
  const [buyer_agent_check, set_buyer_agent_check] = useState(false);
  const [solicitor_agent_check, set_solicitor_agent_check] = useState(false);
  const [profession_agent_check, set_profession_agent_check] = useState(false);

  async function add_strata(e) {
    e.preventDefault();
    var agent_list = [];
    console.log(agent_list);

    if (formData.purchasing_report_on_behalf_on_someone === "") {
      // setbtnCheck(true)
      seterrName(
        "You purchasing this report on behalf of someone field is required."
      );
      return false;
    }
    if (
      formData.purchasing_report_on_behalf_on_someone === "YES" &&
      formData.your_profession === ""
    ) {
      seterrName("Profession is required.");
      return false;
    }
    if (
      formData.your_profession !== "" &&
      formData.your_profession !== "other" &&
      formData.your_profession !== "real estate agent" &&
      profession_agent_on_list === "none" &&
      formData.profession_agent_id === ""
    ) {
      seterrName("Please select agent.");
      return false;
    }
    if (
      profession_agent_on_list === "block" &&
      formData.profession_agent_name == ""
    ) {
      seterrName("Please enter agent name.");
      return false;
    }
    if (
      profession_agent_on_list === "block" &&
      formData.profession_agent_email == ""
    ) {
      seterrName("Please enter agent email");
      return false;
    }
    if (
      formData.your_profession === "other" &&
      formData.other_profession === ""
    ) {
      seterrName("Other profession field is required.");
      return false;
    }
    if (Agent_on_list === "none" && formData.agent_id == "") {
      // setbtnCheck(true)
      seterrName("Agent is required.");
      return false;
    }
    if (Agent_on_list === "block" && formData.agent_first_name === "") {
      // setbtnCheck(true)
      seterrName("Agent Name is required.");
      return false;
    }
    if (Agent_on_list === "block" && formData.agent_email === "") {
      // setbtnCheck(true)
      seterrName("Agent Email is required.");
      return false;
    }
    if (Agent_on_list === "block" && !validator.isEmail(formData.agent_email)) {
      // alert("hi")
      setEmailError4("Invalid Email");
      return false;
    }
    if (formData.requesting_person_first_name.trim() === "") {
      // setbtnCheck(true)
      seterrName("First name is required.");
      return false;
    }
    if (formData.requesting_person_last_name.trim() === "") {
      // setbtnCheck(true)
      seterrName("Last name is required.");
      return false;
    }
    if (formData.requesting_person_email === "") {
      // setbtnCheck(true)
      seterrName("Email is required.");
      return false;
    }
    if (formData.requesting_person_mobile_number === "") {
      // setbtnCheck(true)
      seterrName("Phone number is required.");
      return false;
    }
    if (
      all_date.state.report_type !== "Old" &&
      formData.inspection_date === ""
    ) {
      // setbtnCheck(true)
      seterrName("Please select date by which report is required.");
      return false;
    }
    if (
      formData.purchasing_report_on_behalf_on_someone !== "YES" &&
      formData.do_have_buyers_agent === ""
    ) {
      seterrName("Do you have buyer agent is required.");
      return false;
    }
    if (
      formData.do_have_buyers_agent === "YES" &&
      buyer_agent_on_list === "none" &&
      formData.buyers_agent_id === ""
    ) {
      // setbtnCheck(true)
      seterrName("Buyer's agent is required.");
      return false;
    }
    if (
      formData.do_have_buyers_agent === "YES" &&
      buyer_agent_on_list === "block" &&
      formData.buyers_agent_first_name === ""
    ) {
      // setbtnCheck(true)
      seterrName("Buyer agent name is required.");
      return false;
    }
    if (
      formData.do_have_buyers_agent === "YES" &&
      buyer_agent_on_list === "block" &&
      formData.buyers_agent_email === ""
    ) {
      // setbtnCheck(true)
      seterrName("Buyer email is required.");
      return false;
    }
    if (
      formData.do_have_buyers_agent === "YES" &&
      buyer_agent_on_list === "block" &&
      !validator.isEmail(formData.buyers_agent_email)
    ) {
      setEmailError1("Invalid Email");
      return false;
    }
    if (
      formData.do_have_buyers_agent === "NO" &&
      formData.need_us_to_recommend === ""
    ) {
      // setbtnCheck(true)
      seterrName("Do you want us to recommend buyer agent is required.");
      return false;
    }
    if (
      formData.purchasing_report_on_behalf_on_someone !== "YES" &&
      formData.do_have_solicitor_agent === ""
    ) {
      seterrName("Do you have Solicitor/ conveyancer  is required.");
      return false;
    }
    if (
      formData.do_have_solicitor_agent === "YES" &&
      solicitor_agent_on_list === "none" &&
      formData.solicitor_agent_id === ""
    ) {
      // setbtnCheck(true)
      seterrName("Solicitor/ conveyancer  is required.");
      return false;
    }
    if (
      formData.do_have_solicitor_agent == "YES" &&
      solicitor_agent_on_list === "block" &&
      formData.solicitor_agent_first_name === ""
    ) {
      // setbtnCheck(true)
      seterrName("Solicitor agent name is required.");
      return false;
    }
    if (
      formData.do_have_solicitor_agent == "YES" &&
      solicitor_agent_on_list === "block" &&
      formData.solicitor_agent_email === ""
    ) {
      // setbtnCheck(true)
      seterrName("Solicitor agent email is required.");
      return false;
    }
    if (
      formData.do_have_solicitor_agent == "YES" &&
      solicitor_agent_on_list === "block" &&
      !validator.isEmail(formData.solicitor_agent_email)
    ) {
      setEmailError2("Invalid");
      return false;
    }
    if (
      formData.do_have_solicitor_agent === "NO" &&
      formData.need_us_to_recommend_solicitor_agent === ""
    ) {
      // setbtnCheck(true)
      seterrName(
        "Do you want us to recommend you a solicitor/ conveyancer is required."
      );
      return false;
    }
    if (
      formData.purchasing_report_on_behalf_on_someone !== "YES" &&
      formData.have_organised_finanace === ""
    ) {
      // setbtnCheck(true)
      seterrName("Have you organized finance field is required.");
      return false;
    }
    if (
      formData.have_organised_finanace === "NO" &&
      formData.like_us_to_reccomend_broker === ""
    ) {
      // setbtnCheck(true)
      seterrName(
        "Would you like us to recommend you an affiliated finance broker is required. "
      );
      return false;
    }

    if (
      all_date.state.report_type !== "Old" &&
      formData.authority_letter_for_report === ""
    ) {
      // setbtnCheck(true)
      seterrName("Upload authority letter.");
      return false;
    }
    if (
      formData.purchasing_report_on_behalf_on_someone !== "YES" &&
      formData.reason_of_report === ""
    ) {
      seterrName("Please select purpose of property.");
      return false;
    }
    // if(formData.company_id==="" && formData.company_name===""){
    //     seterrName("Please select your company.")
    //     return false;
    // }
    if (Agree === false) {
      seterrName("Please accept terms and conditions.");
      return false;
    }

    if (formData.agent_email !== "") {
      agent_list.push(formData.agent_email);
    }
    if (formData.buyers_agent_email !== "") {
      agent_list.push(formData.buyers_agent_email);
    }
    if (formData.solicitor_agent_email !== "") {
      agent_list.push(formData.solicitor_agent_email);
    }

    seterrName("");
    setEmailError4("");
    if (agent_list.length !== 0) {
      var data = {
        data: agent_list,
      };

      axios
        .post(
          process.env.REACT_APP_NODE_BACKEND_API_URL +
            "/report/checkIfAgentEmailsExistOrNot",
          data,
          {
            headers: {
              Authorization: "vw7kddgha5j30fz1r7ev",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            history.push({
              pathname: "/payment",
              state: {
                details: formData,
                Agent_on_list: Agent_on_list,
                buyer_agent_on_list: buyer_agent_on_list,
                profession_agent_check: profession_agent_check,
                profession_agent_on_list: profession_agent_on_list,
                solicitor_agent_on_list: solicitor_agent_on_list,
                image: all_date.state.image,
                address: all_date.state.address,
                report_type: all_date.state.report_type,
                date: newDate,
              },
            });
          }
        })
        .catch((err) => {
          // Swal.fire('',err.response.data.message ,'warning')
          seterrName(err.response.data.message);
          return false;
        });
    }
    {
      agent_list.length <= 0 &&
        history.push({
          pathname: "/payment",
          state: {
            details: formData,
            Agent_on_list: Agent_on_list,
            buyer_agent_on_list: buyer_agent_on_list,
            profession_agent_on_list: profession_agent_on_list,
            profession_agent_check: profession_agent_check,
            solicitor_agent_on_list: solicitor_agent_on_list,
            image: all_date.state.image,
            address: all_date.state.address,
            report_type: all_date.state.report_type,
            date: newDate,
          },
        });
    }
  }

  const options = [
    {
      name: "Annie Cruz",
      value: "annie.cruz",
      photo: "https://randomuser.me/api/portraits/women/60.jpg",
    },
    {
      name: "Eli Shelton",
      disabled: true,
      value: "eli.shelton",
      photo: "https://randomuser.me/api/portraits/men/7.jpg",
    },
    {
      name: "Loretta Rogers",
      value: "loretta.rogers",
      photo: "https://randomuser.me/api/portraits/women/51.jpg",
    },
    {
      name: "Lloyd Fisher",
      value: "lloyd.fisher",
      photo: "https://randomuser.me/api/portraits/men/34.jpg",
    },
    {
      name: "Tiffany Gonzales",
      value: "tiffany.gonzales",
      photo: "https://randomuser.me/api/portraits/women/71.jpg",
    },
  ];

  return (
    <div>
      <Image
        src={BgPattern1}
        className="position-absolute w-100"
        style={{ zIndex: "-1" }}
        alt="img"
      ></Image>
      <Container>
        <Header />
        <div className="text-center set_page_title">
          <h1
            className="app-title"
            name="keywords"
            content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more."
          >
            Order strata report
          </h1>
        </div>
        <Row className="strata-Rep-section">
          <Col md={4}>
            <Image
              src={all_date.state == undefined ? "" : all_date.state.image}
              className="w-100"
              style={{ borderRadius: "40px" }}
              alt="img"
            ></Image>
            <div className="text-center">
              <p className="strata-img-title">
                {all_date.state == undefined ? "" : all_date.state.address}
              </p>
              {all_date.state.report_type !== "Old" && (
                <p className="strata-img-date">
                  {newInspectionDate != "TBC"
                    ? "Estimated inspection date : " + newDate
                    : "Estimated inspection date : " + "TBC"}{" "}
                </p>
              )}
              {all_date.state.report_type !== "Old" && (
                <p
                  className="strata-img-date covid_info"
                  style={{ display: disclimer }}
                >
                  {
                    <p>
                      Due to COVID19, the inspection may take longer; the team
                      will notify you once the inspection has been scheduled.
                    </p>
                  }
                </p>
              )}
            </div>
          </Col>

          <Col md={8} className="strata-rep-form">
            {formData.purchasing_report_on_behalf_on_someone == "YES" ? (
              <>
                <Col md={12} className="p-0 my-3">
                  <Form.Label className="contact-form-label">
                    What is your profession?*
                  </Form.Label>
                  <select
                    placeholder="Choose one"
                    value={formData.your_profession}
                    className="contact-form-input select_tab"
                    onChange={(e) => {
                      {
                        e.target.value !== "other" &&
                          e.target.value !== "" &&
                          e.target.value !== "real state agent" &&
                          ProfessionAgentList(e);
                      }
                      setFormData({
                        ...formData,
                        your_profession: e.target.value,
                        other_profession: "",
                        profession_agent_id: "",
                        // profession_placeholder_name:e.target.value==="buyers agent" ?"Select buyer agent name":e.target.value==="conveyancer"?"Select conveyancer agent name":e.target.value="solicitor"?"Select solicitor agent name":''
                      });
                      set_profession_placeholder_name(
                        e.target.value === "buyers agent"
                          ? "Select buyers agent name"
                          : e.target.value === "conveyancer"
                          ? "Select conveyancer name"
                          : (e.target.value = "solicitor"
                              ? "Select solicitor name"
                              : "")
                      );
                      set_profession_agent_check(false);
                      set_profession_agent_on_list("none");
                    }}
                  >
                    <option value="" class="hide_text">
                      Choose one
                    </option>
                    <option value="real estate agent">Real estate Agent</option>
                    <option value="solicitor">Solicitor</option>
                    <option value="buyers agent">Buyers agent</option>
                    <option value="conveyancer">Conveyancer</option>
                    {/* <option value='other'>Other</option> */}
                  </select>
                </Col>
                {formData.your_profession !== "other" &&
                  formData.your_profession !== "" &&
                  formData.your_profession !== "real estate agent" && (
                    <Col md={12} className="my-3 p-0">
                      <Row>
                        <Col sm={7}>
                          <SelectSearch
                            options={selectedProfessionList}
                            value={formData.profession_agent_id}
                            emptyMessage="No agent found"
                            onChange={(e) => {
                              console.log(selectedProfessionList);
                              setFormData({
                                ...formData,
                                profession_agent_id: e,
                                profession_agent_name: "",
                                profession_agent_email: "",
                              });
                              set_profession_agent_check(false);
                              // set_profession_agent_on_list("none")
                            }}
                            search
                            disabled={profession_agent_check}
                            filterOptions={fuzzySearch}
                            placeholder={profession_placeholder_name}
                          />
                        </Col>
                        <Col sm={5} className="agent_not_found">
                          <input
                            type="checkbox"
                            name="noagent1"
                            id="noagent1"
                            checked={profession_agent_check}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                profession_agent_id: "",
                              });
                              set_profession_agent_check(e.target.checked);
                              e.target.checked
                                ? set_profession_agent_on_list("block")
                                : set_profession_agent_on_list("none");
                            }}
                          />
                          <label
                            for="noagent1"
                            className="form-check-label"
                            style={{ color: "#1D83FF" }}
                          >
                            {formData.your_profession === "solicitor"
                              ? "Solicitor"
                              : formData.your_profession === "buyers agent"
                              ? "Buyers agent"
                              : formData.your_profession === "conveyancer"
                              ? "Conveyancer"
                              : ""}{" "}
                            not on list
                          </label>
                        </Col>
                      </Row>
                    </Col>
                  )}
                <Row>
                  <Col
                    md={6}
                    className="my-3"
                    style={{ display: profession_agent_on_list }}
                  >
                    <Form.Label className="contact-form-label">
                      Your name*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name here"
                      className="contact-form-input"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          profession_agent_name: e.target.value,
                          profession_agent_id: "",
                        })
                      }
                      value={formData.profession_agent_name}
                    ></Form.Control>
                  </Col>
                  <Col
                    md={6}
                    className="my-3"
                    style={{ display: profession_agent_on_list }}
                  >
                    <Form.Label className="contact-form-label">
                      Your email*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter email here"
                      className="contact-form-input"
                      value={formData.profession_agent_email}
                      onChange={
                        (e) =>
                          setFormData({
                            ...formData,
                            profession_agent_email: e.target.value,
                          })

                        // (e) => validateAgentEmail(e)
                      }
                    ></Form.Control>
                    <span className="err">{emailError1}</span>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}

            <Form>
              <FormGroup className="srch_agent">
                <Form.Label className="contact-form-label">
                  What is the real estate agent name*
                </Form.Label>
              </FormGroup>

              <Row className="srch_agent">
                <Col xl={8} lg={12} md={12}>
                  <SelectSearch
                    options={realEstateAgentOption}
                    value={formData.agent_id}
                    onChange={(e) => {
                      set_first_agent_check(false);
                      set_Agent_on_list("none");
                      setFormData({
                        ...formData,
                        agent_id: e,
                        agent_first_name: "",
                        agent_email: "",
                      });
                    }}
                    search
                    disabled={first_agent_check}
                    filterOptions={fuzzySearch}
                    emptyMessage="No agent found"
                    placeholder="Search real estate agent name"
                  />
                </Col>
                <Col xl={4} lg={12} md={12} className="agent_not_found">
                  <input
                    type="checkbox"
                    name="noagent"
                    id="noagent"
                    checked={first_agent_check}
                    onChange={(e) => {
                      // alert(e.target.checked)
                      e.target.checked === false &&
                        setFormData({
                          ...formData,
                          agent_first_name: "",
                          agent_email: "",
                        });
                      setFormData({ ...formData, agent_id: "" });
                      set_first_agent_check(e.target.checked);
                      e.target.checked
                        ? set_Agent_on_list("block")
                        : set_Agent_on_list("none");
                    }}
                  />
                  <label
                    for="noagent"
                    className="form-check-label"
                    style={{ color: "#1D83FF" }}
                  >
                    {" "}
                    Real estate agent not on list
                  </label>
                </Col>
              </Row>

              <Row>
                <Col md={6} className="my-3" style={{ display: Agent_on_list }}>
                  <Form.Label className="contact-form-label">
                    Real estate agent name*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter real estate agent name here"
                    className="contact-form-input"
                    value={formData.agent_first_name}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        agent_first_name: e.target.value,
                      })
                    }
                  ></Form.Control>
                </Col>
                <Col md={6} className="my-3" style={{ display: Agent_on_list }}>
                  <Form.Label className="contact-form-label">
                    Real estate agent email*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter real estate agent email here"
                    className="contact-form-input"
                    value={formData.agent_email}
                    onChange={
                      (e) =>
                        setFormData({
                          ...formData,
                          agent_email: e.target.value,
                        })
                      // (e) => validateSimpleAgentEmail(e)
                    }
                  ></Form.Control>
                  <span className="err">{emailError4}</span>
                </Col>
                <Col md={6} className="my-3">
                  <Form.Label className="contact-form-label">
                    {formData.purchasing_report_on_behalf_on_someone !== "YES"
                      ? "Your first name"
                      : "Client first name"}
                    *
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your first name here"
                    className="contact-form-input"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        requesting_person_first_name: e.target.value,
                      })
                    }
                    required
                  ></Form.Control>
                </Col>
                <Col md={6} className="my-3">
                  <Form.Label className="contact-form-label">
                    {formData.purchasing_report_on_behalf_on_someone !== "YES"
                      ? "Your last name"
                      : "Client last name"}
                    *
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your last name here"
                    className="contact-form-input"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        requesting_person_last_name: e.target.value,
                      })
                    }
                    required
                  ></Form.Control>
                </Col>
                <Col md={6} className="my-3">
                  <Form.Label className="contact-form-label">Email*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your email here"
                    className="contact-form-input"
                    onChange={(e) => validateEmail(e)}
                    required
                  ></Form.Control>
                  <span className="err">{emailError}</span>
                </Col>
                <Col md={6} className="my-3">
                  <Form.Label className="contact-form-label">
                    Mobile number*
                  </Form.Label>
                  <MobileInput
                    value={formData.requesting_person_mobile_number}
                    onChange={(e) => validateMobile(e)}
                  />
                  <span className="err">{mobileError}</span>
                </Col>

                {/* Are you using a buyers agent? */}
                {formData.purchasing_report_on_behalf_on_someone !== "YES" && (
                  <Col md={12} className="my-3">
                    <Form.Label className="contact-form-label">
                      Do you have a buyers agent?*
                    </Form.Label>
                    <select
                      placeholder="Choose one"
                      className="contact-form-input select_tab"
                      onChange={(e) => {
                        if (
                          e.target.value === "not" ||
                          e.target.value === "no"
                        ) {
                          set_buyer_agent_check(false);
                        }
                        setFormData({
                          ...formData,
                          do_have_buyers_agent:
                            e.target.value === "yes"
                              ? "YES"
                              : e.target.value === "not"
                              ? "NO"
                              : "no",
                          buyers_agent_id: "",
                        });
                      }}
                    >
                      <option value="no" class="hide_text">
                        Choose one
                      </option>
                      <option value="yes">Yes</option>
                      <option value="not">No</option>
                    </select>
                  </Col>
                )}

                {formData.do_have_buyers_agent == "YES" ? (
                  <>
                    <Col md={12} className="my-3">
                      <Row>
                        <Col sm={8}>
                          <SelectSearch
                            options={agentBuyerOption}
                            value={formData.buyers_agent_id}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                buyers_agent_id: e,
                                buyers_agent_first_name: "",
                                buyers_agent_email: "",
                              });
                              set_buyer_agent_check(false);
                            }}
                            search
                            disabled={buyer_agent_check}
                            filterOptions={fuzzySearch}
                            emptyMessage="No agent found"
                            placeholder="Search buyers agent name"
                          />
                        </Col>
                        <Col sm={4} className="agent_not_found">
                          <input
                            type="checkbox"
                            name="noagent1"
                            id="noagent1"
                            checked={buyer_agent_check}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                buyers_agent_id: "",
                              });
                              set_buyer_agent_check(e.target.checked);
                              e.target.checked
                                ? set_buyer_agent_on_list("block")
                                : set_buyer_agent_on_list("none");
                            }}
                          />
                          <label
                            for="noagent1"
                            className="form-check-label"
                            style={{ color: "#1D83FF" }}
                          >
                            {" "}
                            Buyers agent not on list
                          </label>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      md={6}
                      className="my-3"
                      style={{ display: buyer_agent_on_list }}
                    >
                      <Form.Label className="contact-form-label">
                        Buyers agent name*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter name here"
                        className="contact-form-input"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            buyers_agent_first_name: e.target.value,
                            buyers_agent_id: "",
                          })
                        }
                        value={formData.buyers_agent_first_name}
                      ></Form.Control>
                    </Col>
                    <Col
                      md={6}
                      className="my-3"
                      style={{ display: buyer_agent_on_list }}
                    >
                      <Form.Label className="contact-form-label">
                        Buyers agent email*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter email here"
                        className="contact-form-input"
                        value={formData.buyers_agent_email}
                        onChange={
                          (e) =>
                            setFormData({
                              ...formData,
                              buyers_agent_email: e.target.value,
                            })

                          // (e) => validateAgentEmail(e)
                        }
                      ></Form.Control>
                      <span className="err">{emailError1}</span>
                    </Col>
                  </>
                ) : formData.do_have_buyers_agent == "NO" ? (
                  <Col md={12} className="my-3">
                    <Form.Label className="contact-form-label">
                      Do you want us to recommend you one?*
                    </Form.Label>
                    <select
                      placeholder="Choose one"
                      className="contact-form-input select_tab"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          need_us_to_recommend: e.target.value,
                        })
                      }
                    >
                      <option value="NULL" class="hide_text">
                        Choose one
                      </option>
                      <option value="YES">Yes</option>
                      <option value="NO">No</option>
                    </select>
                  </Col>
                ) : (
                  ""
                )}

                {/*Do you have a solicitor?*/}
                {formData.purchasing_report_on_behalf_on_someone !== "YES" && (
                  <Col md={12} className="my-3">
                    <Form.Label className="contact-form-label">
                      Do you have a solicitor or a conveyancer?*
                    </Form.Label>
                    <select
                      placeholder="Choose one"
                      className="contact-form-input select_tab"
                      onChange={(e) => {
                        if (
                          e.target.value === "not" ||
                          e.target.value === "no"
                        ) {
                          set_solicitor_agent_check(false);
                        }

                        setFormData({
                          ...formData,
                          do_have_solicitor_agent:
                            e.target.value === "yes"
                              ? "YES"
                              : e.target.value === "not"
                              ? "NO"
                              : "no",
                          solicitor_agent_id: "",
                        });
                      }}
                    >
                      <option value="no" class="hide_text">
                        Choose one
                      </option>
                      <option value="yes">Yes</option>
                      <option value="not">No</option>
                    </select>
                  </Col>
                )}
                {formData.do_have_solicitor_agent == "YES" ? (
                  <>
                    <Col md={12} className="my-3">
                      <Row>
                        <Col sm={7}>
                          {/* <SelectSearch
                                                            options={solicitoragent}
                                                            value={value}
                                                            onChange={(e)=>selectAgent2(e)}
                                                            search
                                                            filterOptions={fuzzySearch}
                                                            placeholder="Search Agent Name"
                                                        /> */}
                          <SelectSearch
                            options={agentsociliorOption}
                            value={formData.solicitor_agent_id}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                solicitor_agent_id: e,
                                solicitor_agent_first_name: "",
                                solicitor_agent_email: "",
                              });
                              set_solicitor_agent_check(false);
                            }}
                            search
                            disabled={solicitor_agent_check}
                            filterOptions={fuzzySearch}
                            placeholder="Search solicitor or conveyancer name"
                          />
                        </Col>
                        <Col sm={5} className="agent_not_found">
                          <input
                            type="checkbox"
                            name="noagent2"
                            id="noagent2"
                            checked={solicitor_agent_check}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                solicitor_agent_id: "",
                              });
                              set_solicitor_agent_check(e.target.checked);
                              e.target.checked
                                ? set_solicitor_agent_on_list("block")
                                : set_solicitor_agent_on_list("none");
                            }}
                          />
                          <label
                            for="noagent2"
                            className="form-check-label"
                            style={{ color: "#1D83FF", fontSize: "14px" }}
                          >
                            {" "}
                            Solicitor or conveyancer not on list
                          </label>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      md={6}
                      className="my-3"
                      style={{ display: solicitor_agent_on_list }}
                    >
                      <Form.Label className="contact-form-label">
                        Solicitor or Conveyancer name*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter name here"
                        className="contact-form-input"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            solicitor_agent_first_name: e.target.value,
                            solicitor_agent_id: "",
                          })
                        }
                      ></Form.Control>
                    </Col>
                    <Col
                      md={6}
                      className="my-3"
                      style={{ display: solicitor_agent_on_list }}
                    >
                      <Form.Label className="contact-form-label">
                        Solicitor or Conveyancer email*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter email here"
                        className="contact-form-input"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            solicitor_agent_email: e.target.value,
                          })
                        }
                      ></Form.Control>
                      <span className="err">{emailError2}</span>
                    </Col>
                  </>
                ) : formData.do_have_solicitor_agent === "NO" ? (
                  <Col md={12} className="my-3">
                    <Form.Label className="contact-form-label">
                      Do you want us to recommend you one?*
                    </Form.Label>
                    <select
                      placeholder="Choose one"
                      className="contact-form-input select_tab"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          need_us_to_recommend_solicitor_agent:
                            e.target.value === "yes" ? "YES" : "NO",
                        })
                      }
                    >
                      <option value="no" class="hide_text">
                        Choose one
                      </option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </Col>
                ) : (
                  ""
                )}

                {formData.purchasing_report_on_behalf_on_someone !== "YES" && (
                  <Col md={12} className="my-3">
                    <Form.Label className="contact-form-label">
                      Have you organised finance?*
                    </Form.Label>
                    <select
                      placeholder="Choose one"
                      className="contact-form-input select_tab"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          have_organised_finanace:
                            e.target.value === "yes"
                              ? "YES"
                              : e.target.value === ""
                              ? ""
                              : "NO",
                          like_us_to_reccomend_broker: "",
                        })
                      }
                    >
                      <option value="" class="hide_text">
                        Choose one
                      </option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </Col>
                )}

                {formData.have_organised_finanace == "NO" ? (
                  <Col md={12} className="my-3">
                    <Form.Label className="contact-form-label">
                      Would you like us to recommend you a affiliated broker?*
                    </Form.Label>
                    <select
                      placeholder="Choose one"
                      className="contact-form-input select_tab"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          like_us_to_reccomend_broker:
                            e.target.value === "yes"
                              ? "YES"
                              : e.target.value === ""
                              ? ""
                              : "NO",
                        })
                      }
                    >
                      <option value="" class="hide_text">
                        Choose one
                      </option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </Col>
                ) : (
                  ""
                )}

                {all_date.state.report_type !== "Old" && (
                  <Col md={12} className="my-3">
                    {/* <Form.Label className="contact-form-label">Requesting Report*</Form.Label> */}
                    <Form.Label className="contact-form-label">
                      Report required by*{" "}
                      <span>
                        <i
                          style={{ cursor: "pointer" }}
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-tip="Our reports take 24-48 hours once the inspection has been booked."
                        ></i>
                        <ReactTooltip />
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter date here"
                      min={moment(date).format("YYYY-MM-DD")}
                      className="contact-form-input"
                      onChange={(e) => changeDate(e)}
                      required
                    ></Form.Control>
                    <img
                      src={require("../assets/images/calendar.png").default}
                      className="calendar-custom-icon"
                      alt="img"
                    ></img>
                  </Col>
                )}

                {all_date.state.report_type !== "Old" && (
                  <Col md={12} className="my-3">
                    <Form.Label className="contact-form-label">
                      Upload letter of authority*
                      <span>
                        <i
                          style={{ cursor: "pointer" }}
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-tip="If you don't have the letter of authority please upload the first page of the contract of sale."
                        ></i>
                        <ReactTooltip />
                      </span>
                    </Form.Label>{" "}
                    <br />
                    <Form.Control
                      className="contact-form-input contact-form-input-copy "
                      style={{ width: "100%" }}
                      name="purchage-type"
                      inline
                      type="file"
                      accept="application/pdf"
                      id="file"
                      onChange={(e) => {
                        e.target.files[0].size <= 10000000 &&
                        e.target.files[0].type === "application/pdf"
                          ? setFormData({
                              ...formData,
                              authority_letter_for_report: e.target.files[0],
                            })
                          : Swal.fire(
                              "",
                              "File type is not pdf or it is larger than 10 MB. "
                            );
                      }}
                    ></Form.Control>
                    <Form.Control
                      className="contact-form-input"
                      style={{ width: "100%" }}
                      name="purchage-type"
                      inline
                      type="text"
                      accept="application/pdf"
                      id="file"
                      placeholder="Please upload authority letter here"
                      value={formData.authority_letter_for_report.name}

                      // onChange={

                      //     (e) => {
                      //         setFormData({ ...formData, authority_letter_for_report: e.target.files[0] })
                      //         console.log(e.target.files[0])
                      //     }}
                    ></Form.Control>
                    <br />
                  </Col>
                )}
                {/* {formData.purchasing_report_on_behalf_on_someone !== "YES" &&
                                    <Col md={12} className="my-2">
                                        <Form.Label className="contact-form-label" >What is your current living situation?*</Form.Label> <br />
                                        <Form.Check className="app-text strata-redio circle-center" name='purchage-type' inline label='Renting' type='radio' id='rent' style={{ color: '#756c84' }}
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    living_situation: e.target.id
                                                })
                                            }

                                        ></Form.Check>

                                        <Form.Check className="app-text strata-redio  circle-center" name='purchage-type' inline label='Living with parents' type='radio' id='living with parent' style={{ color: '#756c84' }}
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    living_situation: e.target.id
                                                })
                                            }

                                        ></Form.Check>

                                        <Form.Check className="app-text strata-redio  circle-center" name='purchage-type' inline label='Own home' type='radio' id='own home' style={{ color: '#756c84' }}
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    living_situation: e.target.id
                                                })
                                            }

                                        ></Form.Check>
                                    </Col>} */}
              </Row>
              {formData.purchasing_report_on_behalf_on_someone !== "YES" && (
                <>
                  <Form.Label className="contact-form-label">
                    What is the purpose of the property you are interested in
                    purchasing?*
                  </Form.Label>
                  <br />
                  <div className="d-flex set-radio-mobile ">
                    <Form.Check
                      className="app-text strata-redio circle-center"
                      name="live-type"
                      inline
                      label="To live in"
                      type="radio"
                      id="To live in"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          reason_of_report: e.target.id,
                        })
                      }
                    ></Form.Check>
                    <Form.Check
                      className="app-text strata-redio circle-center"
                      name="live-type"
                      inline
                      label="To use as an investment"
                      type="radio"
                      id="To use as an investment"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          reason_of_report: e.target.id,
                        })
                      }
                    ></Form.Check>
                    <Form.Check
                      className="app-text strata-redio circle-center"
                      name="live-type"
                      inline
                      label="Currently selling"
                      type="radio"
                      id="Currently selling"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          reason_of_report: e.target.id,
                        })
                      }
                    ></Form.Check>
                    {/* <Form.Check className="app-text strata-redio" name='purchage-type' inline label='Neither renting nor selling' type='radio' id='neither renting nor selling'
                                    onChange={
                                        (e) => setFormData({
                                            ...formData,
                                            reason_of_report: e.target.id
                                        })
                                    }
                                ></Form.Check> */}
                  </div>
                </>
              )}
              <CompanyInput formData={formData} setFormData={setFormData} />
              <Form.Check
                className="app-text agree-checkbox"
                name="purchage-agree"
                label="I agree to terms and conditions"
                type="checkbox"
                id="agree"
                onChange={(e) => setAgree(e.target.checked)}
              ></Form.Check>

              <Row className="justify-content-center my-4">
                <p style={{ color: "red", width: "100%", textAlign: "center" }}>
                  {" "}
                  {errName}{" "}
                </p>
                <Button
                  className="navigation_button app-text"
                  style={{ borderRadius: "1.5rem", width: "100%" }}
                  onClick={() => {
                    history.push("/strata-report");
                  }}
                >
                  Go back
                </Button>

                <Button
                  className="navigation_button app-text"
                  type="submit"
                  style={{ borderRadius: "1.5rem", width: "100%" }}
                  onClick={(e) => add_strata(e)}
                >
                  Order now
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Purchase;
