import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Container, Form, Image, FormGroup, Row } from 'react-bootstrap'
import Footer from '../../components/footer'
import Header from '../../components/header'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import Cookies from 'universal-cookie';
import GetProperties from '../getProperties'
import BgPattern1 from '../../assets/images/bg/pattern/hero_bg_pattern.svg';
import Autocomplete from 'react-google-autocomplete';
import validator from 'validator'
import Swal from 'sweetalert2';
import ReactTooltip from 'react-tooltip';
import { Helmet } from 'react-helmet';
import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import MobileInput from '../../components/common/MobileInput';
import CompanyInput from '../../components/common/CompanyInput'
var qs = require('qs');

export default function OrderBuilding() {
    const inputRef = useRef(null);
    const cookies = new Cookies();
    const all_date = useLocation();
    let tmp = 1;
    const [images, setImages] = React.useState('');
    const [allProperties, setAllProperties] = React.useState([]);

    const [myownproperties, setownproperties] = React.useState([]);
    var address = null;
    // const [address, setupcomingAddress] = React.useState(null);
    const [token, setToken] = React.useState("");
    var minm = 100000000000;
    var maxm = 999999999999;
    var code = Math.floor(Math.random() * (maxm - minm + 1)) + minm;

    const [btnCheck, setbtnCheck] = React.useState(true);
    const [reportDetail, setreportDetail] = React.useState('');
    const [searchReport, setSearchReport] = React.useState([]);
    const [found, setFound] = React.useState('');
    const [addressComponent, setAddressComponent] = React.useState([]);
    var addressComponent1 = [];

    const [searchProperty, setSearchProperty] = React.useState({
        address: address,
        method: "new",
        type: "valuation"
    })

    async function set_component(data, address) {
        // setAddressComponent(data);
        // address = all_date.state.address;
        addressComponent1 = data
        var addpr = {
            address: address,
            method: 'new'
        }
        setImages(<GetProperties
            address={addpr}
            component={data}
        />);
    }

    const [search, setSearch] = React.useState("block");
    const [detail1, setDetail1] = React.useState("none");


    // const [formData, setFormData] = React.useState({
    //     action:"purchase",
    //     name:"",
    //     address:"",
    //     email:"",
    //     mobile:"",
    //     purchase_price:"",
    //     purchase_date:"",
    //     settlement_date:"",
    //     manager_name:"",
    //     status:"",
    //     
    // })

    const [formData, setFormData] = React.useState({
        order_report_type: "D",
        order_type: "N",
        place_id: "",
        address: "",
        location_latitude: "",
        location_longitude: "",
        ip_address: "",
        propery_type: "",
        rental_start_date: "",
        construction_completion_date: "",
        construction_cost: "",
        renovations: "",
        owners_name: "",
        owner_twos_name: "",
        owner_email: "",
        owner_contact: "",
        companyName: "",
        manager_contact: "",
        accountant_name: "",
        // form_address: "",
        accountant_email: "",
        accountant_mobile: "",
        purchase_price: "",
        purchase_date: "",
        settlement_date: "",
        manager_name: "",
        status: "",
        company_id:"",
        company_name:"",
        agree: false
    })

    const [emailError, setEmailError] = useState('')
    const [accemailError, setAccEmailError] = useState('')
    const [mobileError, setmobileError] = useState('')
    const [ownermobileError, setownermobileError] = useState('')
    const [accmobileError, setaccmobileError] = useState('')
    const [InputBox, setmyInputBox] = React.useState()
    // 

    async function mydetail1() {
    }

    async function mydetail2() {
    }

    async function setInputBox(status) {
        if (status == 0) {
            setmyInputBox();
        } else {

        }

    }

   
    async function validate(evt) {

        var theEvent = evt || window.event;

        if (theEvent.type === 'paste') {
            key = window.event.clipboardData.getData('text/plain');
        } else {
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    async function getToken() {
        if (cookies.get("tokens") == "" || cookies.get("tokens") == undefined || cookies.get("tokens") == null) {
            localStorage.setItem("tokens1", '');
            if (localStorage.getItem('tokens1') == "" || localStorage.getItem('tokens1') == undefined || localStorage.getItem('tokens1') == null) {
                var data = qs.stringify({
                    'client_id': 'client_4f363bc816d8d1a65e65e6cdbf032256',
                    'client_secret': 'secret_9c5900c75a8fb3bb5764280ed74a5120',
                    'scope': 'api_agencies_read api_listings_read api_properties_read',
                    'grant_type': 'client_credentials'
                });
                var config = {
                    method: 'post',
                    url: 'https://auth.domain.com.au/v1/connect/token',
                    headers: {
                        'grant_type': '',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: data
                };

                axios(config).then(function (response) {
                    var responces = response.data;
                    setToken(responces.access_token);
                    cookies.set('tokens', responces.access_token, { path: '/', maxAge: 43200 });

                    localStorage.setItem("tokens1", responces.access_token);
                }).catch(function (error) {
                    console.log(error);
                });
            }
        }
    }


    async function getProperties(address, component) {

        const report = await axios.post(

            process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
            {
                action: "getReport",
                address: address
            }
        );
        if (report.data.length != 0) {
            setreportDetail('existing');
            setSearchReport(report.data);
        } else {
            setreportDetail('new');
        }


        var token = cookies.get("tokens");
        var getaddress = searchProperty.address != '' ? searchProperty.address : ''
        var myAddress = ""
        if (component[0].long_name != undefined) {
            for (var i = 0; i < component.length; i++) {
                myAddress = myAddress + ' ' + component[i].long_name
            }
        } else {
            myAddress = searchProperty.address
        }

        var config = {
            method: 'get',
            url: 'https://api.domain.com.au/v1/properties/_suggest?terms=' + myAddress,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        };

        axios(config)
            .then(function (response) {

                if (response.data.length != 0) {
                    setAllProperties(response.data)

                    setownproperties(response.data)

                    var tmpval = 0;

                    var street = response.data[0].addressComponents.streetName + " " + response.data[0].addressComponents.streetTypeLong;
                    component.map((item) => (

                        item.types[0] == "street_number" ? response.data[0].addressComponents.streetNumber == item.long_name ? tmpval++ : console.log("no match") : null,

                        item.types[0] == "route" ? street == item.long_name ? tmpval++ : console.log("no match") : '',

                        item.types[0] == "locality" ? response.data[0].addressComponents.suburb == item.short_name ? tmpval++ : console.log("no match") : '',

                        item.types[0] == "administrative_area_level_1" ? response.data[0].addressComponents.state == item.short_name ? tmpval++ : console.log("no match") : '',

                        item.types[0] == "postal_code" ? response.data[0].addressComponents.postCode == item.long_name ? tmpval++ : console.log("no match") : ''

                    ));
                    if (tmpval == 5) {
                        setAllProperties(response.data)
                        setownproperties(response.data)
                    } else {
                        setAllProperties({ tmp: 'empty' })
                        setFound("new");
                    }


                } else {
                    setAllProperties({ tmp: 'empty' })
                    setFound("new");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    // const history = useHistory();
    const [properties, setProperties] = React.useState([]);

    async function getImage() {
        var token = cookies.get("tokens");
        if (allProperties[0].id != undefined || allProperties[0].id != null || allProperties[0].id != '') {
            var config = {
                method: 'get',
                url: 'https://api.domain.com.au/v1/properties/' + allProperties[0].id,
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            };

            axios(config)
                .then(function (response) {
                    setProperties(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
            tmp = 0;
        }
    }

    const history = useHistory();

    // console.log("properties");
    // console.log(properties);
    const onClickSearch = () => {
        // history.push("/depreciation-report/detail1");
        if (searchProperty.address != undefined) {
            getProperties(searchProperty.address, addressComponent);

        }
        setImages(<GetProperties
            address={searchProperty}
            component={addressComponent}
        />);


        setSearch("none");
        setDetail1("block");



        window.scrollTo(0, 0);

    }

    const notify = (message) => toast(message);

    const validateEmail = (e) => {
        var email = e.target.value

        if (validator.isEmail(email)) {
            setEmailError('')
            setFormData({
                ...formData,
                owner_email: e.target.value
            })
            setbtnCheck(false)
        } else {
            setEmailError('Invalid')
            setbtnCheck(true)
        }
    }

    const validateAccEmail = (e) => {
        var email = e.target.value

        if (validator.isEmail(email)) {
            setAccEmailError('')
            setFormData({
                ...formData,
                accountant_email: e.target.value
            })
            setbtnCheck(false)
        } else {
            setAccEmailError('Invalid')
            setbtnCheck(true)
        }
    }


    useEffect(
        () => {

            setTimeout(() => {
                getToken();
                window.scrollTo(0, 0);
            }, 1000);

            if (all_date.state == undefined) {
            } else {
                if (tmp == 1) {
                    tmp = 0;
                    setSearchProperty({
                        ...searchProperty,
                        address: all_date.state.address
                    })
                    set_component(all_date.state.component, all_date.state.address);
                    setSearch("none");
                    setDetail1("block");
                    setFormData({
                        ...formData,
                        address: all_date.state.address,
                        place_id: all_date.state.place_id,
                        location_latitude: all_date.state.lat,
                        location_longitude: all_date.state.long
                    })
                }
            }


        }, []
    )
    const [errName, seterrName] = useState('')

    async function add_depreciation_report(e) {
        e.preventDefault()
       
        // if (formData.ip_address.trim() === "") {
        //     seterrName("Investment property address is required.")
        //     return false;
        // }
        if (formData.propery_type === "") {
            seterrName("Property type is required .")
            return false;
        }
        // if (formData.rental_start_date === "") {
        //     seterrName("Rental start date is required.")
        //     return false;
        // }
        if (formData.purchase_date === "") {
            seterrName("Purchase date is required.")
            return false;
        }
        if (formData.settlement_date === "") {
            seterrName("Settlement date is required.")
            return false;
        }
        if (formData.purchase_price === "") {
            seterrName("Purchase price is required .")
            return false;
        }
        if (formData.construction_completion_date === "") {
            seterrName("Construction completion date is required.")
            return false;
        }
        // if (formData.construction_cost === "") {
        //     seterrName("Construction cost is required.")
        //     return false;
        // }
        // if (formData.renovations === "") {
        //     seterrName("Renovations is required.")
        //     return false;
        // }
        if (formData.owners_name === "") {
            seterrName("Owner's name is required.")
            return false;
        }
        if (formData.owner_email === "") {
            seterrName("Owner email is required.")
            return false;
        }
        if (formData.owner_contact === "") {
            seterrName("Owner contact is required.")
            return false;
        }
        if (!isValidPhoneNumber(formData.owner_contact)) {
            return false;
        }
        if (formData.companyName === "") {
            seterrName("Company is required.")
            return false;
        }
        if (formData.manager_name === "") {
            seterrName("Property manager name is required.")
            return false;
        }
        if (formData.manager_contact === "") {
            seterrName("Contact is required.")
            return false;
        }
        if (!isValidPhoneNumber(formData.manager_contact)) {
            return false;
        }
        if (formData.agree === false) {
            seterrName("Please accept terms and conditions.")
            return false;
        }
        if (formData.accountant_name === "") {
            seterrName("Name is required.")
            return false;
        }
        // if (formData.company_name.trim() === "" && formData.company_id.trim()=="") {
        //     seterrName("Company field is required.")
        //     return false;
        // }
        // if (formData.accountant_email === "") {
        //     seterrName("Accountant email is required.")
        //     return false;
        // }
        // if (formData.accountant_mobile === "") {
        //     seterrName("Accountant contact is required.")
        //     return false;
        // }
        // if (!isValidPhoneNumber(formData.accountant_mobile)) {
        //     return false;
        // }


        // let data = {
        //     "order_report_type": formData.order_report_type,
        //     "order_type": formData.order_type,
        //     "place_id": formData.place_id,
        //     "address": formData.address,
        //     "location_latitude": formData.location_latitude,
        //     "location_longitude": formData.location_longitude,
        //     "name_for_depreciation": formData.name,
        //     'address_for_depreciation': formData.form_address,
        //     "email_id_for_depreciation": formData.email,
        //     "contact_number_for_depreciation": formData.mobile,
        //     "purchase_price_for_depreciation": formData.purchase_price,
        //     'purchase_date_for_depreciation': formData.purchase_date,
        //     "settlement_date_for_depreciation": formData.settlement_date,
        //     "property_manager_name_for_depreciation": formData.manager_name
        // }

        let data = {
            "order_report_type": formData.order_report_type,
            "order_type": formData.order_type,
            "place_id": formData.place_id,
            "address": formData.address,
            "location_latitude": formData.location_latitude,
            "location_longitude": formData.location_longitude,
            // "address_for_investment_property_for_depreciation": formData.ip_address,
            "property_type_for_depreciation": formData.propery_type,
            "rental_start_date_for_depreciation": formData.rental_start_date,
            "purchase_date_for_depreciation": formData.purchase_date,
            "settlement_date_for_depreciation": formData.settlement_date,
            "purchase_price_for_depreciation": formData.purchase_price,
            "construction_completion_date_for_depreciation": formData.construction_completion_date,
            "construction_costs_for_depreciation": formData.construction_cost,
            "renovations_for_depreciation": formData.renovations,

            "owner_name_for_depreciation": formData.owners_name,
            "owner_other_name_for_depreciation": formData.owner_twos_name,
            "owner_email_for_depreciation": formData.owner_email,
            "owner_contact_number_for_depreciation": formData.owner_contact,

            "company_name_for_depreciation": formData.companyName,
            "property_manager_for_depreciation": formData.manager_name,
            "company_contact_for_depreciation": formData.manager_contact,

            "name_for_depreciation": formData.accountant_name,
            "contact_number_for_depreciation": formData.accountant_mobile,
            "email_id_for_depreciation": formData.accountant_email,
            "company_name_ref":formData.company_name,
            "company_id":formData.company_id

        }

        await axios.post(process.env.REACT_APP_NODE_BACKEND_API_URL + '/report', data, {
            headers: {
                "Authorization": "vw7kddgha5j30fz1r7ev"
            }
        }).then(response => {
            if (response.status === 200) {
                Swal.fire('Successfully Submitted.', "Admin will contact you shortly", "success").then((result) => {
                    if (result.isConfirmed) {
                        history.push('/')
                    }
                })
                setTimeout(() => {
                    history.push('/')
                }, 1000)
            }
        }).catch(err => [
            Swal.fire('', err.response.data.message, "warning")
        ])
    }

    return (
        <div className="d-flex flex-wrap" style={{ minHeight: "100vh" }}>
            <Helmet>
                <title> Fast-track your depreciation reports with Propti</title>
                <meta name="description" content="Discover how much you can claim on tax for the depreciation of your home or commercial property with a Propti depreciation report." />
            </Helmet>
            <Image src={BgPattern1} className="position-absolute w-100" style={{ zIndex: "-1" }} alt="img"></Image>
            <Container>
                <Header />
                {/* <Router>
                <Switch>
                    <Route exact path="/depreciation-report"> */}
                <div className={"text-center set_report_title "} style={{ display: search, position: "relative" }} >
                    <h1 className="app-title" name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">Order Depreciation Report</h1>
                    {/* <div className="px-md-5 set_report_search">
                                <GooglePlaceAutoComplete
                                    value={address}
                                    setSearchProperty = {setSearchProperty} 
                                    searchProperty = {searchProperty}
                                    onClickSearch = {onClickSearch}
                                    placeholder = "Enter the address you need a depreciation report for"
                                />
                            </div> */}
                    <Row >
                        <Col md={12}>
                            <Form.Control as={Autocomplete} types={["address"]} componentRestrictions={{ country: "au" }} ref={inputRef} type='text' placeholder="Enter property address" value={address != "" ? address : ''} className='contact-form-input starta_search'

                                onPlaceSelected={(place) => {

                                    setSearchProperty({
                                        ...searchProperty,
                                        address: place?.formatted_address
                                    })
                                    setFormData({ ...formData, address: place?.formatted_address, place_id: place?.place_id, location_latitude: place?.geometry?.location.lat(), location_longitude: place?.geometry?.location.lng(), })
                                    setAddressComponent(place.address_components);
                                }}
                                onChange={
                                    (e) => {
                                        setSearchProperty({
                                            ...searchProperty,
                                            address: e.target.value
                                        })
                                        setAddressComponent(e.target.value);
                                        setFormData({ ...formData, place_id: '' })
                                    }
                                }
                                required></Form.Control>
                        </Col>

                        <Button className="app-text btn btn-outline-primary  search_button strata_search" style={{ borderRadius: '1.5rem', width: '100%', color: '#fff' }}
                            onClick={
                                (e) => onClickSearch()
                            }
                            disabled={formData.place_id ? false : true}
                        >Search</Button>
                    </Row>
                </div>
                {/* </Route>
                </Switch>
                </Router> */}

                <div className="set_building_title px-3" style={{ display: detail1 }}>
                    <ReactTooltip />
                    {/* <Router>
                        <Switch>
                            <Route exact path="/depreciation-report/detail1"> */}
                    <p className="app-title align_center">Order depreciation report</p>
                    <Row className='mt-5'>
                        <Col md={4}>
                            {images}
                            <div className="text-center">
                                <p className="strata-img-title">{searchProperty.address}</p>
                                <p className="strata-img-date"> Price and estimated time will be sent to you via email once order is confirmed</p>
                            </div>

                        </Col>
                        <Col md={8} className="strata-rep-form">
                            {/* onSubmit={handleSubmit} */}
                            <Form >
                     
                                <h4>Investment property details</h4>
                                <Row>
                                    {/* <Col md={6} className="my-3">
                                        <FormGroup>
                                            <Form.Label className="contact-form-label">Address for investment property*</Form.Label>
                                            <Form.Control type='text' placeholder="Enter address here" className='contact-form-input'
                                                onChange={
                                                    (e) => setFormData({
                                                        ...formData,
                                                        ip_address: e.target.value
                                                    })
                                                }
                                                required></Form.Control>
                                        </FormGroup>
                                    </Col> */}
                                    <Col md={6} className="my-3">
                                        <FormGroup>
                                            <Form.Label className="contact-form-label">Property type*</Form.Label>
                                            <Form.Control type='text' placeholder="Enter your property type here" className='contact-form-input'
                                                onChange={
                                                    (e) => setFormData({
                                                        ...formData,
                                                        propery_type: e.target.value
                                                    })
                                                }
                                                required></Form.Control>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} className="my-3">
                                        <FormGroup>
                                            <Form.Label className="contact-form-label">Rental start date</Form.Label>
                                            <Form.Control type='date' placeholder="Select rental start date" className='contact-form-input'
                                                onChange={
                                                    (e) => setFormData({
                                                        ...formData,
                                                        rental_start_date: e.target.value
                                                    })
                                                }
                                                required></Form.Control>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} className="my-3">
                                        <Form.Label className="contact-form-label">Purchase date*</Form.Label>
                                        <Form.Control type='date' placeholder="Select purchase date" className='contact-form-input'
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    purchase_date: e.target.value
                                                })
                                            }
                                            required></Form.Control>
                                    </Col>
                                    <Col md={6} className="my-3">
                                        <Form.Label className="contact-form-label">Settlement date*</Form.Label>
                                        <Form.Control type='date' placeholder="select settlement date" className='contact-form-input'
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    settlement_date: e.target.value
                                                })
                                            }
                                            required></Form.Control>
                                    </Col>
                                    <Col md={6} className="my-3 fixed_value">
                                        <FormGroup>
                                            <Form.Label className="contact-form-label">Purchase price*</Form.Label>
                                            <Form.Control type='text' value={formData.purchase_price} maxlength="8" placeholder="" className='contact-form-input doller_price'
                                                onChange={
                                                    (e) => setFormData({
                                                        ...formData,
                                                        purchase_price: e.target.value
                                                    })
                                                }
                                                onKeyPress={
                                                    (e) => validate(e)
                                                }
                                                required></Form.Control>
                                            <span class="unit1">$</span>
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} className="my-3">
                                        <FormGroup>
                                            <Form.Label className="contact-form-label">Construction completion date*</Form.Label>
                                            <Form.Control type='date' placeholder="Select construction completion date" className='contact-form-input'
                                                onChange={
                                                    (e) => setFormData({
                                                        ...formData,
                                                        construction_completion_date: e.target.value
                                                    })
                                                }
                                                required></Form.Control>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} className="my-3 fixed_value">
                                        <FormGroup>
                                            <Form.Label className="contact-form-label">Construction costs</Form.Label>
                                            <Form.Control type='text' maxlength="8" placeholder="" className='contact-form-input doller_price'
                                                onChange={
                                                    (e) => setFormData({
                                                        ...formData,
                                                        construction_cost: e.target.value
                                                    })
                                                }
                                                onKeyPress={
                                                    (e) => validate(e)
                                                }
                                                required></Form.Control>
                                            <span class="unit1">$</span>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} className="my-3 fixed_value">
                                        <FormGroup>
                                            <Form.Label className="contact-form-label">Renovations <span><i style={{ cursor: "pointer" }} class="fa fa-info-circle" aria-hidden="true" data-tip="Specify renovations & approximate value ($)"></i></span></Form.Label>
                                            <Form.Control type='text' maxlength="8" placeholder="" className='contact-form-input doller_price'
                                                onChange={
                                                    (e) => setFormData({
                                                        ...formData,
                                                        renovations: e.target.value
                                                    })
                                                }
                                                onKeyPress={
                                                    (e) => validate(e)
                                                }
                                                required></Form.Control>
                                            <span class="unit1">$</span>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <h4>Owners details</h4>
                                <Row>
                                    <Col md={12} className="my-3">
                                        <Form.Label className="contact-form-label">Owner's name* <span><i style={{ cursor: "pointer" }} class="fa fa-info-circle" aria-hidden="true" data-tip="Enter SMSF, Business, Company name if applicable"></i></span></Form.Label>
                                        <Form.Control type='text' placeholder="Enter name here" className='contact-form-input'
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    owners_name: e.target.value
                                                })
                                            }
                                            required></Form.Control>
                                    </Col>
                                    <Col md={12} className="my-3">
                                        <Form.Label className="contact-form-label">Owner two’s name (if applicable)</Form.Label>
                                        <Form.Control type='text' placeholder="Enter name here" className='contact-form-input'
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    owner_twos_name: e.target.value
                                                })
                                            }
                                            required></Form.Control>
                                    </Col>
                                    <Col md={6} className="my-3">
                                        <Form.Label className="contact-form-label">Email*</Form.Label>
                                        <Form.Control type='text' placeholder="Enter your email here" className='contact-form-input'
                                            onChange={
                                                (e) => validateEmail(e)
                                            }

                                            required></Form.Control>
                                        <span className="err">{emailError}</span>
                                    </Col>
                                    <Col md={6} className="my-3">
                                        <Form.Label className="contact-form-label">Contact number*</Form.Label>
                                        <MobileInput value={formData.owner_contact ? formData.owner_contact : ""} onChange={(e) => setFormData({...formData,owner_contact: e})} />
                                        <span className="err">
                                            {
                                                formData.owner_contact !== ""
                                                    ?
                                                    formData.owner_contact && isPossiblePhoneNumber(formData.owner_contact) ? formData.owner_contact && isValidPhoneNumber(formData.owner_contact) ? '' : 'Invalid Phone Number' : 'Impossible Phone Number'
                                                    : ""
                                            }
                                        </span>
                                    </Col>
                                </Row>

                                <h4>Access details <span><i style={{ cursor: "pointer" }} class="fa fa-info-circle" aria-hidden="true" data-tip="We may require an inspection."></i></span></h4>
                                <Row>
                                    <Col md={12} className="my-3">
                                        <Form.Label className="contact-form-label">Company* </Form.Label>
                                        <Form.Control type='text' placeholder="Enter company here" className='contact-form-input'
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    companyName: e.target.value
                                                })
                                            }
                                            required></Form.Control>
                                    </Col>
                                    <Col md={6} className="my-3">
                                        <Form.Label className="contact-form-label">Property manager*</Form.Label>
                                        <Form.Control type='text' placeholder="Enter property manager name here" className='contact-form-input'
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    manager_name: e.target.value
                                                })
                                            }
                                            required></Form.Control>
                                    </Col>
                                    <Col md={6} className="my-3">
                                        <Form.Label className="contact-form-label">Contact*</Form.Label>
                                        <MobileInput value={formData.manager_contact ? formData.manager_contact : ""} onChange={e => setFormData({...formData,manager_contact: e})} />
                                        <span className="err">
                                            {
                                                formData.manager_contact !== ""
                                                    ?
                                                    formData.manager_contact && isPossiblePhoneNumber(formData.manager_contact) ? formData.manager_contact && isValidPhoneNumber(formData.manager_contact) ? '' : 'Invalid Phone Number' : 'Impossible Phone Number'
                                                    : ""
                                            }
                                        </span>
                                    </Col>
                                </Row>
                                <h4>Accountant details</h4>
                                <Row>
                                    <Col md={6} className="my-3">
                                        <Form.Label className="contact-form-label">Name</Form.Label>
                                        <Form.Control type='text' placeholder="Enter accountant name here" className='contact-form-input'
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    accountant_name: e.target.value
                                                })
                                            }
                                            required></Form.Control>
                                    </Col>
                                    <Col md={6} className="my-3">
                                        <Form.Label className="contact-form-label">Email</Form.Label>
                                        <Form.Control type='text' placeholder="Enter your email here" className='contact-form-input'
                                            onChange={
                                                (e) => validateAccEmail(e)
                                            }

                                            required></Form.Control>
                                        <span className="err">{accemailError}</span>
                                    </Col>
                                    <Col md={6} className="my-3">
                                        <Form.Label className="contact-form-label">Contact number</Form.Label>
                                        <MobileInput value={formData.accountant_mobile ? formData.accountant_mobile : ""} onChange={e => setFormData({...formData,accountant_mobile: e})} />
                                        <span className="err">
                                            {
                                                formData.accountant_mobile !== ""
                                                    ?
                                                    formData.accountant_mobile && isPossiblePhoneNumber(formData.accountant_mobile) ? formData.accountant_mobile && isValidPhoneNumber(formData.accountant_mobile) ? '' : 'Invalid Phone Number' : 'Impossible Phone Number'
                                                    : ""
                                            }
                                        </span>
                                    </Col>
                                </Row>

                                <CompanyInput formData={formData} setFormData={setFormData}/>
                                <Form.Check className="agree-checkbox app-text" name='purchage-agree' label='I agree to terms and conditions*' type='checkbox' id='agree'
                                    onChange={
                                        (e) => setFormData({
                                            ...formData,
                                            agree: e.target.checked
                                        })
                                    }
                                ></Form.Check>
                                <Row className="justify-content-center my-4 row">

                                    <Button className="navigation_button app-text" style={{ borderRadius: '1.5rem', width: '100%', color: '#fff' }} onClick={(e) => {
                                        setSearch("block");
                                        setDetail1("none");
                                    }}>Go Back</Button>

                                    <Button className="navigation_button app-text" style={{ borderRadius: '1.5rem', width: '100%', color: '#fff' }}
                                        onClick={
                                            (e) => add_depreciation_report(e)
                                        }
                                    // disabled={btnCheck}
                                    >Order Now</Button>
                                    <p style={{ color: "red", width: "100%", textAlign: "center", marginTop: "20px" }}> {errName} </p>

                                </Row>
                                {/* <p className='text-center' style={{ margin: "20px 0", color: "red" }}>Admin will contact you shortly*</p> */}
                            </Form>
                        </Col>
                    </Row>
                    {/* </Route>
                            
                        </Switch>
                    </Router> */}
                </div>
                <ToastContainer toastClassName="border border-grey" />

            </Container>
            <Footer />
            {/* <div className="w-100 footer-bottom-line mt-5">

            </div> */}
        </div>
    )
}

