import React, { useEffect } from 'react'
import { Col, Container, Image, Row, Form, Button, Accordion, Breadcrumb } from 'react-bootstrap'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'

import BgPattern3 from '../assets/images/bg/pattern/footer_bg_pattern.svg'
import Header from '../components/header'
import Footer from '../components/footer'
import axios from 'axios';
import moment from 'moment';

const BlogThumb = (props) => {
    useEffect(() =>{
        window.location.reload()
    },[])

    const hitory = useHistory();
    return <>
        <div className="my-3">
            <img className="w-100" src={props.image} alt="img" />
            <h5 className="my-2 blog-history-title" style={{ fontFamily: 'poppins', fontWeight: 'bold' }}>{props.title}</h5>
            <div className="d-flex align-items-center justify-content-between">
                <p className="my-1 blog-history-date">{props.date}</p>
                <p
                    className="my-1 blog-history-readnow"
                    style={{ cursor: 'pointer' }}
                    onClick={
                        () => {
                            hitory.push(`/blogdetail/${props.id}`);
                            props.setBlogId(props.id);
                        }
                    }
                >
                    Read Now
                </p>
            </div>
        </div>
    </>
}



function BlogDetail(props) {
    const [blogs, setblogs] = React.useState([]);
    const [loader, set_loader] = React.useState(false)
    var history=useHistory()
    const params = useParams()
    useEffect( async () => {
       
            set_loader(true)
            const blog = await axios.get(process.env.REACT_APP_NODE_BACKEND_API_URL + '/article/getArticleDetail?article=' + params.name, {
                headers: {
                    "Authorization": "vw7kddgha5j30fz1r7ev"
                }
            })
           
            // location.reload();
            setblogs(blog.data.data)
            set_loader(false)
        }, []
    )

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
    };
    const MEDIA_URL = process.env.REACT_APP_NODE_BACKEND_MEDIA_API_URL

    return (
        <>
            {loader === true &&
                <div className='loader-main-div'>
                    <div className='loader-second'>
                        <div class="loader"></div></div>
                </div>}
            <div>

                <Image src={window.location.origin + BgPattern3} className="position-absolute w-100" style={{ marginTop: '100px', zIndex: '-1' }} alt="backgroud"></Image>

                <Container>
                    <Header />
                    <Col md={12} className="brad_cumb pl-0">
                            <Breadcrumb className='pl-0' >
                                <Breadcrumb.Item onClick={()=>history.push('/')}>Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/blog">
                                    Articles
                                </Breadcrumb.Item>
                                <Breadcrumb.Item href={`/blog?cat=${blogs.category?._id}`}>
                                    {blogs.category?.category_name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>{blogs.article_title}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    <div className="text-center set_page_title">
                        <h2 className="login-title" name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">Article</h2>
                    </div>
                    <Row>
             
                       
                        <Col xl={12} md={12}>
                            <div className="blog-detail-block">


                                <div key={blogs._id} >
                                    {loader === false &&
                                        <div className='text-center'>
                                            <img
                                                src={blogs.article_image !== undefined ? MEDIA_URL + blogs.article_image : ''} className="main_card_img1"
                                                style={{
                                                    borderRadius: "20px 20px 0px 0px"
                                                }}
                                                alt={blogs?.alt_tag}
                                            />
                                        </div>}
                                    <h1 className="my-2 single-blog-title">{blogs.article_title}</h1>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="single-blog-date my-0 ">{moment(blogs.createdAt).format("DD-MM-YYYY")}</p>
                                    </div>
                                    <p className="single-blog-content">
                                        <div dangerouslySetInnerHTML={{ __html: blogs.article_description }} />

                                    </p>
                                  
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        </>
    )
}

export default BlogDetail
