import React, { useState, useRef } from 'react';
import { Button, Col, Container, Form, FormGroup, Image, Row } from 'react-bootstrap'
import Footer from '../components/footer'
import Header from '../components/header'
import axios from 'axios'
import validator from 'validator'
import BgPattern1 from '../assets/images/bg/pattern/hero_bg_pattern.svg';
import HeroImage from '../assets/images/svg/Submit.png';
import Autocomplete from 'react-google-autocomplete';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import MobileInput from '../components/common/MobileInput';

export default function StrataInsurance() {
    var minm = 100000000000;
    var maxm = 999999999999;
    var code = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    const history = useHistory();


    const [emailError, setEmailError] = useState('')
    const [btnCheck, setbtnCheck] = React.useState(true);
    const [mobileError, setmobileError] = useState('')



    // async function handleSubmit() {
    //     history.push(
    //         {
    //             pathname: "../success",
    //             state: {
    //                 order: 'Strata', number: code
    //             }
    //         }
    //     );
    // }


    const [formData, setFormData] = React.useState({
        insurance_type: "S",
        name: "",
        email: "",
        mobile: "",
        replied: false,
        cost: "",
        value: "",
        address: "",
        lat: "",
        lng: "",
        strata_number: "",
        insurer: "",
        // tc:""
    })
    const [tc, set_tc] = useState(false)
    var navigate = useHistory()
    async function handleSubmit(e) {
        toast.dismiss()
        e.preventDefault();
        if (formData.name.trim() === "") {
            toast.error("Name is required.")
            return false;
        }
        if (formData.email === "") {
            toast.error('Email is required.')
            return false;
        }
        if (formData.mobile === "") {
            toast.error('Mobile is required.')
            return false;
        }
        if (formData.cost === "") {
            toast.error('Building placement cost required.')
            return false;
        }
        if (formData.cost < 1) {
            toast.error('Building placement cost should be greater than 0.')
            return false;
        }
        if (formData.value === "") {
            toast.error('Common area content value is required.')
            return false;
        }
        if (formData.value < 1) {
            toast.error('Common area content value should be greater than 0.')
            return false;
        }
        if (formData.address === "") {
            toast.error('Adress is required.')
            return false;
        }
        if (formData.strata_number === "") {
            toast.error("Strata number is required.")
            return false;
        }
        if (formData.insurer === "") {
            toast.error('Current insurer field is required.')
            return false;
        }
        if (tc === false) {
            toast.error('Please accept terms and conditions.')
            return false;
        }

        var data = {
            "insurance_type": formData.insurance_type,
            "name": formData.name,
            "email_id": formData.email,
            "phone_number": formData.mobile,
            // "is_replied_or_not": formData.replied,
            "building_replacement_costs": formData.cost,
            "common_area_content_value": formData.value,
            "strata_number": formData.strata_number,
            "address": formData.address,
            "latitude": formData.lat,
            "longitude": formData.lng,
            "current_insurer": formData.insurer
        }

        await axios.post(process.env.REACT_APP_NODE_BACKEND_API_URL + '/insurance', data, {
            headers: {
                "Authorization": "vw7kddgha5j30fz1r7ev"
            }
        }).then((response) => {
            Swal.fire(
                '',
                'Strata insurance request made successfully',
                'success'
            )
            navigate.push('/')
        }).catch((error) => {
            console.log({ error })
        })

        // const res = await axios.post(
        //     process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
        //     {
        //         action: "stratainsurence_send_mail",
        //         address: formData.address,
        //         starta_number: formData.starta_number,
        //         cost: formData.cost,
        //         value: formData.value,
        //         insurer: formData.insurer,
        //         name: formData.name,
        //         email: formData.email,
        //         mobile: formData.mobile,

        //     }
        // )

        // history.push(
        //     {
        //         pathname: "../success",
        //         state: {
        //             order: 'Stata Insurance', number: code, text: 'A quote will be sent to you via email please refer to your reference number'
        //         }
        //     }
        // );
    }

    async function validate(evt) {

        var theEvent = evt || window.event;

        if (theEvent.type === 'paste') {
            key = window.event.clipboardData.getData('text/plain');
        } else {
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }


    const validateEmail = (e) => {
        var email = e.target.value

        if (validator.isEmail(email)) {
            setEmailError('')
            setFormData({
                ...formData,
                email: e.target.value.toLowerCase()
            })

        } else {
            setEmailError('Invalid')
            setbtnCheck(true)
        }
    }
    const validateMobile = (e) => {
        var mobile = e
        if (mobile && validator.isMobilePhone(mobile, 'any')) {
            setmobileError('')
            setFormData({
                ...formData,
                mobile: e.target.value
            })

        } else {
            setmobileError('Invalid')
            setbtnCheck(true)
        }
    }


    return (
        <div>
            <Helmet>
                <title name="keywords" content="comprehensive database of strata reports, building and pest reports, valuations, depreciation reports, and QS reports in one place">Residential strata insurance with Propti</title>
                <meta name="description" content="Get comprehensive strata insurance for body corporate managed properties to protect your shared spaces in an apartment or complex." />
            </Helmet>
            <Image src={BgPattern1} className="position-absolute w-100" style={{ zIndex: "-1" }} alt="img"></Image>
            <Container>
                <Header />
                <div className="text-center set_page_title">
                    <h1 className="app-title my-5" name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">Order strata insurance</h1>
                </div>
                <Row className='insurance-block'>
                    <Col md={4}>
                        <Image src={HeroImage} className="position-asbolute w-100 set_hero_bg" alt="img"></Image>
                        {/* <p className="hero-title my-5 text-center"> A quote will be sent to you via email</p> */}
                        <p className="hero-title my-5 text-center">A broker will be in contact with you via email once submitted</p>
                    </Col>
                    <Col md={8} className="insurance-form">
                        <Form>
                            <FormGroup>
                                <Form.Label className="contact-form-label">Name*</Form.Label>
                                <Form.Control type='text' placeholder="Enter your name here" className='contact-form-input'
                                    onChange={
                                        (e) => setFormData({
                                            ...formData,
                                            name: e.target.value
                                        })
                                    }
                                ></Form.Control>
                            </FormGroup>

                            <Row>
                                <Col lg={6} md={12} className="my-3">
                                    <Form.Label className="contact-form-label">Email*</Form.Label>
                                    <Form.Control type='email' placeholder="Enter your email here" className='contact-form-input'

                                        onChange={
                                            (e) => validateEmail(e)
                                        }
                                    ></Form.Control>
                                    <span className="err">{emailError}</span>
                                </Col>
                                <Col lg={6} md={12} className="my-3">
                                    <Form.Label className="contact-form-label">Mobile number*</Form.Label>
                                    <MobileInput value={formData.mobile} onChange={e => validateMobile(e)} />
                                    <span className="err">{mobileError}</span>
                                </Col>
                                <Col lg={6} md={12} className="my-3 fixed_value">
                                    <FormGroup>
                                        <Form.Label className="contact-form-label">Building replacement costs*</Form.Label>
                                        <Form.Control type='text' maxlength="8" className='contact-form-input'
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    cost: e.target.value
                                                })
                                            }
                                            onKeyPress={
                                                (e) => validate(e)
                                            }
                                        ></Form.Control>
                                        <span class="unit1">$</span>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={12} className="my-3 fixed_value">
                                    <FormGroup>
                                        <Form.Label className="contact-form-label">Common area contents value*</Form.Label>
                                        <Form.Control type='text' className='contact-form-input' maxlength="8"
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    value: e.target.value
                                                })
                                            }
                                            onKeyPress={
                                                (e) => validate(e)
                                            }
                                        ></Form.Control>
                                        <span class="unit1">$</span>
                                    </FormGroup>

                                </Col>
                            </Row>
                            <FormGroup>
                                <Form.Label className="contact-form-label">Address to inspect*</Form.Label>
                                <Form.Control as={Autocomplete} types={["address"]} componentRestrictions={{ country: "au" }} type='text' placeholder="Enter address to inspect" className='contact-form-input'
                                    onPlaceSelected={(place, e) => {
                                        var latitude = place.geometry.location.lat()
                                        var longitude = place.geometry.location.lng()
                                        setFormData({
                                            ...formData,
                                            lat: latitude,
                                            lng: longitude,
                                            address: place.formatted_address
                                        })
                                    }

                                    }
                                    onChange={
                                        (e) => setFormData({
                                            ...formData,
                                            address: e.target.value
                                        })
                                    }
                                ></Form.Control>
                            </FormGroup>
                            <Row>
                                <Col lg={6} md={12} className="my-3">
                                    <Form.Label className="contact-form-label">Strata number*</Form.Label>
                                    <Form.Control type='text' placeholder="Enter strata number " maxlength="13" className='contact-form-input'
                                        onChange={
                                            (e) => setFormData({
                                                ...formData,
                                                strata_number: e.target.value
                                            })
                                        }
                                        onKeyPress={
                                            (e) => validate(e)
                                        }
                                    ></Form.Control>
                                </Col>
                                <Col lg={6} md={12} className="my-3">
                                    <Form.Label className="contact-form-label">Current insurer*</Form.Label>
                                    <Form.Control type='text' placeholder="Enter current insurer*" className='contact-form-input'
                                        onChange={
                                            (e) => setFormData({
                                                ...formData,
                                                insurer: e.target.value
                                            })
                                        }
                                    ></Form.Control>
                                </Col>
                            </Row>
                            <Form.Check className="agree-checkbox app-text" name='purchage-agree' label='I agree to terms and conditions' type='checkbox' id='agree'
                                onChange={
                                    (e) => set_tc(e.target.checked)
                                }
                            ></Form.Check>
                            <Row className="justify-content-center mx-0 px-0 row btnrow">

                                <Button className="navigation_button app-text" style={{ borderRadius: '1.5rem', width: '100%' }}>Go back</Button>

                                <Button type="submit" className="navigation_button app-text" style={{ borderRadius: '1.5rem', width: '100%' }} onClick={(e) => { handleSubmit(e) }}  >Submit for quote</Button>

                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </div>
    )
}
