import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'

import BgPattern1 from '../assets/images/bg/pattern/hero_bg_pattern.svg';
import BgPattern2 from '../assets/images/bg/pattern/ourwork_bg_pattern.svg'
import BgPattern3 from '../assets/images/bg/pattern/footer_bg_pattern.svg';
import IntroImage from '../assets/images/svg/Mask Group.png';
import IntroImagePanel from '../assets/images/svg/aboutus_image_panel.svg';
import Header from '../components/header';
import Footer from '../components/footer';
import Curiours from '../components/aboutus/curious';
import Userinfo from '../components/aboutus/userintro';

import '../assets/css/aboutus/aboutus.css'
import axios from 'axios';
import { Helmet } from 'react-helmet';

function AboutUs() {
    const [content, set_content] = useState({
        about_us: '',
        about_us_image: '',
        step_one_desc: '',
        step_two_desc: '',
        step_three_desc: '',
        for_repoters_desc: '',
        for_real_estate_agents_desc: '',
        for_conveyancer_desc: '',
        for_customers_desc: ''
    });
    const MEDIA_URL = process.env.REACT_APP_NODE_BACKEND_MEDIA_API_URL
    // const MEDIA_URL = "http://198.211.110.165:4001"

    useEffect(async () => {

        const response = await axios.get(process.env.REACT_APP_NODE_BACKEND_API_URL + '/quicklinks/getAboutUs', {
            headers: {
                "Authorization": "vw7kddgha5j30fz1r7ev"
            }
        })
        set_content({
            about_us: response.data.data.about_us,
            about_us_image: response.data.data.about_image,
            step_one_desc: response.data.data.step_one_desc,
            step_two_desc: response.data.data.step_two_desc,
            // step_three_desc: response.data.data.step_three_desc,
            for_repoters_desc: response.data.data.for_repoters_desc,
            for_real_estate_agents_desc: response.data.data.for_real_estate_agents_desc,
            for_conveyancer_desc: response.data.data.for_conveyancer_desc,
            for_customers_desc: response.data.data.for_customers_desc
        })

        window.scrollTo(0, 0);
    }, [])



    return (
        <>
            <Helmet>
                <title name="keywords" content="comprehensive database of strata reports, building and pest reports, valuations, depreciation reports, and QS reports in one place">The easiest and quickest way to get property reports</title>
                <meta name="description" content="Download strata reports, building and pest reports, or up-to-date valuations for your property – all in the click of a button." />
            </Helmet>
            <div className="w-100">
                <Image src={BgPattern1} className="position-absolute w-100" alt="img"></Image>
                <Container>
                    <Header />
                    <div className="d-flex w-100 justify-content-center set_page_title">
                        <h1 className='aboutus-title' name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">About us</h1>
                    </div>
                    <Row className="about-section">
                        <Col xl={6} md={12} className="w-100 about-us-image">

                            <Image src={MEDIA_URL + content.about_us_image} className=" aboutus-image" style={{ left: '0' }} alt="img" />

                        </Col>
                        <Col xl={6} md={12}>
                            <h2 className='aboutus-title'>Prioritising property, not paperwork</h2>
                            <p className='aboutus-text set_about_text' style={{ whiteSpace: "pre-wrap" }}>
                                {content.about_us}
                            </p>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between align-items-top curious_title_sec set_aboutus_root">
                        <div>
                            <p className="home-section-title-1 mb-0">Efficiency in simplicity  </p>
                            {/* <p className='aboutus-title'>How Does it Work?</p> */}
                        </div>
                        <div>
                            <p className="aboutus-text px-xl-5 px-md-0 curious-text">No need to pick up the phone, sign up or create an account. Get the ball rolling and we’ll handle the rest.</p>
                        </div>
                    </div>
                    <Curiours {...content} />
                </Container>
                <Image src={BgPattern3} className="position-absolute w-100" style={{ marginTop: '200px' }} alt="img"></Image>
                <Container>
                    <Row className="carious_card_block set-cario-test">
                        <Col xl={6} md={12} key="For buyers">
                            <Userinfo title="For buyers" content={content.for_customers_desc} link="Order report now" to="./" />
                        </Col>
                        <Col xl={6} md={12} key="For reporters">
                            <Userinfo title="For reporters" content={content.for_repoters_desc} link="Create account now" to="/signup" />
                        </Col>
                        <Col xl={6} md={12} key="For real estate agents">
                            <Userinfo title="For real estate agents" content={content.for_real_estate_agents_desc} link="Create account now" to="/signup" />
                        </Col>
                        <Col xl={6} md={12} key="For conveyancers and solicitors">
                            <Userinfo title="For conveyancers and solicitors" content={content.for_conveyancer_desc} link="Create account now" to="/signup" />
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        </>
    )
}

// const Data = [
//     {
//         title: 'For Reporters',
//         content: 'Receive free leads for joining our panel! We will help you gain more business via our network of agents and conveyancers. No more waiting around for business, simply log onto our not out portal and we will send you business. Receive funds upfront for new reports.'
//     },
//     {
//         title: 'For Real-estate Agents',
//         content: 'As an Agent the time it takes to find and organise a strata report is extensive and unnecessary. We have developed a portal to minimise the time it takes you, whilst optimising your success selling apartments. We will help you know who the serious buyers are and help you find leads for future sales.'
//     },
//     {
//         title: 'For Conveyancers',
//         content: 'A review of strata reports is necessary as you know. Gain access to potential customers by joining our platform. We have access to people buying strata reports who will potentially need help with settlements.'
//     },
//     {
//         title: 'For Customers',
//         content: 'No need to pick up a phone and call around! No need to sign up or create an account! Simply click on the link the real estate agent sends you or freely search the property you need a report for.'
//     },

// ]

export default AboutUs
