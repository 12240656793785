import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Image,
  Row,
} from "react-bootstrap";

// import reasources
import "../../../assets/css/hero.css";
import GooglePlaceAutoComplete from "../../basic/googleAutoComplete";
import Autocomplete from "react-google-autocomplete";
import RightImage from "../../../assets/images/svg/home.png";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import QsModal from "../../QsModal";
import DilapidationModal from "../../Dilapidation";
import PoolInspectionModal from "../../PoolInspection";
import HandOverInspectionModal from "../../Handover-inspection";
import StageInspectionModal from "../../stage-inspection";

function Hero() {
  const all_date = useLocation();
  const inputRef = useRef(null);

  const [place_id, set_place_id] = useState("");
  const [prop_address, set_prop_address] = useState("");
  const [latitude, set_latitude] = useState("");
  const [longitude, set_longitude] = useState("");
  const [addressComponent, setAddressComponent] = React.useState([]);
  const [selectReport, setSelectReport] = React.useState("");
  const [search_btn_style, setsearch_btn_style] = React.useState("none");
  const [next_btn_style, setnext_btn_style] = React.useState("block");
  const [next_btn_active, setnext_btn_active] = React.useState(true);
  const [btnCheck, setbtnCheck] = React.useState(true);
  const [searchProperty, setSearchProperty] = React.useState({
    address: "",
    method: "new",
    type: "home",
  });

  const [show, set_show] = useState(false);
  const [dilapidationShow, setDilapidationShow] = useState(false);
  const [poolInspectionShow, setPoolInspectionShow] = useState(false);
  const [handoverInspectionShow, setHandoverInspectionShow] = useState(false);
  const [stageInspectionShow, setStageInspectionShow] = useState(false);

  const history = useHistory();

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  async function enableBtn(e) {
    if (e.target.value != "") {
      setSelectReport(e.target.value);
      setbtnCheck(false);
    } else {
      setbtnCheck(true);
    }
  }

  const onClickSearch = () => {
    // if(modal==true){
    //     setModal(false);
    // }else{
    //     setModal(true);
    // }
    if (selectReport != "") {
      handleSendReply(selectReport);
    }
  };

  async function handleSendReply(report) {
    if (report == "strata") {
      history.push({
        pathname: "/strata-report",
        state: {
          address: searchProperty.address,
          place_id: place_id,
          prop_address: prop_address,
          lat: latitude,
          long: longitude,
          component: addressComponent,
        },
      });
    } else if (report == "valuation") {
      history.push({
        pathname: "/valuation",
        state: {
          address: searchProperty.address,
          component: addressComponent,
          detail1: "detail1",
          place_id: place_id,
          lat: latitude,
          long: longitude,
        },
      });
    } else if (report == "build") {
      history.push({
        pathname: "/inspection",
        state: {
          address: searchProperty.address,
          component: addressComponent,
          place_id: place_id,
          lat: latitude,
          long: longitude,
        },
      });
    } else if (report == "depreciation") {
      history.push({
        pathname: "/depreciation-report",
        state: {
          address: searchProperty.address,
          component: addressComponent,
          place_id: place_id,
          lat: latitude,
          long: longitude,
        },
      });
    } else if (report == "qs") {
      set_show(true);
    } else if (report == "dilapidation") {
      setDilapidationShow(true);
    } else if (report == "pool") {
      setPoolInspectionShow(true);
    } else if (report == "handover") {
      setHandoverInspectionShow(true);
    } else if (report == "stage") {
      setStageInspectionShow(true);
    }
  }

  async function next_process() {
    setnext_btn_style("none");
    setsearch_btn_style("block");
  }

  async function valueInputed(e) {
    console.log("work");
    if (e.target.value != "") {
      setnext_btn_active(false);
      setSearchProperty({
        ...searchProperty,
        address: e.target.value,
      });
      setAddressComponent(e.target.value);
    } else {
      setnext_btn_active(true);
    }
  }
  const data = {
    place_id,
    prop_address,
    latitude,
    longitude,
  };

  useEffect(() => {}, [show]);

  return (
    <div className="home_search">
      <div className="hero-root position-relative">
        <Image
          src={RightImage}
          className="position-absolute  hero-image"
          alt="property reports australia"
        ></Image>
        <div className="set_herotext_block">
          <div className="hero-head-title-1">
            <h1>
            The home of <br></br>property reports
            </h1>
          </div>
          {/* <div><span className="hero-head-title-2">Delivered Faster!</span></div> */}
          <div className="hero-title set_herotext">
            <p
              className="h1_p"
              name="keywords"
              content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more."
            >
              Propti is property reporting reimagined. It’s the fastest{" "}
              <br></br>and easiest way to get strata reports, building and pest{" "}
              <br></br>reports, valuations, depreciation reports, QS reports,
              and more.
            </p>
          </div>
        </div>
        <p className="show_address" style={{ display: search_btn_style }}>
          {searchProperty.address}
        </p>
        <div
          className="px-1 hero-place-autocomplete"
          style={{ position: "relative" }}
        >
          {/* <GooglePlaceAutoComplete
                    setSearchProperty = {setSearchProperty} 
                    searchProperty = {searchProperty}
                    onClickSearch = {onClickSearch}
                    placeholder = "Enter the address you need a report for"
                    components = {setAddressComponent}
                /> */}

          <span className="box-shaddow">
            <Form.Control
              as={Autocomplete}
              types={["address"]}
              componentRestrictions={{ country: "au" }}
              ref={inputRef}
              type="text"
              placeholder="Find your property"
              className="contact-form-input starta_home_search"
              onPlaceSelected={(place) => {
                setSearchProperty({
                  ...searchProperty,
                  address: place.formatted_address,
                });
                set_place_id(place.place_id);
                set_prop_address(place.formatted_address);
                var lat = place.geometry.location.lat();
                set_latitude(lat);
                var long = place.geometry.location.lng();
                set_longitude(long);
                set_prop_address(place.formatted_address);
                setAddressComponent(place.address_components);
              }}
              onChange={(e) => {
                valueInputed(e);
              }}
              required
            ></Form.Control>
          </span>
          <Button
            className="app-text btn btn-outline-primary  search_home_button"
            style={{
              borderRadius: "1.5rem",
              width: "100%",
              color: "#fff",
              display: next_btn_style,
            }}
            onClick={(e) => next_process()}
            disabled={next_btn_active || !place_id ? true : false}
          >
            Go!
          </Button>
          <Button
            className="app-text btn btn-outline-primary  search_home_button"
            style={{
              borderRadius: "1.5rem",
              width: "100%",
              color: "#fff",
              display: search_btn_style,
            }}
            onClick={(e) => onClickSearch()}
            disabled={btnCheck}
          >
            Search
          </Button>
          <select
            name=""
            id="select_strata_report"
            onChange={(e) => {
              enableBtn(e);
            }}
            style={{ display: search_btn_style }}
          >
            <option value="" class="hide_text">
              Select report
            </option>
            <option value="strata">Strata report</option>
            <option value="build">Building & Pest inspection</option>
            <option value="valuation">Valuation report</option>
            <option value="depreciation">Depreciation report</option>
            <option value="qs">Qs report</option>
            <option value="dilapidation">Dilapidation report</option>
            <option value="pool">Pool inspection & certificate</option>
            <option value="handover">Handover inspection</option>
            <option value="stage">Stage inspection</option>
          </select>
        </div>
        {modal && (
          <div className="select_button">
            <h5>Select Report Type : </h5>
            <Button onClick={() => handleSendReply("strata")}>
              Strata Report
            </Button>
            <Button onClick={() => handleSendReply("build")}>
              Building & Pest Inspection
            </Button>
            <Button onClick={() => handleSendReply("valuation")}>
              Valuation Report
            </Button>
            <Button onClick={() => handleSendReply("depreciation")}>
              Depreciation Report
            </Button>
          </div>
        )}
      </div>
      {show !== false && (
        <QsModal show={show} address={data} onHide={() => set_show(false)} />
      )}
      <DilapidationModal
        show={dilapidationShow}
        address={data}
        onHide={() => setDilapidationShow(false)}
      />
      <PoolInspectionModal
        show={poolInspectionShow}
        address={data}
        onHide={() => setPoolInspectionShow(false)}
      />
      <HandOverInspectionModal
        show={handoverInspectionShow}
        address={data}
        onHide={() => setHandoverInspectionShow(false)}
      />
      <StageInspectionModal
        show={stageInspectionShow}
        address={data}
        onHide={() => setStageInspectionShow(false)}
      />
    </div>
  );
}

export default Hero;
