import React, { useEffect, useRef } from 'react';
import { Container, Image } from 'react-bootstrap';

// import reasources
import Header from '../components/header';
import Hero from '../components/home/hero';
import BgPattern1 from '../assets/images/bg/pattern/hero_bg_pattern.svg';
import BgPattern2 from '../assets/images/bg/pattern/ourwork_bg_pattern.svg'
import BgPattern3 from '../assets/images/bg/pattern/footer_bg_pattern.svg';
import Brnad from '../components/home/brand';
import AboutUs from '../components/home/aboutus';
import Ourwork from '../components/home/ourwork';
import Partners from '../components/home/partners';
import TestMonial from '../components/home/testmonial';
import Contact from '../components/home/contact';
import Footer from '../components/footer';
import { useHistory } from 'react-router-dom';

function Home() {
    let history = useHistory()
    const contactDiv = useRef("")

    useEffect(() => {
        window.history.scrollRestoration = 'manual'
        // alert(history.location.hash)
        // if (history.location.hash === "#contact") {
        //     setTimeout(() => {

        //     }, 500)

        // }
    }, [])
    // var ele= document.getElementById('contact_us')
    // console.log(ele);
    // if(ele){
    //     ele.scrollIntoView({
    //         behavior:"smooth",
    //         block:"center"
    //     })
    // }
    return (
        <div className="w-100" style={{ position: "relative" }}>
            <Image src={BgPattern1} className="position-absolute w-100 set_hero_bg" alt="img"></Image>
            <Container>
                <Header />
                <Hero />
                <Brnad />
                <AboutUs />
            </Container>
            <Image src={BgPattern2} className="position-absolute w-100" style={{ marginTop: '400px' }} alt="img"></Image>
            <Container>
                <Ourwork />
                <Partners />
            </Container>
            <div style={{ backgroundColor: '#E8F3FF' }}>
                <Container>
                    <TestMonial />
                </Container>
            </div>
            <Image src={BgPattern3} className="position-absolute w-100" style={{ marginTop: '300px' }} alt="img"></Image>
            <Container>
                <div id="contact_part">
                    <Contact />
                </div>
            </Container>

            <Footer />
        </div>
    )


}

export default Home;
