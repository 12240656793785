import React from 'react'
import { Col, Row } from 'react-bootstrap'

function Curious(props) {
    return (
        <Row className="curious_sec">
            <Col md={6}>
                <div className="curious_set">
                    <div className="text-center d-flex justify-content-center">
                        <div className="p-3 aboutus-curious-no">
                            <p className="aboutus-curious-no-text">1</p>
                        </div>
                    </div>
                    <div className="set_curious_block">
                        <p className="aboutus-text text-center">
                            {props.step_one_desc}
                        </p>
                    </div>
                </div>
            </Col>
            <Col md={6}>
                <div className="curious_set">
                    <div className="text-center d-flex justify-content-center">
                        <div className="p-3 aboutus-curious-no">
                            <p className="aboutus-curious-no-text">2</p>
                        </div>
                    </div>
                    <div className="set_curious_block">
                        <p className="aboutus-text text-center abouts-curious">
                            {props.step_two_desc}
                        </p>
                    </div>
                </div>
            </Col>
            {/* <Col md={4}>
                <div className="curious_set">
                    <div className="text-center d-flex justify-content-center">
                        <div className="p-3 aboutus-curious-no">
                            <p className="aboutus-curious-no-text">3</p>
                        </div>
                    </div>
                    <div className="set_curious_block">
                        <p className="aboutus-text text-center">
                            {props.step_three_desc}
                        </p>
                    </div>
                </div>
            </Col> */}
        </Row>
    )
}

export default Curious
