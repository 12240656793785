import React, { Profiler, useEffect, useState } from 'react';
import { Button, Card, Carousel, Form, Image, Modal } from 'react-bootstrap';
import Slider from "react-slick";
import { BsPlusCircle } from 'react-icons/bs'
import ReactStars from "react-rating-stars-component";
import { Rating } from "react-simple-star-rating";
// import resources
import '../../../assets/css/testmonial.css';
import Avatar1 from '../../../assets/images/avatars/user_dummy.png';
import Avatar2 from '../../../assets/images/avatars/avatar2.svg';
import Avatar3 from '../../../assets/images/avatars/avatar3.svg';
import QuateIcon from '../../../assets/images/icons/quate.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function TestMonial() {

    const [show, setShow] = useState(false);
    const [name, set_name] = useState('');
    const [rating, set_rating] = useState(0)
    const [Profile, set_profile] = useState('')
    const [image, set_image] = useState('')
    const [rate, set_rate] = useState(0)
    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true); set_profile(''); set_image(''); set_rate(''); set_rating('') }
    const [loader, set_loader] = useState(false)
    const [content, set_content] = useState('')
    var query = new URLSearchParams(window.location.search)
    const [Data, set_data] = useState([])
    const [reviewData, setReviewData] = useState([])
    const [starNumbers, setStarNumbers] = useState(["ONE","TWO", "THREE", "FOUR", "FIVE"])

    useEffect(
        async () => {
            // alert(query.get('submit'))
            if (query.get('fromEmail') === 'true') {
                setShow(true)
            }
            const res = await axios.get(
                process.env.REACT_APP_NODE_BACKEND_API_URL + "/homepage/getHomePageData", {
                headers: {
                    "Authorization": "vw7kddgha5j30fz1r7ev"
                }
            }
            )

            const res1 = await axios.get(
                process.env.REACT_APP_NODE_BACKEND_API_URL + "/homepage/getReviewers", {

            }
            )
            setReviewData(res1.data.data)
            console.log("res1", res1.data.data)
            set_data(res.data.testimonial)
        }, [])



    const Media_url = process.env.REACT_APP_NODE_BACKEND_MEDIA_API_URL
    const [slide, setSlide] = React.useState()

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: Data.length === 1 ? 1 : 2,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
        ]
    };

    function add_testimonial() {
        // alert(rate)
        //     alert(typeof (rate))
        toast.dismiss()
        if (Profile === "") {
            toast.error('Select image to upload.')
            return false;
        }
        if (name == "") {
            toast.error("Please enter name.")
            return false;
        }

        if (content === "") {
            toast.error('Please enter the description.')
            return false;
        }
        if (rate <= 0) {
            // alert('')
            toast.error('Please select rating.')
            return false;
        }
        else {

            const data = new FormData()
            data.append('user_image', Profile)
            data.append('user_type', "Client")
            data.append('name', name)
            data.append('testimonial_rating', rate)
            data.append('testimonial_desc', content)
            data.append('addedBy', "Web")
            set_loader(true)
            return axios.post(process.env.REACT_APP_NODE_BACKEND_API_URL + '/testimonial/', data, {
                headers: {
                    "Authorization": "vw7kddgha5j30fz1r7ev"
                }
            }).then(response => {
                if (response.status === 200) {
                    setShow(false)
                    set_loader(false)
                    Swal.fire('', "Your rating request submitted successfully.", 'success')

                }
            }).catch(err => {
                setShow(false)
                set_loader(false)
                Swal.fire('', err.response.data.message, 'warning')
            })
        }
    }
    function handleRating(e) {
        // alert(e)
        set_rating(e)
        set_rate(e / 20)
    }
    function handle_profile(e) {
        if (e.target.files != '') {
            if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                set_profile(e.target.files[0])
                set_image(URL.createObjectURL(e.target.files[0]))
            }
            else {
                Swal.fire('', 'Please select image of format Jpeg or png.')
            }
        }
    }
    return (
        <>


            <div className='testmonial-root'>
                <div className='home-section-title-1 mt-5 mt-md-3'>Propti testimonials
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <p className='home-section-title mb-3 pb-3'>Don’t just take our word for it.<br /> See what our customers have to say.
                    </p>
                    <div className="prev-next-button-section" style={{ zIndex: 1025 }}>
                        <div className="d-flex justify-content-end align-items-center">
                            {/* <div className='text-center' onClick={handleShow} style={{ marginRight: "60px", cursor: "pointer" }}>
                            <Button variant="primary"  className='mt-2 partner-card-link'>Submit Testimonial</Button>
                                
                            </div> */}

                            <div className="d-flex m-2" onClick={() => (slide.slickPrev())}>
                                <div className='nextprev-button m-auto'>
                                    <div className="prev-icon">
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex m-2" onClick={() => (slide.slickNext())}>
                                <div className='nextprev-button m-auto'>
                                    <div className="next-icon">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="testmonial-desktop-carosel">
                    <Slider ref={slider => (setSlide(slider))} {...settings} className="mb-5"

                    >
                        {
                            reviewData.map(
                                (item, index) =>
                                    <div key={item.index}>
                                        <Card style={{ height: 330 }} className="mx-3  testmonial-card set_testimonial_card testimonial-box">
                                            <Card.Body className='testimonial-content'>
                                                <div className='testimonial-top'>
                                                    <div>
                                                        <img onError={(event) => event.target.src = item.reviewer.profilePhotoUrl}
                                                            src={item.reviewer.profilePhotoUrl}
                                                            className="testmonial-quate"
                                                            alt="img" /></div>
                                                    <div className="">
                                                        <p className="testmonial-text testimonial-pro-name my-0" >{item.reviewer.displayName}</p>

                                                        <div className="mt-1 l-2 reactstar">
                                                            <span className='star-time'> <ReactStars classNames="rating-area" size={24} value={starNumbers.indexOf(item.starRating)+1} edit={false} />   <p className="testmonial-text testimonial-pro-type my-0 author-desc" >  ({moment(item.createTime).format("DD MMMM yyyy")})</p></span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <Card.Text className="testmonial-text set_testmonial_text">
                                                    {item.comment ? item.comment : "No Comment!"}
                                                </Card.Text>
                                                <div className='d-flex align-items-center'>
                                                    {/* <div>
                                                        <Image src={item.user_image !== '' ? Media_url + item.user_image : Avatar1} className="testmonial-profile"></Image>
                                                    </div> */}

                                                </div>

                                            </Card.Body>
                                        </Card>
                                    </div>

                            )
                        }
                    </Slider>
                </div>
                {/* <div className="testmonial-mobile-carosel">
                <Carousel>
                {
                        Data.map(
                            (item)=>(
                                <Carousel.Item key={item.id}>
                                    <Card className="p-1 mx-1 testmonial-card">
                                        <Card.Body>
                                            <img src={QuateIcon}></img>
                                            <Card.Text className="mt-4 testmonial-text set_testmonial_text">
                                                {item.content}
                                            </Card.Text> 
                                            <div className='d-flex align-items-center'>
                                                <div>
                                                    <img src={item.user_image!==''?Media_url+item.user_image:Avatar1} className="testmonial-profile"></img>
                                                </div>
                                                <div className="ml-2">
                                                    <p className="testmonial-text testimonial-pro-name">{item.name}</p>
                                                    <p className="testmonial-text testimonial-pro-type">{item.user_type}</p>
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <ReactStars size={30} value={item.testimonial_rating} edit={true} onChange={(e)=>alert(e)}/>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Carousel.Item>
                            )
                        )
                    }
                </Carousel>
            </div> */}

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Testimonial</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <div className='add-img-box'>
                            <img src={image == '' ? require('../../../assets/images/download.png').default : image} alt="img"></img>
                            <input type='file' onChange={handle_profile} onClick={(e) => e.target.value = ''} className='upload-img-box'></input>
                        </div> */}
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" maxLength={15} onChange={(e) => set_name(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" maxLength={150} rows={3} onChange={(e) => set_content(e.target.value)} />
                            </Form.Group>
                        </Form>

                        <Rating onClick={(e) => handleRating(e)} ratingValue={rating} readonly={false} />
                    </Modal.Body>
                    <Modal.Footer>

                        <Button style={{ borderRadius: "30px" }} variant="primary" disabled={loader} onClick={add_testimonial}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}


// const Data = [
//     {
//         id:'testmonial_1',
//         content:'I am very fortunate to trust my company to allies and now my company income isincreasing.',
//         name:'Jenny Wilson',
//         user_type:'Client',
//         star:4,
//         avatar: Avatar1
//     },
//     {
//         id:'testmonial_2',
//         content:'I am very fortunate to trust my company to allies and now my company income isincreasing.',
//         name:'Jenny Wilson',
//         user_type:'Agent',
//         star:5,
//         avatar: Avatar3
//     },
//     {
//         id:'testmonial_3',
//         content:'I am very fortunate to trust my company to allies and now my company income isincreasing.',
//         name:'Jenny Wilson',
//         user_type:'Agent',
//         star:3,
//         avatar: Avatar2
//     },
//     {
//         id:'testmonial_4',
//         content:'I am very fortunate to trust my company to allies and now my company income isincreasing.',
//         name:'Jenny Wilson',
//         user_type:'Client',
//         star:2,
//         avatar: Avatar1
//     },
//     {
//         id:'testmonial_5',
//         content:'I am very fortunate to trust my company to allies and now my company income isincreasing.',
//         name:'Jenny Wilson',
//         user_type:'Agent',
//         star:4,
//         avatar: Avatar2
//     },
//     {
//         id:'testmonial_6',
//         content:'I am very fortunate to trust my company to allies and now my company income isincreasing.',
//         name:'Jenny Wilson',
//         user_type:'reporter',
//         star:5,
//         avatar: Avatar3
//     },
// ]

export default TestMonial;
