import React, { useEffect, useState } from 'react'
import { Container, Image } from 'react-bootstrap'

import '../assets/css/faqs.css'
import Header from '../components/header'
import Footer from '../components/footer'
import BgPattern3 from '../assets/images/bg/pattern/footer_bg_pattern.svg'
import FaqsItem from '../components/faqs/faqsitem'
import axios from 'axios'
import { Accordion, Card, Button, } from 'react-bootstrap'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Helmet } from 'react-helmet'


function Faqs() {
    const [initial_faq, set_initial_faq] = useState([])
    const [faqs, setFaqs] = React.useState([]);
    const [categories, set_categories] = React.useState([])
    const [active, set_active] = useState(false);
    /**
     * Call Faqs API when page is loaded
     */
    useEffect(async () => {
        window.scrollTo(0, 0);
        get_faq_categories()

        const res = await axios.get(process.env.REACT_APP_NODE_BACKEND_API_URL + '/quicklinks/getFAQ?faq_category=&search=&size=&pageNo=', {
            headers: {
                "Authorization": "vw7kddgha5j30fz1r7ev"
            }
        });
        setFaqs(res.data.data);
        set_initial_faq(res.data.data)
        console.log(res.data.data);
    }, [])


    async function get_faq_categories() {
        const category = await axios.get(process.env.REACT_APP_NODE_BACKEND_API_URL + '/quicklinks/getFAQCategories', {
            headers: {
                "Authorization": "vw7kddgha5j30fz1r7ev"
            }
        })
        console.log(category.data)
        set_categories(category.data.data)
    }
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }


    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
    }));
    async function get_faq_by_cat(e, id) {
        const res = await axios.get(process.env.REACT_APP_NODE_BACKEND_API_URL + `/quicklinks/getFAQ?faq_category=${id}&search=&size=&pageNo=`, {
            headers: {
                "Authorization": "vw7kddgha5j30fz1r7ev"
            }

        });
        setFaqs(res.data.data);
    }

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function FaqAccCategory(e, id) {

        let faq_list = initial_faq.filter((itm) => itm.faq_category._id === id)
        setFaqs(faq_list)
        console.log(faq_list)
    }
    // console.log(categories)
    console.log(faqs)
    return (

        <>
            <Helmet>
                <title name="keywords" content="comprehensive database of strata reports, building and pest reports, valuations, depreciation reports, and QS reports in one place">Discover the simplest way to get property reports</title>
                <meta name='description' content='From statra reports and up-to-date property valuations to building and pest reports, find answers to all your property reporting questions in the click of a button.'></meta>
            </Helmet>
            <div>
                <Image src={BgPattern3} className="position-absolute w-100" style={{ paddingTop: '100px', zIndex: '-1' }} alt="img"></Image>
                <Container>
                    <Header></Header>

                    <div className="text-center set_page_title">
                        <h1 className="login-title faq-title mb-0" name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">FAQs</h1>
                    </div>

                    <div className={classes.root + " fags-tabs commonheight"}>


                        <AppBar position="static" color="default">

                            <Tabs

                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"

                                aria-label="scrollable auto tabs example"
                            >
                                <Tab onClick={(e) => { setFaqs(initial_faq) }} label="All" {...a11yProps(0)} />
                                {categories.length > 0 && categories.map((item, index) => {
                                    // console.log(item.category_name);
                                    return (<Tab onClick={(e) => { FaqAccCategory(e, item._id) }} label={item.category_name} {...a11yProps(index)} />)
                                }
                                )}

                            </Tabs>

                        </AppBar>
                        {faqs.length !== 0 ?
                            <div className="p-md-5">
                                {
                                    faqs.map(
                                        (item, i) =>
                                            // <>
                                            //     <div>
                                            //         <Accordion className="my-2">
                                            //             <Card className="border-0 bg-white faqs-item-card">
                                            //                 <Card.Header className="faqs-item-card-header bg-white " onClick={() => set_active(!active)}>
                                            //                     <Accordion.Toggle as={"div"} variant="link" eventKey="0" className="w-100" style={{ cursor: "pointer" }}>
                                            //                         <div className="d-flex justify-content-between align-items-center py-2 plus_minus">
                                            //                             <p className="faqs-text my-0 bold_qstn">{item.faq_question}</p>
                                            //                             {active === false && <p className="faqs-plus-icon my-0 text-primary">+</p>}
                                            //                             {active === true && <p className="faqs-plus-icon my-0 text-primary">-</p>}
                                            //                         </div>
                                            //                     </Accordion.Toggle>
                                            //                 </Card.Header>
                                            //                 <Accordion.Collapse eventKey="0">
                                            //                     <Card.Body>
                                            //                         <div className="mx-md-5">
                                            //                             <p className="faqs-text my-0">{item.faq_answer}</p>
                                            //                         </div>
                                            //                     </Card.Body>
                                            //                 </Accordion.Collapse>
                                            //             </Card>
                                            //         </Accordion>


                                            //     </div>
                                            // </>
                                            <FaqsItem key={i} title={item.faq_question} content={item.faq_answer} />

                                    )
                                }
                            </div>
                            :
                            <p className='faq-para'>No data found</p>}


                    </div>
                </Container>
                <Footer />
            </div>
        </>







    )
}
export default Faqs
