import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Container, Form, FormGroup, Image, Row } from 'react-bootstrap'
import Footer from '../components/footer'
import Header from '../components/header'
import Geocode from "react-geocode";
import SETTINGS from '../config';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import axios from "axios";

import BgPattern1 from '../assets/images/bg/pattern/hero_bg_pattern.svg';
import GooglePlaceAutoComplete from '../components/basic/googleAutoComplete';
import OrderPropertyCard from '../components/order/orderpropertycard';
import { getStrataReport } from '../API/order.api';
import CardIamge1 from '../assets/images/svg/notfound.png';
import Cookies from 'universal-cookie';
import Autocomplete from 'react-google-autocomplete';
import validator from 'validator'
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';

var qs = require('qs');

function Order(props) {
    const inputRef = useRef(null);
    const cookies = new Cookies();
    const all_date = useLocation();
    const [address, setupcomingAddress] = React.useState(null);
    const [allProperties, setAllProperties] = React.useState([])

    const [getaddress, setAddress] = React.useState('');
    const [reportDetail, setreportDetail] = React.useState('');
    const [found, setFound] = React.useState('');
    const [searchReport, setSearchReport] = React.useState([]);
    const [emailError, setEmailError] = useState('')
    const [searchProperty, setSearchProperty] = React.useState({
        address: address,
        method: "",
        type: ""
    })
    const [addressComponent, setAddressComponent] = React.useState([]);

    const [searchData, setsearchData] = React.useState([]);
    const [token, setToken] = React.useState("");

    const [dummy, set_dummy] = useState(1)
    const history = useHistory();

    var minm = 100000000000;
    var maxm = 999999999999;
    var code = Math.floor(Math.random() * (maxm - minm + 1)) + minm;


    const [report_list, set_report_list] = useState([])
    const [report_type, set_report_type] = useState('')
    const [place_id, set_place_id] = useState('')
    const [prop_address, set_prop_address] = useState('')
    const [latitude, set_latitude] = useState('')
    const [longitude, set_longitude] = useState('')
    const [address_prop_id, set_address_prop_id] = useState('')
    const [loader, set_loader] = useState(false)

    async function get_existing_reports(address, id) {

        set_loader(true)
        var token = cookies.get("tokens");
        set_dummy(!dummy)
        set_report_type(undefined)
        // var address=prop_address!==''?prop_address :all_date.state.prop_address
        // var placeId=place_id!==''?place_id :all_date.state.place_id
        await axios.get('https://api.domain.com.au/v1/properties/_suggest?terms=' + address, { headers: { "Authorization": 'Bearer ' + token } }).then(response =>{console.log(response.data); set_address_prop_id(response.data[0].id)}).catch((err) => {
            set_loader(false)
            // Swal.fire('', 'Location not found please select from field options', 'warning')
        })
        await axios.get(process.env.REACT_APP_NODE_BACKEND_API_URL + '/report/?order_report_type=' + "S" + "&place_id=" + id, {
            headers: {
                "Authorization": 'vw7kddgha5j30fz1r7ev'
            }
        }).then(response => {
            if (response.status === 200) {
                set_loader(false)
                // console.log('jjjjjjjjjjjjjjjjjjjjjjjj',response.data.data);
                if (response.data.data.length === 0) {

                    set_report_type("New")
                }
                else {
                    set_report_list(response.data.data)
                    set_report_type("Old")
                }
            }
        }).catch((err) => {
            set_loader(false)
            console.log(err);
            Swal.fire('', err.err.message, "warning")
        })
    }



    useEffect(
        () => {

            if (all_date.state !== undefined) {
                set_place_id(all_date.state.place_id)
                set_prop_address(all_date.state.prop_address)
                set_latitude(all_date.state.lat)
                set_longitude(all_date.state.long)
            }

            if (prop_address === '' && place_id === '' || report_type) {

                if (all_date.state != undefined) {
                    if (all_date.state.address != undefined) {


                        setupcomingAddress(all_date.state.address);
                        get_existing_reports(all_date.state.prop_address, all_date.state.place_id)

                    }
                }
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )




    const onClickSearch = async () => {

        if (searchProperty.address != undefined) {
            // getProperties( searchProperty.address, addressComponent);            
        }
    }

    const validateEmail = (e) => {
        var email = e.target.value

        if (validator.isEmail(email)) {
            setEmailError('Valid Email')
        } else {
            setEmailError('Invalid Email')

        }
    }

    //show contact form
    const [showContact, setShowContact] = React.useState(false)
    return (
        <>
         <Helmet>
                <title name="keywords" content="comprehensive database of strata reports, building and pest reports, valuations, depreciation reports, and QS reports in one place"> The fastest way to get a strata report</title>
                <meta name="description" content="With one click, generate a new strata report or download a pre-existing strata report for any property with Propti." />
            </Helmet>
            {loader === true &&
                <div className='loader-main-div'>
                    <div className='loader-second'>
                        <div class="loader"></div></div>
                </div>}
            <div className="d-flex flex-wrap" style={{ minHeight: "100vh" }}>


                <Image src={BgPattern1} className="position-absolute w-100" style={{ zIndex: "-1" }} alt="img"></Image>
                <Container>
                    <Header />
                    <div className="text-center set_report_title" style={{ position: "relative" }}>
                        <h1 className="app-title " name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">Order strata report</h1>
                        <Row className=''>
                            <Col md={12}>
                                <Form.Control as={Autocomplete} types={["address"]} componentRestrictions={{ country: "au" }} type='text' placeholder="Enter property address" value={prop_address != "" ? prop_address : ''} className='contact-form-input starta_search'

                                    onPlaceSelected={(place, e) => {
                                        console.log(place)
                                        set_place_id(place?.place_id)
                                        set_prop_address(place?.formatted_address)
                                        var lat = place?.geometry?.location.lat()
                                        set_latitude(lat)
                                        var long = place?.geometry?.location.lng()
                                        set_longitude(long)
                                        // 2/65 dover Road Rose Bay NSW, Australia
                                        // 2/65 Dover Road Rose Bay NSW, Australia
                                        setSearchProperty({
                                            ...searchProperty,
                                            address: place?.formatted_address?.replaceAll('Rd,', "Road")
                                        })
                                        setAddressComponent(place?.address_components);
                                        setupcomingAddress(place?.formatted_address?.replaceAll('Rd,', "Road"));
                                    }}
                                    onChange={
                                        (e) => {
                                            setSearchProperty({
                                                ...searchProperty,
                                                address: e.target.value
                                            })
                                            set_prop_address(e.target.value)
                                            set_place_id('')
                                            setAddressComponent(e.target.value);
                                            setupcomingAddress(e.target.value);
                                        }
                                    }
                                    required></Form.Control>
                            </Col>

                            <Button className="app-text btn btn-outline-primary search_button strata_search" style={{ borderRadius: '1.5rem', width: '100%', color: '#fff' }}
                                onClick={
                                    (e) => get_existing_reports(prop_address, place_id)
                                }
                                disabled={place_id ? false : true}
                            >Search</Button>
                        </Row>
                        {/* <span  style={{color:'red'}}>Please select from the options*</span> */}
                    </div>
                    {

                        <div className="search-report-block">
                            <Row className="justify-content-center">
                                {report_type === "New" ?
                                    <Col md={4}>
                                        <OrderPropertyCard
                                            report_type="New"
                                            address={prop_address}
                                            place_id={place_id}
                                            image={CardIamge1}
                                            lat={latitude}
                                            long={longitude}
                                            id={address_prop_id}
                                        />
                                    </Col>

                                    :
                                    report_type == "Old" &&
                                    <>
                                        <Col md={4}>

                                            <OrderPropertyCard
                                                report_type="Old"
                                                place_id={place_id}
                                                address={prop_address}
                                                lat={latitude}
                                                long={longitude}
                                                image={CardIamge1}
                                                id={address_prop_id}
                                            />
                                        </Col>

                                        <Col md={4}>

                                            <OrderPropertyCard
                                                report_type="New Independent"
                                                address={prop_address}
                                                place_id={place_id}
                                                lat={latitude}
                                                long={longitude}
                                                image={CardIamge1}
                                                id={address_prop_id}
                                            />
                                        </Col>
                                    </>
                                }

                            </Row>
                        </div>

                    }


                    {/* {
                        allProperties.tmp == 'empty' ?

                            found == 'new' ? 
                            
                                searchReport != '' ? 
                                    searchReport.filter((item, index) => index < 1).map(
                                        (item)=>(
                                            <Row className="justify-content-center">
                                                <Col md={4}>
                                                    <OrderPropertyCard
                                                        number = { code }
                                                        reportType = 'existing'
                                                        formatted_address = {searchProperty.address}
                                                        streetname = {item.address}
                                                        reportby={item.reported_by}
                                                        descrition = {"Report by : "+item.reported_by}
                                                        image = {item.file}
                                                        report = 'data' //{searchData[0].reported_by}
                                                        date = {item.report_date}
                                                        strataReport = {'strata'}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    
                                                    <OrderPropertyCard
                                                        number = { code }
                                                        reportType = 'noFound'
                                                        formatted_address = {searchProperty.address != null ? searchProperty.address : all_date.state.address}
                                                        streetname = {searchProperty.address != null ? searchProperty.address : all_date.state.address}
                                                        descrition = {""}
                                                        image = {CardIamge1}
                                                        report = 'data' //{searchData[0].reported_by}
                                                        date = {'TBC'}
                                                        strataReport = {'strata'}
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                    )
                                :
                                    
                                    
                                <Row className="justify-content-center">
                                     <Col md={4}>
                                         
                                        <OrderPropertyCard
                                            number = { code }
                                            reportType = 'noFound'
                                            formatted_address = {searchProperty.address != null ? searchProperty.address : all_date.state.address}
                                            streetname = {searchProperty.address != null ? searchProperty.address : all_date.state.address}
                                            descrition = {""}
                                            image = {CardIamge1}
                                            report = 'data' //{searchData[0].reported_by}
                                            date = {'TBC'}
                                            strataReport = {'strata'}
                                        />
                                    </Col>
                                </Row>
                            :

                            <div className = ""> 
                                <div className="d-flex justify-content-between px-md-5">   
                                    <p className="app-text px-3 my-0">*No Related Search Found. Can’t Find Property?</p>
                                    <p className="app-text px-3 my-0 text-primary app-link" onClick={()=>setShowContact(!showContact)}>Click Here</p>
                                </div>
                                <div  className="px-md-5 my-0 py-0"><hr/></div>
                                {
                                    showContact && 
                                    <>
                                        <div className="text-center mt-5">
                                            <p className="app-text mt-3">Please fill in the following details, we will get in touch with you as soon as possible. You<br/> can also call us on 1300 253 761 to order your report over the phone.</p>
                                        </div>
                                        <div className="mx-md-5 px-md-5 mt-5">
                                            <Form className="mx-md-5 px-md-5">
                                                <FormGroup>
                                                    <Form.Label className="contact-form-label">Property Name*</Form.Label>
                                                    <Form.Control type='text' placeholder="Enter Property Name Here" className='contact-form-input' required></Form.Control>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label className="contact-form-label">Your Name</Form.Label>
                                                    <Form.Control type='text' placeholder="Enter Your Name Here" className='contact-form-input'></Form.Control>
                                                </FormGroup>
                                                <Row >
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Form.Label className="contact-form-label">Email ID*</Form.Label>
                                                            <Form.Control type='text' placeholder="Enter your mail ID" className='contact-form-input'
                                                                
                                                            required></Form.Control>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Form.Label className="contact-form-label">Contact Number</Form.Label>
                                                            <Form.Control type='text' placeholder="Enter Your contact number" className='contact-form-input'></Form.Control>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="px-5">
                                                    <Col md={6}>
                                                        <Button className="app-text btn btn-outline-primary text-white" style={{borderRadius:'1.5rem', width:'100%', color:'#fff'}}>Cancel</Button>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Button className="app-text btn btn-outline-primary text-white" style={{borderRadius:'1.5rem', width:'100%', color:'#fff'}}>Submit</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </>
                                }
                                    
                            </div>
                        :
                        
                        reportDetail == "existing" ?

                            <div className="search-report-block">
                                <Row className="justify-content-center">
                                    {
                                        searchReport != '' ? 
                                        searchReport.filter((item, index) => index < 1).map(
                                            (item)=>(
                                                <Col md={4}>
                                                    <OrderPropertyCard
                                                        number = { code }
                                                        reportType = 'existing'
                                                        formatted_address = {searchProperty.address}
                                                        streetname = {item.address}
                                                        descrition = {"Report by : "+item.reported_by}
                                                        image = {item.file}
                                                        report = 'data' //{searchData[0].reported_by}
                                                        date = {item.report_date}
                                                        strataReport = {'strata'}
                                                        reportby={item.reported_by}
                                                    />
                                                </Col>
                                            )
                                        )
                                    :
                                        ''
                                        
                                    }
                                    {
                                        searchReport != '' ? 
                                            allProperties.filter((item, index) => index < 1).map(
                                                (item)=>(
                                                    <Col md={4}>
                                                        
                                                        <OrderPropertyCard
                                                            reportType = 'new'
                                                            number = { code }
                                                            formatted_address = {searchProperty.address}
                                                            streetname = {item.address}
                                                            descrition = {''}
                                                            method = {searchProperty.method}
                                                            type = {searchProperty.type}
                                                            image = {CardIamge1}
                                                            report = 'data' //{searchData[0].reported_by}
                                                            id = {item.id}
                                                            strataReport = {'strata'}
                                                        />
                                                    </Col>
                                                )
                                            )
                                        : ''
                                    }
                                </Row>
                            </div>

                            :

                            <div className="search-report-block">
                                <Row className="justify-content-center">
                                    {
                                        allProperties.filter((item, index) => index < 1).map(
                                            (item)=>(
                                                <Col md={4}>
                                                    
                                                    <OrderPropertyCard
                                                        reportType = 'moreNew'
                                                        number = { code }
                                                        streetname = {item.address}
                                                        formatted_address = {searchProperty.address}
                                                        descrition = ''
                                                        method = {searchProperty.method}
                                                        type = {searchProperty.type}
                                                        image = {CardIamge1}
                                                        report = '' //{searchData[0].reported_by}
                                                        id = {item.id}
                                                    />
                                                </Col>
                                            )
                                        )
                                    }
                                </Row>
                            </div>

                           
                               
                                   
                                  
                               
                    
                } */}

                </Container>
                <Footer></Footer>
            </div>
        </>
    )
}

export default Order
