import React, { useEffect, useState, useRef } from 'react';
import { Navbar, Button, Col, Container, Form, FormGroup, Image, Row } from 'react-bootstrap'
import Footer from '../components/footer'
import Header from '../components/header'
import moment from 'moment';
import BgPattern1 from '../assets/images/bg/pattern/hero_bg_pattern.svg';
import FooterBgPattern from '../assets/images/bg/pattern/footer_bg_pattern.svg';
import HeroImage from '../assets/images/svg/document.png';
import { Link, useHistory } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import { jsPDF } from "jspdf";
import Logo from '../assets/images/logo/logo.png';
import Line from '../assets/images/pdf/line.png';
import call from '../assets/images/pdf/call.png';
import mail from '../assets/images/pdf/mail.png';
import site from '../assets/images/pdf/internet.png';
import loaction from '../assets/images/pdf/loaction.png';
import bottom from '../assets/images/pdf/bottom.png';
import bg1 from '../assets/images/pdf/background1.png';
import bg2 from '../assets/images/pdf/background2.png';
import html2canvas from 'html2canvas';
import $ from 'jquery'
import axios from 'axios';
import FileSaver from 'file-saver';
import download from 'download-pdf'
import downloadPdf from 'download-pdf';
import fileDownload from 'js-file-download';
import Swal from 'sweetalert2';
function AuthorityDocument() {
    const [loader, set_loader] = useState(false)
    const [validated, setValidated] = useState(false);
    const inputRef = useRef(null);
    var history = useHistory()
    const [pdf, setPdf] = useState('')

    const [formData, setFormData] = useState({
        date: "",
        address: "",
        company: "",
        managementAddr: "",
        plan: "",
        currentHome: "",
        currentHomeRep: "",
        intrestedParty: "",
        intrestedhome: ""
    });
    console.log(formData);
    // const doc= new jsPDF('p','pt','a4')
    // $('#cmd').click(function () {

    //     doc.html($('#pdfFile').html(), 15, 15, {
    //         'width': 170
    //             // 'elementHandlers': specialElementHandlers
    //     });
    //     doc.save('sample-file.pdf');
    // });

    const handleSubmit = (event) => {
        event.preventDefault();
        set_loader(true)
        let data = {
            "address": formData.address,
            "currentHomeOwner": formData.currentHome,
            "interestedPerson": formData.intrestedParty,
            "strataCompanyName": formData.company,
            "strataManagementAddress": formData.managementAddr,
            "startaNumber": formData.plan,
            'currentHomeResp': formData.currentHomeRep,
            // "interestedPartyRep": formData.intrestedhome
        }
        axios.post(process.env.REACT_APP_NODE_BACKEND_API_URL + '/quicklinks/generatePdf', data, {
            headers: {
                "Authorization": "vw7kddgha5j30fz1r7ev"
            }
        }).then(res => {
            set_loader(false)
            formRef.current.reset();
            setValidated(false);
            setPdf(res.data.data)

            setFormData({
                ...formData, date: "",
                address: "",
                company: "",
                managementAddr: "",
                plan: "",
                currentHome: "",
                currentHomeRep: "",
                intrestedParty: "",
                intrestedhome: ""
            })

            // fileDownload(data, 'filename.csv');
            const link = document.createElement('a');
            link.href = `${process.env.REACT_APP_NODE_BACKEND_MEDIA_API_URL}/` + res.data.data;
            // link.href = 'http://198.211.110.165:4001/' + res.data.data;
            link.setAttribute(
                'target',
                '_blank'
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // // window.location.href=`http://192.168.2.79:4001/${res.data.data}`
            // FileSaver.saveAs(`http://192.168.2.79:4001/${res.data.data}`, "Authority");
            // download(`http://192.168.2.79:4001/${res.data.data}`)
        }).catch(err => {
            set_loader(false)
            formRef.current.reset();
            setValidated(false);
            Swal.fire('', err.response.data.message, 'warning')
        })

        // const doc= new jsPDF('p','pt','a4')
        // const content = document.getElementById("pdfFile");
        // html2canvas(document.getElementById("pdfFile"))
        //     .then((canvas) => {
        //         const imgData = canvas.toDataURL('image/jpeg');
        //         const pdf = new jsPDF('p','pt', 'a4');
        //         pdf.addImage(imgData,'JPEG',10,20);

        //         pdf.save("Authority Doc.pdf");
        //     });


        // console.log(content);
        // doc.html(content, {
        //     callback: function (doc) {
        //         console.log("in callback");
        //         doc.save("Authority Doc.pdf");
        //     },
        // var specialElementHandlers = {
        //     '#editor': function (element, renderer) {
        //         return true;
        //     }
        // }



        // });
        // setTimeout(() => {
        //     history.push('/')
        // }, 2500)



    };

    useEffect(
        () => {
            window.scrollTo(0, 0);
        }, []
    )
    const formRef = useRef(null);


    return (
        <div>
            {loader === true &&
                <div className='loader-main-div'>
                    <div className='loader-second'>
                        <div class="loader"></div></div>
                </div>}
            <Image src={BgPattern1} className="position-absolute w-100" style={{ zIndex: "-1" }} alt="img"></Image>
            <Container>
                <Header />
                <div className="text-center set_page_title">
                    <h1 className="app-title my-5" name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">Authority document</h1>
                </div>
                <Row className='insurance-block'>
                    <Col md={4}>
                        <Image src={HeroImage} className="position-asbolute w-100 set_hero_bg" alt="img"></Image>
                        <p className="hero-title text-center my-5">Authority document can be downloaded after filling out required details</p>
                    </Col>
                    <Col md={8} className="insurance-form">
                        <Form id="myForm" ref={formRef} validated={validated} onSubmit={handleSubmit}>
                            <FormGroup>
                                <Form.Label className="contact-form-label">Address to inspect*</Form.Label>
                                <Form.Control as={Autocomplete} types={["address"]} componentRestrictions={{ country: "au" }} ref={inputRef} type='text' placeholder="Enter address to inspect" className='contact-form-input'
                                    onPlaceSelected={(place) => {
                                        setFormData({
                                            ...formData,
                                            address: place.formatted_address
                                        })
                                    }}
                                    required></Form.Control>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label className="contact-form-label">Strata manager (Company name)*</Form.Label>
                                <Form.Control type='text' maxLength={50} placeholder="Strata management" className='contact-form-input'
                                    onChange={
                                        (e) => {
                                            setFormData({
                                                ...formData,
                                                company: e.target.value
                                            })
                                        }
                                    }
                                    required></Form.Control>
                            </FormGroup>
                            <Row>
                                <Col md={6} className="my-3">
                                    <Form.Label className="contact-form-label">Strata management address*</Form.Label>
                                    <Form.Control as={Autocomplete} types={["address"]} componentRestrictions={{ country: "au" }} ref={inputRef} type='text' placeholder="Enter property address" className='contact-form-input'
                                        onPlaceSelected={
                                            (place) => {
                                                setFormData({
                                                    ...formData,
                                                    managementAddr: place.formatted_address
                                                })
                                            }
                                        }
                                        required></Form.Control>
                                </Col>
                                <Col md={6} className="my-3">
                                    <Form.Label className="contact-form-label">Strata plan(SP)*</Form.Label>
                                    <Form.Control type='text' placeholder="Enter sp number" className='contact-form-input'
                                        onChange={
                                            (e) => {
                                                setFormData({
                                                    ...formData,
                                                    plan: e.target.value
                                                })
                                            }
                                        }
                                        required></Form.Control>
                                </Col>
                                <Col md={6} className="my-3">
                                    <Form.Label className="contact-form-label">Current homeowner*</Form.Label>
                                    <Form.Control type='text' maxLength={25} placeholder="Current homeowner name" className='contact-form-input'
                                        onChange={
                                            (e) => {
                                                setFormData({
                                                    ...formData,
                                                    currentHome: e.target.value
                                                })
                                            }
                                        }
                                        required></Form.Control>
                                </Col>
                                <Col md={6} className="my-3">
                                    <Form.Label className="contact-form-label">Current homeowner representative*</Form.Label>
                                    <Form.Control type='text' maxLength={25} placeholder="Homeowner representative name" className='contact-form-input'
                                        onChange={
                                            (e) => {
                                                setFormData({
                                                    ...formData,
                                                    currentHomeRep: e.target.value
                                                })
                                            }
                                        }
                                        required></Form.Control>
                                </Col>
                                <Col md={6} className="my-3">
                                    <Form.Label className="contact-form-label">Interested party*</Form.Label>
                                    <Form.Control type='text' maxLength={25} placeholder="Interested party name" className='contact-form-input'
                                        onChange={
                                            (e) => {
                                                setFormData({
                                                    ...formData,
                                                    intrestedParty: e.target.value
                                                })
                                            }
                                        }
                                        required></Form.Control>
                                </Col>
                                {/* <Col md={6} className="my-3">
                                    <Form.Label className="contact-form-label">Interested homeowner representative*</Form.Label>
                                    <Form.Control type='text' maxLength={25} placeholder="Name of interested parties representative" className='contact-form-input'
                                        onChange={
                                            (e) => {
                                                setFormData({
                                                    ...formData,
                                                    intrestedhome: e.target.value
                                                })
                                            }
                                        }
                                        required></Form.Control>
                                </Col> */}
                            </Row>
                            <Form.Check className="agree-checkbox app-text" name='purchage-agree' label='I agree to terms and conditions' type='checkbox' id='agree' required></Form.Check>
                            <Row className="justify-content-center mx-0 px-0">

                                <Button id="cmd" type="submit" className="navigation_button app-text" style={{ borderRadius: '1.5rem', width: '100%' }}>Download</Button>

                            </Row>
                        </Form>
                    </Col>
                </Row>




                <div style={{ display: "none", height: '100%', position: 'relative' }}>
                    <div id="pdfFile" style={{ width: "780px", position: "relative" }}>
                        <Image src={bg1} className="logo-image" style={{ position: "absolute", right: "14%", top: "5%" }} ></Image>
                        <Image src={bg2} className="logo-image" style={{ position: "absolute", bottom: "20%" }} ></Image>
                        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                            <div>
                                <Navbar.Brand><Image src={Logo} className="logo-image" alt="img"></Image></Navbar.Brand>
                                <Image src={Line} style={{
                                    width: " 68%",
                                    float: "right",
                                    padding: "36px 0 0 0"
                                }} alt="img"></Image>
                            </div>
                            <div style={{ display: "block", paddingTop: "40px" }}>
                                <p style={{ fontSize: "12px" }}>
                                    Propti Pty Ltd <br />
                                    45 Denison Street, <br />
                                    {/* Bondi Junction, Nsw,<span> 2022</span> */}
                                    <p > Bondi Junction, NSW, 2022 </p>
                                </p>

                                {/* <p style={{ fontSize: "12px" }}>
                                    To whom it may concern,
                                </p> */}
                                <p style={{ position: "relative", width: "26%", fontSize: "12px" }}>

                                    <span style={{ position: "absolute", width: " max-content", left: "0", bottom: "0" }} >
                                        {/* Date: {formData.date} */}
                                    </span>
                                </p>
                                <p style={{ textAlign: "right", marginTop: "-37px", fontSize: "12px" }}>Date: {moment(new Date()).format("DD MMM YYYY")}</p>
                            </div>
                            <div style={{ textAlign: "center", paddingTop: "50px" }}>

                                <div className='font-poppins' style={{ fontSize: "20px", fontWeight: "bold", color: "#313131" }} >
                                    <span className='ml-2'>AUTHORITY FOR STRATA RECORD</span></div>

                                <p style={{ fontSize: "14px", wordSpacing: '3px' }} >&nbsp;&nbsp;&nbsp;&nbsp;{formData.address}</p>
                            </div>
                            <div style={{ paddingTop: "30px" }} >
                                <p style={{ fontSize: "19px", fontWeight: "bold", color: "#1D83FF" }} >Strata Plan: {formData.plan}</p>
                            </div>
                            <div className="drawLine" style={{ width: "100%", height: "1px", backgroundColor: "#616161" }} ></div>
                            <div style={{ color: "#313131", fontSize: "12px", fontWeight: "600", paddingTop: "16px" }}>
                                {/* <p>
                                    We/ I, <span style={{ color:"#1D83FF" }}>{formData.currentHome}</span> the owners of the above property hereby authorise <span style={{ color:"#1D83FF" }}>{formData.intrestedParty}</span> or their representative to inspect the strata records for our property and obtain copies of such records as they may require.
                                </p> */}
                                <p>
                                    We/ I, <span style={{ color: "#1D83FF" }}>{formData.currentHome}</span> the owners of the above property hereby authorise <span style={{ color: "#1D83FF" }}>{formData.intrestedParty}</span> or their representative to inspect the strata records for our property and obtain copies of such records as they may require.
                                </p>
                                {/* <p>
                                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. on deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                                </p> */}
                            </div>

                            <div style={{ textAlign: "right", paddingTop: "30px" }}>
                                <h5 style={{ fontWeight: "500", color: "#313131", fontSize: "14px", margin: "0" }} >Yours faithfully</h5>
                                {/* <div style={{ textAlign:"right", marginTop:"18px" }} >
                                    <p style={{ fontWeight:"500", color:"#313131", fontSize:"16px", margin:"0"  }} >{formData.currentHome}</p>
                                    <p style={{ fontWeight:"400", color:"#313131", fontSize:"12px", margin:"0" }} >Property Owner</p>
                                </div> */}
                            </div>

                            <div className="drawLine" style={{ width: "100%", height: "1px", backgroundColor: "#616161", marginTop: "40px" }} ></div>
                            <div style={{}} >
                                <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                                    {formData.currentHomeRep}, Acting on behalf of the owner of the property or {formData.intrestedhome}
                                </p>
                            </div>


                            <div style={{ paddingTop: '24px', }}>
                                <div style={{ display: "flex" }} >
                                    <Image src={call} style={{ width: "19px", height: "19px" }} alt="img"></Image>
                                    <p style={{ paddingLeft: "12px", fontSize: "11px" }} > 02 9389 1077</p>
                                </div>
                                <div style={{ display: "flex" }} >
                                    <Image src={mail} style={{ width: "19px", height: "19px" }} alt="img"></Image>
                                    <p style={{ paddingLeft: "12px", fontSize: "11px" }} >support@propti.com.au</p>
                                </div>
                                <div style={{ display: "flex" }} >
                                    <Image src={site} style={{ width: "19px", height: "19px" }} alt="img"></Image>
                                    <p style={{ paddingLeft: "12px", fontSize: "11px" }} > propti.com.au</p>
                                </div>
                                <div style={{ display: "flex" }} >
                                    <Image src={loaction} style={{ width: "14px", height: "18px", marginLeft: "2px" }} alt="img"></Image>
                                    <p style={{ paddingLeft: "12px", fontSize: "11px" }} > 45 Denison Street, Bondi Junction, NSW, 2022</p>
                                </div>
                            </div>


                        </div>
                        {/* <footer style={{position:"absolute", bottom:"0", width:"100%"}}>
                            
                                <Image src={bottom} style={{ width: "100%" }}></Image>
                        
                        </footer> */}
                    </div>
                </div>

            </Container>


            <Footer />
        </div>
    );
}

export default AuthorityDocument
