/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  FormGroup,
  Row,
} from "react-bootstrap";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CompanyInput from "../../components/common/CompanyInput";
import { useLocation } from "react-router-dom";
import BgPattern1 from "../../assets/images/bg/pattern/hero_bg_pattern.svg";
import axios from "axios";
import GetProperties from "../getProperties";
import Autocomplete from "react-google-autocomplete";
import validator from "validator";
import Swal from "sweetalert2";
import moment from "moment";
import { Helmet } from "react-helmet";
import SelectSearch, { fuzzySearch } from "react-select-search";
import MobileInput from "../../components/common/MobileInput";

function OrderInspection() {
  const inputRef = useRef(null);
  const all_date = useLocation();
  var address = "";
  // const [address, setupcomingAddress] = React.useState(null);
  const [images, setImages] = React.useState("");
  var tmp = 1;
  const [addressComponent, setAddressComponent] = React.useState([]);
  var addressComponent1 = [];
  const [emailError, setEmailError] = useState("");
  const [btnCheck, setbtnCheck] = React.useState(true);
  const [mobileError, setmobileError] = useState("");
  const [agentOption, setagentOption] = React.useState([]);
  const [realEstateAgentOption, setRealEstateAgentOption] = React.useState([]);
  const [agentBuyerOption, setagentBuyerOption] = React.useState([]);
  const [agentsociliorOption, setagentsociliorOption] = React.useState([]);

  const [first_agent_check, set_first_agent_check] = useState(false);
  const [buyer_agent_check, set_buyer_agent_check] = useState(false);
  const [solicitor_agent_check, set_solicitor_agent_check] = useState(false);
  const [Agent_on_list, set_Agent_on_list] = useState("none");
  const [buyer_agent_on_list, set_buyer_agent_on_list] = useState("none");
  const [profession_agent_on_list, set_profession_agent_on_list] =
    useState("none");
  const [solicitor_agent_on_list, set_solicitor_agent_on_list] =
    useState("none");

  const [emailError1, setEmailError1] = useState("");
  const [emailError2, setEmailError2] = useState("");
  const [emailError4, setEmailError4] = useState("");

  const [searchProperty, setSearchProperty] = React.useState({
    address: address != "" ? address : "",
    method: "new",
    type: "valuation",
  });

  async function set_component(data, address) {
    // setAddressComponent(data);
    // address = all_date.state.address;
    addressComponent1 = data;
    var addpr = {
      address: address,
      method: "new",
    };
    console.log("12");
    setImages(<GetProperties address={addpr} component={data} />);
  }
  // const validateEmail = (e) => {
  //     var email = e.target.value

  //     if (validator.isEmail(email)) {
  //         setEmailError('true')
  //         setFormData({
  //             ...detail2,
  //             email: e.target.value
  //         })
  //     } else {
  //         setEmailError('false')

  //     }
  // }

  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
      setFormData({
        ...formData,
        email: e.target.value,
      });
      // setbtnCheck(false)
    } else {
      setEmailError("Invalid");
      setbtnCheck(true);
    }
  };
  const validateRealEstateAgentEmail = (e) => {
    var email = e.target.value.toLowerCase();

    if (validator.isEmail(email)) {
      setEmailError4("");
      setFormData({
        ...formData,
        agent_email: e.target.value,
      });
      // setbtnCheck(false)
    } else {
      setEmailError4("Invalid");
      setbtnCheck(true);
    }
  };
  const validateBuyerAgentEmail = (e) => {
    var email = e.target.value.toLowerCase();

    if (validator.isEmail(email)) {
      setEmailError1("");
      setFormData({ ...formData, buyers_agent_email: e.target.value });
      // setbtnCheck(false)
    } else {
      setEmailError1("Invalid");
      setbtnCheck(true);
    }
  };
  const validateSolicitorAgentEmail = (e) => {
    var email = e.target.value.toLowerCase();

    if (validator.isEmail(email)) {
      setEmailError2("");
      setFormData({ ...formData, solicitor_agent_email: e.target.value });
      // setbtnCheck(false)
    } else {
      setEmailError2("Invalid");
      setbtnCheck(true);
    }
  };

  const validateMobile = (e) => {
    var mobile = e ? e : "";

    if (mobile && validator.isMobilePhone(mobile, "any")) {
      setmobileError("");
      setFormData({
        ...formData,
        number: e,
      });
      // setbtnCheck(false)
    } else {
      setmobileError("Invalid");
      setbtnCheck(true);
    }
  };

  const [search, setSearch] = React.useState("block");
  const [detail1style, setDetail1Style] = React.useState("none");
  const [detail2style, setFormDataStyle] = React.useState("none");
  const [place_id, set_place_id] = useState("");
  const [loc_address, set_loc_address] = useState("");
  const [location_lattitude, set_location_lattitude] = useState("");
  const [location_longitude, set_location_longitude] = useState("");

  // const [formData, setFormData] = React.useState({
  //     action:"purchase",
  //     bedrooms:"",
  //     bathrooms:""
  // })
  // const [detail2, setFormData] = React.useState({
  //     action:"purchase",
  //     name:"",
  //     address:"",
  //     email:"",
  //     mobile:"",
  //     report:"",
  //     inspection:"",
  //     manager_name:"",
  //     manager_number:"",
  //
  // })
  const [formData, setFormData] = React.useState({
    order_report_type: "B&P",
    place_id: "",
    address: loc_address,
    location_latitude: location_lattitude,
    location_longitude: location_longitude,
    agent_id: "",
    agent_first_name: "",
    agent_email: "",
    do_have_buyers_agent: "",
    need_us_to_recommend: "",
    buyers_agent_id: "",
    buyers_agent_first_name: "",
    buyers_agent_email: "",
    do_have_solicitor_agent: "",
    need_us_to_recommend_solicitor_agent: "",
    solicitor_agent_id: "",
    solicitor_agent_first_name: "",
    solicitor_agent_email: "",
    have_organised_finanace: "",
    like_us_to_reccomend_broker: "",
    bedrooms: "",
    bathrooms: "",
    name: "",
    // building_address: '',
    email: "",
    number: "",
    report: "",
    inspection: "",
    manager_name: "",
    manager_number: "",
    order_type: "N",
    agree: false,
    reason_of_report: "",
    company_id: "",
    company_name: "",
  });

  const [item1, setitem1] = React.useState("");
  const [item2, setitem2] = React.useState("");
  const [item3, setitem3] = React.useState("");
  const [item4, setitem4] = React.useState("");
  const [loader, set_loader] = React.useState(false);
  const [errName, set_errName] = useState("");

  const [InputBox, setmyInputBox] = React.useState();
  //

  async function nextDetail() {
    if (
      formData.bedrooms != "" &&
      formData.bathrooms != "" &&
      formData.report !== ""
    ) {
      // history.push("/inspection/detail2");
      setDetail1Style("none");
      setFormDataStyle("block");
      window.scrollTo(0, 0);
    } else {
      // notify("Please Fill All Questions")
      if (formData.report === "") {
        Swal.fire("", "Please select your report type", "warning");
      } else if (formData.bedrooms === "") {
        Swal.fire("", "Please select your property type.", "warning");
      } else if (formData.bathrooms === "") {
        Swal.fire("", "Please select how many bathrooms field.", "warning");
      }
    }
  }
  var date = new Date();

  async function setInputBox(status) {
    if (status == 0) {
      setmyInputBox();
    } else {
    }
  }

  async function validate(evt) {
    var theEvent = evt || window.event;

    if (theEvent.type === "paste") {
      key = window.event.clipboardData.getData("text/plain");
    } else {
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const [itemname1, setitemname1] = React.useState("");

  // check box click
  async function handleInput(e) {
    let item_name = e.target.id;
    let bed_number = parseInt(item_name.substr(0, 1));
    let which_type = item_name
      .substr(item_name.length - 9)
      .toLowerCase()
      .replaceAll("_", "");
    let flag = true;

    if (which_type == "bedrooms") {
      setitem1(
        <Col md={6} className="my-3 inspection_font">
          {item_name.replaceAll("_", " ")}
        </Col>
      );
      setitemname1(item_name.replaceAll("_", " "));
    } else if (which_type == "bathrooms") {
      if (itemname1 != "") {
        let tmp = itemname1 + " , " + item_name.replaceAll("_", " ");
        setitem1(
          <>
            <Col md={6} className="my-3 inspection_font">
              {tmp}
            </Col>
            <Col md={6} className="my-3 align_right inspection_font">
              $400
            </Col>
          </>
        );
      }
    }
  }

  async function add_building_report(e) {
    e.preventDefault();

    let agent_list = [];

    if (formData.name.trim() === "") {
      set_errName("Name is required.");
      return false;
    }
    if (Agent_on_list === "none" && formData.agent_id == "") {
      // setbtnCheck(true)
      set_errName("Agent is required.");
      return false;
    }
    if (Agent_on_list === "block" && formData.agent_first_name === "") {
      // setbtnCheck(true)
      set_errName("Agent Name is required.");
      return false;
    }
    if (Agent_on_list === "block" && formData.agent_email === "") {
      // setbtnCheck(true)
      set_errName("Agent Email is required.");
      return false;
    }
    if (Agent_on_list === "block" && !validator.isEmail(formData.agent_email)) {
      // alert("hi")
      setEmailError4("Invalid Email");
      return false;
    }
    if (formData.do_have_buyers_agent === "") {
      set_errName("Do you have buyer agent is required.");
      return false;
    }
    if (
      formData.do_have_buyers_agent === "YES" &&
      buyer_agent_on_list === "none" &&
      formData.buyers_agent_id === ""
    ) {
      // setbtnCheck(true)
      set_errName("Buyer's agent is required.");
      return false;
    }
    if (
      formData.do_have_buyers_agent === "YES" &&
      buyer_agent_on_list === "block" &&
      formData.buyers_agent_first_name === ""
    ) {
      // setbtnCheck(true)
      set_errName("Buyer agent name is required.");
      return false;
    }
    if (
      formData.do_have_buyers_agent === "YES" &&
      buyer_agent_on_list === "block" &&
      formData.buyers_agent_email === ""
    ) {
      // setbtnCheck(true)
      set_errName("Buyer email is required.");
      return false;
    }
    if (
      formData.do_have_buyers_agent === "YES" &&
      buyer_agent_on_list === "block" &&
      !validator.isEmail(formData.buyers_agent_email)
    ) {
      setEmailError1("Invalid Email");
      return false;
    }
    if (
      formData.do_have_buyers_agent === "NO" &&
      formData.need_us_to_recommend === ""
    ) {
      // setbtnCheck(true)
      set_errName("Do you want us to recommend buyer agent is required.");
      return false;
    }
    if (formData.do_have_solicitor_agent === "") {
      set_errName("Do you have Solicitor/ conveyancer  is required.");
      return false;
    }
    if (
      formData.do_have_solicitor_agent === "YES" &&
      solicitor_agent_on_list === "none" &&
      formData.solicitor_agent_id === ""
    ) {
      // setbtnCheck(true)
      set_errName("Solicitor/ conveyancer  is required.");
      return false;
    }
    if (
      formData.do_have_solicitor_agent == "YES" &&
      solicitor_agent_on_list === "block" &&
      formData.solicitor_agent_first_name === ""
    ) {
      // setbtnCheck(true)
      set_errName("Solicitor agent name is required.");
      return false;
    }
    if (
      formData.do_have_solicitor_agent == "YES" &&
      solicitor_agent_on_list === "block" &&
      formData.solicitor_agent_email === ""
    ) {
      // setbtnCheck(true)
      set_errName("Solicitor agent email is required.");
      return false;
    }
    if (
      formData.do_have_solicitor_agent == "YES" &&
      solicitor_agent_on_list === "block" &&
      !validator.isEmail(formData.solicitor_agent_email)
    ) {
      setEmailError2("Invalid");
      return false;
    }
    if (
      formData.do_have_solicitor_agent === "NO" &&
      formData.need_us_to_recommend_solicitor_agent === ""
    ) {
      // setbtnCheck(true)
      set_errName(
        "Do you want us to recommend you a solicitor/ conveyancer is required."
      );
      return false;
    }
    if (
      formData.purchasing_report_on_behalf_on_someone !== "YES" &&
      formData.have_organised_finanace === ""
    ) {
      // setbtnCheck(true)
      set_errName("Have you organized finance field is required.");
      return false;
    }
    if (
      formData.have_organised_finanace === "NO" &&
      formData.like_us_to_reccomend_broker === ""
    ) {
      // setbtnCheck(true)
      set_errName(
        "Would you like us to recommend you an affiliated finance broker is required. "
      );
      return false;
    }
    // if (formData.building_address === '') {
    //     set_errName('Address is required. ')
    //     return false;
    // }
    if (formData.email === "") {
      set_errName("Email is required.");
      return false;
    }

    if (formData.number === "") {
      set_errName("Number is required.");
      return false;
    }
    if (formData.report === "") {
      set_errName("Please select report type.");
      return false;
    }
    if (formData.inspection === "") {
      set_errName("Please select date by which this report is required.");
      return false;
    }
    if (formData.reason_of_report === "") {
      set_errName("Please select purpose of property.");
      return false;
    }
    // if (formData.company_id === "" && formData.company_name === "") {
    //   set_errName("Please select your company.");
    //   return false;
    // }
    // if (formData.manager_number === "") {
    //     set_errName("Manager number is required.")
    //     return false;
    // }
    if (formData.agree === false) {
      set_errName("Please accept terms and condition.");
      return false;
    }
    setbtnCheck(false);
    if (formData.agent_email !== "") {
      agent_list.push(formData.agent_email);
    }
    if (formData.buyers_agent_email !== "") {
      agent_list.push(formData.buyers_agent_email);
    }
    if (formData.solicitor_agent_email !== "") {
      agent_list.push(formData.solicitor_agent_email);
    }

    set_errName("");
    setEmailError4("");
    if (agent_list.length !== 0) {
      var data = {
        data: agent_list,
      };

      await axios
        .post(
          process.env.REACT_APP_NODE_BACKEND_API_URL +
            "/report/checkIfAgentEmailsExistOrNot",
          data,
          {
            headers: {
              Authorization: "vw7kddgha5j30fz1r7ev",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            set_loader(true);
            let data = {
              order_report_type: formData.order_report_type,
              order_type: formData.order_type,
              place_id: formData.place_id,
              address: formData.address,
              location_latitude: formData.location_latitude,
              location_longitude: formData.location_longitude,
              property_type_for_building_and_pest: formData.bedrooms,
              bathrooms_count_for_building_and_pest: formData.bathrooms,
              name_for_building_and_pest: formData.name,
              address_for_building_and_pest: formData.building_address,
              email_for_building_and_pest: formData.email,
              contact_number_for_building_and_pest: formData.number,
              report_type_for_building_and_pest: formData.report,
              inspection_date_for_building_and_pest: formData.inspection,
              reason_of_report: formData.reason_of_report,
              company_id: formData.company_id,
              company_name_ref: formData.company_name,

              // "property_manager_name_for_building_and_pest": formData.manager_name,
              // "property_manager_number_for_building_and_pest": formData.manager_number,
            };

            if (Agent_on_list === "none") {
              data["agent_id_for_building_and_pest"] = formData.agent_id;
            } else {
              data["agent_id_for_building_and_pest"] = "";
            }
            if (Agent_on_list === "block") {
              data["agent_first_name_for_building_and_pest"] =
                formData.agent_first_name;
              data["agent_email_for_building_and_pest"] = formData.agent_email;
            } else {
              data["agent_first_name_for_building_and_pest"] = "";
              data["agent_email_for_building_and_pest"] = "";
            }
            data["do_have_buyers_agent_for_building_and_pest"] =
              formData.do_have_buyers_agent;
            data["need_us_to_recommend_for_building_and_pest"] =
              formData.need_us_to_recommend;
            if (buyer_agent_on_list === "none") {
              data["buyers_agent_id_for_building_and_pest"] =
                formData.buyers_agent_id;
            } else {
              data["buyers_agent_id_for_building_and_pest"] = "";
            }
            if (buyer_agent_on_list === "block") {
              data["buyers_agent_first_name_for_building_and_pest"] =
                formData.buyers_agent_first_name;
              data["buyers_agent_email_for_building_and_pest"] =
                formData.buyers_agent_email;
            } else {
              data["buyers_agent_first_name_for_building_and_pest"] = "";
              data["buyers_agent_email_for_building_and_pest"] = "";
            }
            data["do_have_solicitor_agent_for_building_and_pest"] =
              formData.do_have_solicitor_agent;
            data["need_us_to_recommend_solicitor_agent_for_building_and_pest"] =
              formData.need_us_to_recommend_solicitor_agent;
            if (solicitor_agent_on_list === "none") {
              data["solicitor_agent_id_for_building_and_pest"] =
                formData.solicitor_agent_id;
            } else {
              data["solicitor_agent_id_for_building_and_pest"] = "";
            }
            if (solicitor_agent_on_list === "block") {
              data["solicitor_agent_first_name_for_building_and_pest"] =
                formData.solicitor_agent_first_name;
              data["solicitor_agent_email_for_building_and_pest"] =
                formData.solicitor_agent_email;
            } else {
              data["solicitor_agent_first_name_for_building_and_pest"] = "";
              data["solicitor_agent_email_for_building_and_pest"] = "";
            }
            data["have_organised_finanace_for_building_and_pest"] =
              formData.have_organised_finanace;
            data["like_us_to_reccomend_broker_for_building_and_pest"] =
              formData.like_us_to_reccomend_broker;

            axios
              .post(
                process.env.REACT_APP_NODE_BACKEND_API_URL + "/report",
                data,
                {
                  headers: {
                    Authorization: "vw7kddgha5j30fz1r7ev",
                  },
                }
              )
              .then((response) => {
                set_loader(false);
                if (response.status === 200) {
                  Swal.fire(
                    "Successfully Submitted.",
                    "Admin will contact you shortly.",
                    "success"
                  ).then((result) => {
                    if (result.isConfirmed) {
                      history.push("/");
                    }
                  });
                  setTimeout(() => {
                    history.push("/");
                  }, 1000);
                }
              })
              .catch((err) => {
                Swal.fire("", err.response.data.message, "warning");
                set_loader(false);
              });
          }
        })
        .catch((err) => {
          set_loader(false);
          set_errName(err.response.data.message);
          return false;
        });
    } else {
      set_loader(true);
      let data = {
        order_report_type: formData.order_report_type,
        order_type: formData.order_type,
        place_id: formData.place_id,
        address: formData.address,
        location_latitude: formData.location_latitude,
        location_longitude: formData.location_longitude,
        property_type_for_building_and_pest: formData.bedrooms,
        bathrooms_count_for_building_and_pest: formData.bathrooms,
        name_for_building_and_pest: formData.name,
        // "address_for_building_and_pest": formData.building_address,
        email_for_building_and_pest: formData.email,
        contact_number_for_building_and_pest: formData.number,
        report_type_for_building_and_pest: formData.report,
        inspection_date_for_building_and_pest: formData.inspection,
        reason_of_report: formData.reason_of_report,
        company_id: formData.company_id,
        company_name_ref: formData.company_name,
        // "property_manager_name_for_building_and_pest": formData.manager_name,
        // "property_manager_number_for_building_and_pest": formData.manager_number,
      };
      if (Agent_on_list === "none") {
        data["agent_id_for_building_and_pest"] = formData.agent_id;
      } else {
        data["agent_id_for_building_and_pest"] = "";
      }
      if (Agent_on_list === "block") {
        data["agent_first_name_for_building_and_pest"] =
          formData.agent_first_name;
        data["agent_email_for_building_and_pest"] = formData.agent_email;
      } else {
        data["agent_first_name_for_building_and_pest"] = "";
        data["agent_email_for_building_and_pest"] = "";
      }
      data["do_have_buyers_agent_for_building_and_pest"] =
        formData.do_have_buyers_agent;
      data["need_us_to_recommend_for_building_and_pest"] =
        formData.need_us_to_recommend;
      if (buyer_agent_on_list === "none") {
        data["buyers_agent_id_for_building_and_pest"] =
          formData.buyers_agent_id;
      } else {
        data["buyers_agent_id_for_building_and_pest"] = "";
      }
      if (buyer_agent_on_list === "block") {
        data["buyers_agent_first_name_for_building_and_pest"] =
          formData.buyers_agent_first_name;
        data["buyers_agent_email_for_building_and_pest"] =
          formData.buyers_agent_email;
      } else {
        data["buyers_agent_first_name_for_building_and_pest"] = "";
        data["buyers_agent_email_for_building_and_pest"] = "";
      }
      data["do_have_solicitor_agent_for_building_and_pest"] =
        formData.do_have_solicitor_agent;
      data["need_us_to_recommend_solicitor_agent_for_building_and_pest"] =
        formData.need_us_to_recommend_solicitor_agent;
      if (solicitor_agent_on_list === "none") {
        data["solicitor_agent_id_for_building_and_pest"] =
          formData.solicitor_agent_id;
      } else {
        data["solicitor_agent_id_for_building_and_pest"] = "";
      }
      if (solicitor_agent_on_list === "block") {
        data["solicitor_agent_first_name_for_building_and_pest"] =
          formData.solicitor_agent_first_name;
        data["solicitor_agent_email_for_building_and_pest"] =
          formData.solicitor_agent_email;
      } else {
        data["solicitor_agent_first_name_for_building_and_pest"] = "";
        data["solicitor_agent_email_for_building_and_pest"] = "";
      }
      data["have_organised_finanace_for_building_and_pest"] =
        formData.have_organised_finanace;
      data["like_us_to_reccomend_broker_for_building_and_pest"] =
        formData.like_us_to_reccomend_broker;

      axios
        .post(process.env.REACT_APP_NODE_BACKEND_API_URL + "/report", data, {
          headers: {
            Authorization: "vw7kddgha5j30fz1r7ev",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            set_loader(false);
            Swal.fire(
              "Successfully Submitted.",
              "Admin will contact you shortly.",
              "success"
            ).then((result) => {
              if (result.isConfirmed) {
                history.push("/");
              }
            });
            setTimeout(() => {
              history.push("/");
            }, 1000);
          }
        })
        .catch((err) => {
          set_loader(false);
          Swal.fire("", err.response.data.message, "warning");
        });
    }
  }

  useEffect(() => {
    get_agents();
    window.scrollTo(0, 0);
    if (all_date.state == undefined) {
    } else {
      if (tmp == 1) {
        tmp = 0;
        setSearchProperty({
          ...searchProperty,
          address: all_date.state.address,
        });
        set_component(all_date.state.component, all_date.state.address);

        setSearch("none");
        setDetail1Style("block");
        setFormData({
          ...formData,
          address: all_date.state.address,
          place_id: all_date.state.place_id,
          location_latitude: all_date.state.lat,
          location_longitude: all_date.state.long,
        });
      }
    }
  }, []);

  const history = useHistory();

  const onClickSearch = (e) => {
    e.preventDefault();
    setImages(
      <GetProperties address={searchProperty} component={addressComponent} />,
      set_loader(false)
    );
    setSearch("none");
    setDetail1Style("block");
  };

  async function get_agents() {
    await axios
      .get(process.env.REACT_APP_NODE_BACKEND_API_URL + "/auth/?agent_type=", {
        headers: {
          Authorization: "vw7kddgha5j30fz1r7ev",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          var temp = [];
          var temp2 = [];
          for (var i = 0; i < response.data.data.length; i++) {
            var obj = {};
            if (response.data.data[i].agent_type === "R") {
              obj["name"] = response.data.data[i].name;
              obj["value"] = response.data.data[i]._id;
              temp.push(obj);
            }
            obj["name"] = response.data.data[i].name;
            obj["value"] = response.data.data[i]._id;
            temp2.push(obj);
          }
          setRealEstateAgentOption(temp);
          setagentOption(temp2);
          var temp2 = [];
          response.data.data
            .filter((obj) => obj.agent_type == "B")
            .map((item) => {
              var obj = {};
              obj["name"] = item.name;
              obj["value"] = item._id;
              temp2.push(obj);
            });
          setagentBuyerOption(temp2);
          var temp3 = [];
          response.data.data
            .filter((obj) => obj.agent_type == "C" || obj.agent_type == "CO")
            .map((item) => {
              var obj = {};
              obj["name"] = item.name;
              obj["value"] = item._id;
              temp3.push(obj);
            });
          setagentsociliorOption(temp3);
        }
      });
  }

  return (
    <>
      <Helmet>
        <title
          name="keywords"
          content="comprehensive database of strata reports, building and pest reports, valuations, depreciation reports, and QS reports in one place"
        >
          Fast and accurate building and pest reports{" "}
        </title>
        <meta
          name="description"
          content="Get a new building and pest report or see if your property has a pre-existing building and pest report available with Propti."
        />
      </Helmet>
      {loader === true && (
        <div className="loader-main-div">
          <div className="loader-second">
            <div class="loader"></div>
          </div>
        </div>
      )}
      <div className="d-flex flex-wrap" style={{ minHeight: "100vh" }}>
        <Image
          src={BgPattern1}
          className="position-absolute w-100"
          style={{ zIndex: "-1" }}
          alt="img"
        ></Image>
        <Container>
          <Header />
          {/* <Switch>
                    <Route exact path="/inspection"> */}
          <div
            className="text-center set_report_title"
            style={{ display: search, position: "relative" }}
          >
            <h1
              className="app-title"
              name="keywords"
              content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more."
            >
              Order building & pest inspection report
            </h1>
            {/* <div className="px-md-5 set_report_search home_search position-relative">
                                <GooglePlaceAutoComplete
                                    value={address}
                                    setSearchProperty = {setSearchProperty} 
                                    searchProperty = {searchProperty}
                                    onClickSearch = {onClickSearch}
                                    placeholder = "Enter the address you need a order building and / or pest report for"
                                />
                            </div> */}
            <Row>
              <Col md={12}>
                <Form.Control
                  as={Autocomplete}
                  types={["address"]}
                  componentRestrictions={{ country: "au" }}
                  ref={inputRef}
                  type="text"
                  placeholder="Enter property address"
                  className="contact-form-input starta_search"
                  onPlaceSelected={(place) => {
                    setFormData({
                      ...formData,
                      address: place?.formatted_address,
                      place_id: place?.place_id,
                      location_latitude: place?.geometry?.location.lat(),
                      location_longitude: place?.geometry?.location.lng(),
                    });
                    setSearchProperty({
                      ...searchProperty,
                      address: place.formatted_address,
                    });
                    setAddressComponent(place.address_components);
                  }}
                  onChange={(e) => {
                    setSearchProperty({
                      ...searchProperty,
                      address: e.target.value,
                    });
                    setAddressComponent(e.target.value);
                    setFormData({
                      ...formData,
                      address: "",
                      place_id: "",
                      location_latitude: "",
                      location_longitude: "",
                    });
                  }}
                  required
                ></Form.Control>
              </Col>
              <Button
                className="app-text btn btn-outline-primary search_button strata_search"
                style={{ borderRadius: "1.5rem", width: "100%", color: "#fff" }}
                onClick={(e) => onClickSearch(e)}
                disabled={formData.place_id ? false : true}
              >
                Search
              </Button>
            </Row>
          </div>
          {/* </Route>
                </Switch> */}
          <div className="set_building_title px-3 margintop0">
            {/* <Switch>
                        <Route exact path="/inspection/detail1"> */}

            <div style={{ display: detail1style }}>
              <p className="app-title align_center">
                Order building & pest inspection report
              </p>
              <Row className="mt-==">
                <Col md={4}>
                  {images}

                  <div className="text-center">
                    <p className="strata-img-title">{searchProperty.address}</p>
                    <p className="strata-img-date px-3">
                      {" "}
                      Price and estimated time will be sent to you via email
                      once order is confirmed
                    </p>
                  </div>
                </Col>
                <Col md={8} className="set-build-form">
                  {/* onSubmit={handleSubmit} */}
                  <Form>
                    <Row>
                      <p className="build-form-title px-3 w-100">
                        Please select your report type*
                      </p>
                      <Col md={12} className="my-3">
                        <Form.Check
                          className="lable_color"
                          label="Pest inspection"
                          type="radio"
                          id="pest_inspection"
                          name="inspection-radiogroup"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              report: e.target.id,
                            });
                          }}
                          onClick={(e) => handleInput(e)}
                        />
                      </Col>
                      <Col md={6} className="my-3">
                        <Form.Check
                          className="lable_color"
                          label="Building inspection"
                          type="radio"
                          id="building_inspection"
                          name="inspection-radiogroup"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              report: e.target.id,
                            })
                          }
                          onClick={(e) => handleInput(e)}
                        />
                      </Col>
                      <Col md={6} className="my-3">
                        <Form.Check
                          className="lable_color"
                          label="Both"
                          type="radio"
                          id="both"
                          name="inspection-radiogroup"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              report: e.target.id,
                            })
                          }
                          onClick={(e) => handleInput(e)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <p className="build-form-title px-3 w-100">
                        How many bedrooms does the property have?*
                      </p>
                      <Col md={6} className="my-3">
                        <Form.Check
                          className="lable_color"
                          label="1-4 Bedrooms"
                          type="radio"
                          id="1 to 4 Bedrooms"
                          name="inspection-radiogroup-1"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              bedrooms: e.target.id,
                            });
                          }}
                          onClick={(e) => handleInput(e)}
                        />
                      </Col>
                      <Col md={6} className="my-3">
                        <Form.Check
                          className="lable_color"
                          label="5 Bedrooms"
                          type="radio"
                          id="5 Bedrooms"
                          name="inspection-radiogroup-1"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bedrooms: e.target.id,
                            })
                          }
                          onClick={(e) => handleInput(e)}
                        />
                      </Col>
                      <Col md={6} className="my-3">
                        <Form.Check
                          className="lable_color"
                          label="6 Bedrooms"
                          type="radio"
                          id="6 Bedrooms"
                          name="inspection-radiogroup-1"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bedrooms: e.target.id,
                            })
                          }
                          onClick={(e) => handleInput(e)}
                        />
                      </Col>
                      <Col md={6} className="my-3">
                        <Form.Check
                          className="lable_color"
                          label="More than 6 Bedrooms"
                          type="radio"
                          id="7 or more Bedrooms"
                          name="inspection-radiogroup-1"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bedrooms: e.target.id,
                            })
                          }
                          onClick={(e) => handleInput(e)}
                        />
                      </Col>
                    </Row>

                    <p className="build-form-title w-100">
                      How many bathrooms*
                    </p>
                    <Row>
                      <Col md={6} className="my-3">
                        <Form.Check
                          className="lable_color"
                          label="1-2 Bathrooms"
                          type="radio"
                          id="1 to 2 Bathrooms"
                          name="inspection-radiogroup-2"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bathrooms: e.target.id,
                            })
                          }
                          onClick={(e) => handleInput(e)}
                        />
                      </Col>
                      <Col md={6} className="my-3">
                        <Form.Check
                          className="lable_color"
                          label="3 Bathrooms"
                          type="radio"
                          id="3 Bathrooms"
                          name="inspection-radiogroup-2"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bathrooms: e.target.id,
                            })
                          }
                          onClick={(e) => handleInput(e)}
                        />
                      </Col>
                      <Col md={6} className="my-3">
                        <Form.Check
                          className="lable_color"
                          label="4 Bathrooms"
                          type="radio"
                          id="4 Bathrooms"
                          name="inspection-radiogroup-2"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bathrooms: e.target.id,
                            })
                          }
                          onClick={(e) => handleInput(e)}
                        />
                      </Col>
                      <Col md={6} className="my-3">
                        <Form.Check
                          className="lable_color"
                          label="5 Bathrooms"
                          type="radio"
                          id="5 Bathrooms"
                          name="inspection-radiogroup-2"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bathrooms: e.target.id,
                            })
                          }
                          onClick={(e) => handleInput(e)}
                        />
                      </Col>
                      <Col md={6} className="my-3">
                        <Form.Check
                          className="lable_color"
                          label="6 or more Bathrooms"
                          type="radio"
                          id="6 Bathrooms"
                          name="inspection-radiogroup-2"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bathrooms: e.target.id,
                            })
                          }
                          onClick={(e) => handleInput(e)}
                        />
                      </Col>
                    </Row>
                    <Row className=" my-5">
                      <Col md={12}>
                      <Button
                        className="app-text navigation_button"
                        style={{ borderRadius: "1.5rem", width: "100%" }}
                        onClick={() => {
                          setSearch("block");
                          setDetail1Style("none");
                        }}
                      >
                        Go back
                      </Button>
                      <Button
                        className="app-text navigation_button"
                        style={{ borderRadius: "1.5rem", width: "100%" }}
                        onClick={() => nextDetail()}
                      >
                        Next
                      </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
            <div style={{ display: detail2style }}>
              <p className="app-title my-5 align_center">
                Order building & pest inspection report
              </p>
              <Row className="mt-5">
                <Col md={4}>
                  {images}
                  <div className="text-center">
                    <p className="strata-img-title">{searchProperty.address}</p>
                    <p className="strata-img-date">
                      {" "}
                      Price and estimated time will be sent to you via email
                      once order is confirmed
                    </p>
                  </div>
                </Col>
                <Col md={8} className="strata-rep-form">
                  <Form>
                    <Row className="srch_agent">
                      <FormGroup className="srch_agent">
                        <Form.Label className="contact-form-label">
                          What is the real estate agent name*
                        </Form.Label>
                      </FormGroup>
                      <Col xl={8} lg={12} md={12}>
                        <SelectSearch
                          options={realEstateAgentOption}
                          value={formData.agent_id}
                          onChange={(e) => {
                            set_first_agent_check(false);
                            set_Agent_on_list("none");
                            setFormData({
                              ...formData,
                              agent_id: e,
                              agent_first_name: "",
                              agent_email: "",
                            });
                          }}
                          search
                          disabled={first_agent_check}
                          filterOptions={fuzzySearch}
                          emptyMessage="No agent found"
                          placeholder="Search real estate agent name"
                        />
                      </Col>
                      <Col xl={4} lg={12} md={12} className="agent_not_found">
                        <input
                          type="checkbox"
                          name="noagent"
                          id="noagent"
                          checked={first_agent_check}
                          onChange={(e) => {
                            e.target.checked === false &&
                              setFormData({
                                ...formData,
                                agent_first_name: "",
                                agent_email: "",
                              });
                            setFormData({ ...formData, agent_id: "" });
                            set_first_agent_check(e.target.checked);
                            e.target.checked
                              ? set_Agent_on_list("block")
                              : set_Agent_on_list("none");
                          }}
                        />
                        <label
                          for="noagent"
                          className="form-check-label"
                          style={{ color: "#1D83FF" }}
                        >
                          {" "}
                          Real estate agent not on list
                        </label>
                      </Col>
                      <Col
                        md={6}
                        className="my-3"
                        style={{ display: Agent_on_list }}
                      >
                        <Form.Label className="contact-form-label">
                          Real estate agent name*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter real estate agent name here"
                          className="contact-form-input"
                          value={formData.agent_first_name}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              agent_first_name: e.target.value,
                            })
                          }
                        ></Form.Control>
                      </Col>
                      <Col
                        md={6}
                        className="my-3"
                        style={{ display: Agent_on_list }}
                      >
                        <Form.Label className="contact-form-label">
                          Real estate agent email*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter real estate agent email here"
                          className="contact-form-input"
                          onChange={(e) => validateRealEstateAgentEmail(e)}
                        />
                        <span className="err">{emailError4}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mt-3">
                        <FormGroup>
                          <Form.Label className="contact-form-label">
                            Name*
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your name here"
                            className="contact-form-input"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                name: e.target.value,
                              })
                            }
                            required
                          ></Form.Control>
                        </FormGroup>
                      </Col>
                      <Col md={6} className="my-3">
                        <Form.Label className="contact-form-label">
                          Email*
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email here"
                          className="contact-form-input"
                          onChange={(e) => validateEmail(e)}
                          required
                        />
                        <span className="err">{emailError}</span>
                      </Col>
                      <Col md={6} className="my-3">
                        <Form.Label className="contact-form-label">
                          Contact number*
                        </Form.Label>
                        <MobileInput
                          value={formData.number}
                          onChange={(e) => validateMobile(e)}
                        />
                        <span className="err">{mobileError}</span>
                      </Col>

                      <Col md={12} className="my-3">
                        <Form.Label className="contact-form-label">
                          Do you have a buyers agent?*
                        </Form.Label>
                        <select
                          placeholder="Choose one"
                          className="contact-form-input select_tab"
                          onChange={(e) => {
                            if (
                              e.target.value === "not" ||
                              e.target.value === "no"
                            ) {
                              set_buyer_agent_check(false);
                            }
                            setFormData({
                              ...formData,
                              do_have_buyers_agent:
                                e.target.value === "yes"
                                  ? "YES"
                                  : e.target.value === "not"
                                  ? "NO"
                                  : "no",
                              buyers_agent_id: "",
                            });
                          }}
                        >
                          <option value="no" class="hide_text">
                            Choose one
                          </option>
                          <option value="yes">Yes</option>
                          <option value="not">No</option>
                        </select>
                      </Col>
                    </Row>
                    <Row>
                      {formData.do_have_buyers_agent == "YES" ? (
                        <>
                          <Col md={12} className="my-3">
                            <Row>
                              <Col sm={8}>
                                <SelectSearch
                                  options={agentBuyerOption}
                                  value={formData.buyers_agent_id}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      buyers_agent_id: e,
                                      buyers_agent_first_name: "",
                                      buyers_agent_email: "",
                                    });
                                    set_buyer_agent_check(false);
                                  }}
                                  search
                                  disabled={buyer_agent_check}
                                  filterOptions={fuzzySearch}
                                  emptyMessage="No agent found"
                                  placeholder="Search buyers agent name"
                                />
                              </Col>
                              <Col sm={4} className="agent_not_found">
                                <input
                                  type="checkbox"
                                  name="noagent1"
                                  id="noagent1"
                                  checked={buyer_agent_check}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      buyers_agent_id: "",
                                    });
                                    set_buyer_agent_check(e.target.checked);
                                    e.target.checked
                                      ? set_buyer_agent_on_list("block")
                                      : set_buyer_agent_on_list("none");
                                  }}
                                />
                                <label
                                  for="noagent1"
                                  className="form-check-label"
                                  style={{ color: "#1D83FF" }}
                                >
                                  {" "}
                                  Buyers agent not on list
                                </label>
                              </Col>
                            </Row>
                          </Col>

                          <Col
                            md={6}
                            className="my-3"
                            style={{ display: buyer_agent_on_list }}
                          >
                            <Form.Label className="contact-form-label">
                              Buyers agent name*
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter name here"
                              className="contact-form-input"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  buyers_agent_first_name: e.target.value,
                                  buyers_agent_id: "",
                                })
                              }
                              value={formData.buyers_agent_first_name}
                            ></Form.Control>
                          </Col>
                          <Col
                            md={6}
                            className="my-3"
                            style={{ display: buyer_agent_on_list }}
                          >
                            <Form.Label className="contact-form-label">
                              Buyers agent email*
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter email here"
                              className="contact-form-input"
                              onChange={(e) => validateBuyerAgentEmail(e)}
                            ></Form.Control>
                            <span className="err">{emailError1}</span>
                          </Col>
                        </>
                      ) : formData.do_have_buyers_agent == "NO" ? (
                        <Col md={12} className="my-3">
                          <Form.Label className="contact-form-label">
                            Do you want us to recommend you one?*
                          </Form.Label>
                          <select
                            placeholder="Choose one"
                            className="contact-form-input select_tab"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                need_us_to_recommend: e.target.value,
                              })
                            }
                          >
                            <option value="NULL" class="hide_text">
                              Choose one
                            </option>
                            <option value="YES">Yes</option>
                            <option value="NO">No</option>
                          </select>
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col md={12} className="my-3">
                        <Form.Label className="contact-form-label">
                          Do you have a solicitor or a conveyancer?*
                        </Form.Label>
                        <select
                          placeholder="Choose one"
                          className="contact-form-input select_tab"
                          onChange={(e) => {
                            if (
                              e.target.value === "not" ||
                              e.target.value === "no"
                            ) {
                              set_solicitor_agent_check(false);
                            }

                            setFormData({
                              ...formData,
                              do_have_solicitor_agent:
                                e.target.value === "yes"
                                  ? "YES"
                                  : e.target.value === "not"
                                  ? "NO"
                                  : "no",
                              solicitor_agent_id: "",
                            });
                          }}
                        >
                          <option value="no" class="hide_text">
                            Choose one
                          </option>
                          <option value="yes">Yes</option>
                          <option value="not">No</option>
                        </select>
                      </Col>
                      {formData.do_have_solicitor_agent == "YES" ? (
                        <>
                          <Col md={12} className="my-3">
                            <Row>
                              <Col sm={7}>
                                <SelectSearch
                                  options={agentsociliorOption}
                                  value={formData.solicitor_agent_id}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      solicitor_agent_id: e,
                                      solicitor_agent_first_name: "",
                                      solicitor_agent_email: "",
                                    });
                                    set_solicitor_agent_check(false);
                                  }}
                                  search
                                  disabled={solicitor_agent_check}
                                  filterOptions={fuzzySearch}
                                  placeholder="Search solicitor or conveyancer name"
                                />
                              </Col>
                              <Col sm={5} className="agent_not_found">
                                <input
                                  type="checkbox"
                                  name="noagent2"
                                  id="noagent2"
                                  checked={solicitor_agent_check}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      solicitor_agent_id: "",
                                    });
                                    set_solicitor_agent_check(e.target.checked);
                                    e.target.checked
                                      ? set_solicitor_agent_on_list("block")
                                      : set_solicitor_agent_on_list("none");
                                  }}
                                />
                                <label
                                  for="noagent2"
                                  className="form-check-label"
                                  style={{ color: "#1D83FF", fontSize: "14px" }}
                                >
                                  {" "}
                                  Solicitor or conveyancer not on list
                                </label>
                              </Col>
                            </Row>
                          </Col>

                          <Col
                            md={6}
                            className="my-3"
                            style={{ display: solicitor_agent_on_list }}
                          >
                            <Form.Label className="contact-form-label">
                              Solicitor or Conveyancer name*
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter name here"
                              className="contact-form-input"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  solicitor_agent_first_name: e.target.value,
                                  solicitor_agent_id: "",
                                })
                              }
                            ></Form.Control>
                          </Col>
                          <Col
                            md={6}
                            className="my-3"
                            style={{ display: solicitor_agent_on_list }}
                          >
                            <Form.Label className="contact-form-label">
                              Solicitor or Conveyancer email*
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter email here"
                              className="contact-form-input"
                              onChange={(e) => validateSolicitorAgentEmail(e)}
                            ></Form.Control>
                            <span className="err">{emailError2}</span>
                          </Col>
                        </>
                      ) : formData.do_have_solicitor_agent === "NO" ? (
                        <Col md={12} className="my-3">
                          <Form.Label className="contact-form-label">
                            Do you want us to recommend you one?*
                          </Form.Label>
                          <select
                            placeholder="Choose one"
                            className="contact-form-input select_tab"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                need_us_to_recommend_solicitor_agent:
                                  e.target.value === "yes" ? "YES" : "NO",
                              })
                            }
                          >
                            <option value="no" class="hide_text">
                              Choose one
                            </option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col md={12} className="my-3">
                        <Form.Label className="contact-form-label">
                          Have you organised finance?*
                        </Form.Label>
                        <select
                          placeholder="Choose one"
                          className="contact-form-input select_tab"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              have_organised_finanace:
                                e.target.value === "yes"
                                  ? "YES"
                                  : e.target.value === ""
                                  ? ""
                                  : "NO",
                              like_us_to_reccomend_broker: "",
                            })
                          }
                        >
                          <option value="" class="hide_text">
                            Choose one
                          </option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </Col>
                      {formData.have_organised_finanace == "NO" ? (
                        <Col md={12} className="my-3">
                          <Form.Label className="contact-form-label">
                            Would you like us to recommend you a affiliated
                            broker?*
                          </Form.Label>
                          <select
                            placeholder="Choose one"
                            className="contact-form-input select_tab"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                like_us_to_reccomend_broker:
                                  e.target.value === "yes"
                                    ? "YES"
                                    : e.target.value === ""
                                    ? ""
                                    : "NO",
                              })
                            }
                          >
                            <option value="" class="hide_text">
                              Choose one
                            </option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </Col>
                      ) : (
                        ""
                      )}

                      {/* <Col md={12} className="my-3">
                                                <Form.Label className="contact-form-label">Address*</Form.Label>
                                                <Form.Control as={Autocomplete} types={["address"]} componentRestrictions={{ country: "au" }} type='text' placeholder="Enter address to inspect" className='contact-form-input'
                                                    onPlaceSelected={(place, e) => {

                                                        setFormData({
                                                            ...formData,

                                                            building_address: place.formatted_address
                                                        })
                                                    }

                                                    }
                                                    onChange={
                                                        (e) => setFormData({
                                                            ...formData,
                                                            building_address: e.target.value
                                                        })
                                                    }
                                                ></Form.Control>
                                            </Col> */}

                      <Col md={12} className="my-3">
                        <Form.Label className="contact-form-label">
                          Report required by*
                        </Form.Label>
                        <Form.Control
                          type="date"
                          min={moment(date).format("YYYY-MM-DD")}
                          placeholder="Select Date"
                          className="contact-form-input"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              inspection: e.target.value,
                            })
                          }
                          required
                        ></Form.Control>
                      </Col>

                      <Col md={12} className="my-3">
                        <Form.Label className="contact-form-label">
                          What is the purpose of the property you are interested
                          in purchasing?*
                        </Form.Label>
                        <br />
                        <div className="d-flex set-radio-mobile ">
                          <Form.Check
                            className="app-text strata-redio circle-center"
                            name="live-type"
                            inline
                            label="To live in"
                            type="radio"
                            id="To live in"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                reason_of_report: e.target.id,
                              })
                            }
                          ></Form.Check>
                          <Form.Check
                            className="app-text strata-redio circle-center"
                            name="live-type"
                            inline
                            label="To use as an investment"
                            type="radio"
                            id="To use as an investment"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                reason_of_report: e.target.id,
                              })
                            }
                          ></Form.Check>
                          <Form.Check
                            className="app-text strata-redio circle-center"
                            name="live-type"
                            inline
                            label="Currently selling"
                            type="radio"
                            id="Currently selling"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                reason_of_report: e.target.id,
                              })
                            }
                          ></Form.Check>
                        </div>
                      </Col>
                    </Row>
                    <CompanyInput
                      formData={formData}
                      setFormData={setFormData}
                    />
                    <Form.Check
                      className=" agree-checkbox app-text"
                      name="purchage-agree"
                      label="I agree to terms and conditions*"
                      type="checkbox"
                      id="agree"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          agree: e.target.checked,
                        })
                      }
                    ></Form.Check>

                    <p style={{ color: "red", textAlign: "center" }}>
                      {" "}
                      {errName}{" "}
                    </p>
                    <Row className="justify-content-center mx-0 px-0">
                      <Button
                        className="app-text navigation_button"
                        style={{
                          borderRadius: "1.5rem",
                          width: "100%",
                          color: "#fff",
                        }}
                        onClick={() => {
                          setDetail1Style("block");
                          setFormDataStyle("none");
                        }}
                      >
                        Go back
                      </Button>

                      <Button
                        className="app-text navigation_button"
                        style={{
                          borderRadius: "1.5rem",
                          width: "100%",
                          color: "#fff",
                        }}
                        onClick={(e) => add_building_report(e)}
                      >
                        Order Now
                      </Button>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
            {/* </Route>
                    </Switch> */}
          </div>
          <ToastContainer toastClassName="border border-grey" />
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default OrderInspection;
