import React from 'react'

function AgentPortal() {
    return (
        <div>
            THis is agent portal
        </div>
    )
}

export default AgentPortal
