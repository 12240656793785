import React, { useState, useRef } from 'react';
import { Button, Col, Container, Form, FormGroup, Image, Row } from 'react-bootstrap'
import Footer from '../components/footer'
import Header from '../components/header'
import validator from 'validator'
import BgPattern1 from '../assets/images/bg/pattern/hero_bg_pattern.svg';
import FooterBgPattern from '../assets/images/bg/pattern/footer_bg_pattern.svg';
import HeroImage from '../assets/images/svg/Submit.png';
import { Link } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import { jsPDF } from "jspdf";
import { useHistory } from 'react-router-dom';
import axios from 'axios'
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import MobileInput from '../components/common/MobileInput';


export default function PropertyInsurance() {
    var minm = 100000000000;
    var maxm = 999999999999;
    var code = Math.floor(Math.random() * (maxm - minm + 1)) + minm;

    const [emailError, setEmailError] = useState('')
    const [btnCheck, setbtnCheck] = React.useState(true);
    const [mobileError, setmobileError] = useState('')

    const [formData, setFormData] = React.useState({
        insurance_type: "P",
        name: "",
        email: "",
        mobile: "",
        replacement: "",
        replied: false,
        articles: "",
        address: "",
        value: "",
        lat: "",
        lng: "",
        Insurer: ""
    })
    const [tc, set_tc] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault();
        toast.dismiss()
        if (formData.name.trim() === "") {
            toast.error('name is required.')
            return false;
        }
        if (formData.email === "") {
            toast.error("Email is required.")
            return false;
        }
        if (formData.mobile === "") {
            toast.error('Mobile is required.')
            return false;
        }
        if (formData.replacement === "") {
            toast.error('Home replacement value is required.')
            return false;
        }
        if (formData.replacement < 1) {
            toast.error('Home replacement value should be greater than zero.')
            return false;
        }
        if (formData.articles === "") {
            toast.error('Valuable article field is required.')
            return false;
        }
        if (formData.address === "") {
            toast.error('Address is required.')
            return false;
        }
        if (formData.value === "") {
            toast.error('Contents value is required.')
            return false;
        }
        if (formData.value < 1) {
            toast.error('Common area content value should be greater than zero.')
            return false;
        }
        if (formData.Insurer === "") {
            toast.error('Current insurer is required.')
        }
        if (tc === false) {
            toast.error('Please accept terms and conditions.')
            return false;
        }
        var data = {
            "insurance_type": formData.insurance_type,
            "name": formData.name,
            "email_id": formData.email,
            "phone_number": formData.mobile,
            // "is_replied_or_not": formData.replied,
            "home_replacement_value": formData.replacement,
            "valuable_article_over_price": formData.articles,
            "contents_value": formData.value,
            "address": formData.address,
            "latitude": formData.lat,
            "longitude": formData.lng,
            "current_insurer": formData.Insurer
        }

        await axios.post(process.env.REACT_APP_NODE_BACKEND_API_URL + '/insurance', data, {
            headers: {
                "Authorization": "vw7kddgha5j30fz1r7ev"
            }
        }).then((response) => {
            Swal.fire(
                '',
                'Property insurance request made successfully',
                'success'
            )
            history.push('/')

        }).catch((error) => {
            console.log({ error })
        })

        // const res = await axios.post(
        //     process.env.REACT_APP_BACKEND_API_URL + "/test_api.php",
        //     {
        //         action:"property_insurence_send_mail",
        //         name:formData.name,
        //         email:formData.email,
        //         mobile:formData.mobile,
        //         replacement:formData.replacement,
        //         articles:formData.articles,
        //         address:formData.address,
        //         value:formData.value,
        //         Insurer:formData.Insurer

        //     }
        // )

        // history.push(
        //     {
        //         pathname:"../success",
        //         state:{
        //             order:'Property Insurance', number:code, text:'A quote will be sent to you via email please refer to your reference number'
        //         }
        //     }
        // );
    }

    async function validate(evt) {

        var theEvent = evt || window.event;

        if (theEvent.type === 'paste') {
            key = window.event.clipboardData.getData('text/plain');
        } else {
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }


    const validateEmail = (e) => {
        var email = e.target.value

        if (validator.isEmail(email)) {
            setEmailError('')
            setFormData({
                ...formData,
                email: e.target.value.toLowerCase()
            })
            setbtnCheck(false)
        } else {
            setEmailError('Invalid')
            setbtnCheck(true)
        }
    }
    const validateMobile = (e) => {
        var mobile = e

        if (mobile && validator.isMobilePhone(mobile, 'any')) {
            setmobileError('')
            setFormData({
                ...formData,
                mobile: e.target.value
            })
            setbtnCheck(false)
        } else {
            setmobileError('Invalid')
            setbtnCheck(true)
        }
    }

    const history = useHistory();

    return (
        <div>
            <Helmet>
                <title name="keywords" content="comprehensive database of strata reports, building and pest reports, valuations, depreciation reports, and QS reports in one place">Protect your home with industry-leading property insurance</title>
                <meta name="description" content="For when something goes wrong, secure your home and its contents with comprehensive property insurance from the Propti network." />
            </Helmet>
            <Image src={BgPattern1} className="position-absolute w-100" style={{ zIndex: "-1" }} alt="img"></Image>
            <Container>
                <Header />
                <div className="text-center set_page_title">
              <h1 className="app-title" name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">Order property insurance</h1>
                </div>
                <Row className='insurance-block'>
                    <Col md={4}>
                        <Image src={HeroImage} className="position-asbolute w-100 set_hero_bg" alt="img"></Image>
                        {/* <p className="hero-title my-5 text-center">A quote will be sent to you via email</p> */}
                        <p className="hero-title my-5 text-center">A broker will be in contact with you via email once submitted</p>
                    </Col>
                    <Col md={8} className="insurance-form">
                        <Form >
                            <FormGroup>
                                <Form.Label className="contact-form-label">Name*</Form.Label>
                                <Form.Control type='text' placeholder="Enter your name here" className='contact-form-input'

                                    onChange={
                                        (e) => setFormData({
                                            ...formData,
                                            name: e.target.value
                                        })
                                    }
                                ></Form.Control>
                            </FormGroup>

                            <Row>
                                <Col lg={6} md={12} className="my-3">
                                    <Form.Label className="contact-form-label">Email*</Form.Label>
                                    <Form.Control type='email' placeholder="Enter your email here" className='contact-form-input'

                                        onChange={
                                            (e) => validateEmail(e)
                                        }
                                    ></Form.Control>
                                    <span className="err">{emailError}</span>
                                </Col>
                                <Col lg={6} md={12} className="my-3">
                                    <Form.Label className="contact-form-label">Mobile number*</Form.Label>
                                    <MobileInput value={formData.mobile} onChange={e => validateMobile(e)} />
                                    <span className="err">{mobileError}</span>
                                </Col>
                                <Col lg={6} md={12} className="my-3 fixed_value">
                                    <FormGroup>
                                        <Form.Label className="contact-form-label">Home replacement value*</Form.Label>
                                        <Form.Control type='text' maxlength="8" className='contact-form-input'
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    replacement: e.target.value
                                                })
                                            }
                                            onKeyPress={
                                                (e) => validate(e)
                                            }
                                            onBlur={
                                                (e) => {
                                                    var cnt = e.target.value.length;

                                                    e.target.value = cnt > 3 ? e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : e.target.value

                                                }
                                            }
                                        ></Form.Control>
                                        <span class="unit1">$</span>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={12} className="my-3 fixed_value">
                                    <Form.Label className="contact-form-label">Any valuable articles worth $25k+? *</Form.Label>

                                    <select placeholder="Choose one" className='contact-form-input select_tab1'
                                        onChange={
                                            (e) => setFormData({
                                                ...formData,
                                                articles: e.target.value
                                            })
                                        }
                                    >
                                        <option value='' class='hide_text'>Choose one</option>
                                        <option value='YES'>Yes</option>
                                        <option value='NO'>No</option>
                                    </select>

                                </Col>
                            </Row>
                            <FormGroup>
                                <Form.Label className="contact-form-label">Address* </Form.Label>
                                <Form.Control as={Autocomplete} types={["address"]} componentRestrictions={{ country: "au" }} type='text' placeholder="Enter address for property insurance" className='contact-form-input'
                                    onPlaceSelected={(place, e) => {
                                        var latitude = place.geometry.location.lat()
                                        var longitude = place.geometry.location.lng()
                                        setFormData({
                                            ...formData,
                                            lat: latitude,
                                            lng: longitude,
                                            address: place.formatted_address
                                        })
                                    }
                                    }
                                    onChange={
                                        (e) => setFormData({
                                            ...formData,
                                            address: e.target.value
                                        })
                                    }
                                ></Form.Control>
                            </FormGroup>
                            <Row>
                                <Col lg={6} md={12} className="my-3 fixed_value">
                                    <FormGroup>
                                        <Form.Label className="contact-form-label">Contents value*</Form.Label>
                                        <Form.Control type='text' placeholder="" maxlength="8" className='contact-form-input'
                                            onChange={
                                                (e) => setFormData({
                                                    ...formData,
                                                    value: e.target.value
                                                })
                                            }
                                            onKeyPress={
                                                (e) => validate(e)
                                            }
                                        ></Form.Control>
                                        <span class="unit1">$</span>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={12} className="my-3">
                                    <Form.Label className="contact-form-label">Current insurer*</Form.Label>
                                    <Form.Control type='text' placeholder="Enter current insurer*" className='contact-form-input'
                                        onChange={
                                            (e) => setFormData({
                                                ...formData,
                                                Insurer: e.target.value
                                            })
                                        }

                                    ></Form.Control>
                                </Col>
                            </Row>
                            <Form.Check className="agree-checkbox app-text" name='purchage-agree' label='I agree to terms and conditions' type='checkbox' id='agree'
                                onChange={
                                    (e) => set_tc(e.target.checked)
                                }
                            ></Form.Check>
                            {/* <Form.Check className="agree-checkbox app-text" name='purchage-agree' label='I agree to terms and conditions' type='checkbox' id='agree' ></Form.Check> */}
                            <Row className="justify-content-center mx-0 px-0 row btnrow" style={{ alignItems: "center" }}>

                                <Button className="navigation_button app-text" style={{ borderRadius: '1.5rem', width: '100%' }}>Go back</Button>

                                <Button type="submit" className="navigation_button app-text" style={{ borderRadius: '1.5rem', width: '100%' }} onClick={(e) => handleSubmit(e)}>Submit for quote</Button>

                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}
