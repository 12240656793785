import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Row, Form, Button, Accordion } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Pagination from "react-js-pagination";

import '../assets/css/blog.css'
import Header from '../components/header'
import Footer from '../components/footer'
import BgPattern3 from '../assets/images/bg/pattern/hero_bg_pattern.svg'
import DropDownIcon from '../assets/images/icons/navbar_dropdown.svg'
import BlogCard from '../components/blog/blogcard'
import { getAllblogs } from '../API/blog.api';
import axios from 'axios';
import moment from 'moment';
// import { GoogleMap, LoadScript } from '@react-google-maps/api';

function Blog() {
    /**
     * Declaration State Variable 
     */
    const [blogs, setBlogs] = React.useState([]);
    const [loader, set_loader] = React.useState(false)
    // const [filteredData, setFilteredData] = React.useState(blogs);
    const [searchString, setSearchString] = React.useState('');
    const [activePage, setactivePage] = React.useState(1);
    const [countPerPage, setCountPerPage] = React.useState(10)
    const [search, set_search] = useState('')
    const [type, set_type] = useState('')
    const [total_count, set_total_count] = useState(0)
    const [search_page, set_search_page] = useState(0)
    const [categories, set_categories] = useState([])
    /**
     * Get intitial Data form backend API
     
     */
    var history = useHistory()
    let query = new URLSearchParams(window.location.search)

    
    useEffect(
    
        async () => {
            set_loader(true)

            var typeToCheck = '';
            var id = query?.get("cat");
            if(id){
                typeToCheck = id;
            }
            else{
                typeToCheck = type;
            }
            
            const blogs = await axios.get(process.env.REACT_APP_NODE_BACKEND_API_URL + `/article/?search=${search}&article_type=${typeToCheck}&size=10&pageNo=0`, {
                headers: {
                    "Authorization": "vw7kddgha5j30fz1r7ev"
                }
            })
            set_total_count(blogs.data.result_count)
            setBlogs(blogs.data.data);
            set_loader(false)

            const category = await axios.get(process.env.REACT_APP_NODE_BACKEND_API_URL + '/article/getCategory', {
                headers: {
                    "Authorization": "vw7kddgha5j30fz1r7ev"
                }
            })
            set_categories(category.data.data)
        }, [type]
    )


    
    async function handlePageChange(pageNumber) {
        set_search_page(pageNumber - 1)
        setactivePage(pageNumber)
        const blogs = await axios.get(process.env.REACT_APP_NODE_BACKEND_API_URL + `/article/?search=${search}&article_type=${type}&size=10&pageNo=${pageNumber - 1}`, {
            headers: {
                "Authorization": "vw7kddgha5j30fz1r7ev"
            }
        })
        setBlogs(blogs.data.data);
    }


    function handleCategory(e, id) {
        history.push('/blog')
        let menuLiElements = document.querySelectorAll('.wall-nav-link ');
        Array.from(menuLiElements).forEach((element, index) => {
            element.classList.remove("activeCategory");
        })
        e.target.classList.add("activeCategory")
        set_type(id)
    }

    async function handleSearch(e) {
        e.preventDefault()

        const blogs = await axios.get(process.env.REACT_APP_NODE_BACKEND_API_URL + `/article/?search=${search}&article_type=${type}&size=10&pageNo=${search_page}`, {
            headers: {
                "Authorization": "vw7kddgha5j30fz1r7ev"
            }
        })
        setBlogs(blogs.data.data);

    }
    /**
     * Rendering views
     */
    console.log(query.get("blogs--->",blogs))
    return (
        <>
         
        
            {loader === true &&
                <div className='loader-main-div'>
                    <div className='loader-second'>
                        <div class="loader"></div></div>
                </div>}
            <div></div>
            <div>
                <Image src={BgPattern3} className="position-absolute w-100" style={{ zIndex: '-1' }} alt={"alt"}></Image>

                <Container>
                    <Header />
                    <div className="text-center set_page_title">
                        <h1 className="login-title" name="keywords" content="Fastest and easiest way to get strata reports, building and pest reports, valuations, depreciation reports, QS reports, and more.">Articles</h1>
                    </div>
                    <Row>
                        <Col lg={4}>
                            <Form>
                                <div className="position-relative d-flex align-items-center search-blog">
                                    <Button
                                        className="search-button-small"
                                        onClick={(e) => handleSearch(e)}
                                    >
                                        Search
                                    </Button>
                                    <Form.Control
                                        type='text'
                                        placeholder='Search here'
                                        name="search"
                                        id="search"
                                        className="serch-box"
                                        onChange={(e) => { set_search(e.target.value) }}
                                        value={search}
                                    />
                                </div>
                            </Form>
                            <Accordion className="blog-category-toggle" defaultActiveKey="0">
                                <Accordion.Toggle as={Button} variant="link" eventKey="0" className="toggle-button">
                                    <div className="d-flex align-items-center mt-4">
                                        <Image src={DropDownIcon} alt="img"></Image>
                                        <p className="ml-3 my-0 app-text blog-toggle-title"><b>Category</b></p>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <div className="ml-5">
                                        <p className="my-2"><Link onClick={(e) => {
                                            let menuLiElements = document.querySelectorAll('.wall-nav-link ');
                                            Array.from(menuLiElements).forEach((element, index) => {
                                                element.classList.remove("activeCategory");
                                            })
                                            set_type('')
                                        } } className="text-decoration-none wall-nav-link app-text">All</Link></p>
                                    {categories.map((item) =>
                                        <p style={{ cursor: 'pointer' }} className="my-2"><a onClick={(e) => handleCategory(e, item._id)} className={query?.get("cat") === item._id ? "text-decoration-none wall-nav-link app-text activeCategory" : "text-decoration-none wall-nav-link app-text"}>{item.category_name}</a></p>
                                    )}

                                    {/* <p className="my-2"><Link to="#" className="text-decoration-none wall-nav-link app-text">Marketing</Link></p>
                                    <p className="my-2"><Link to="#" className="text-decoration-none wall-nav-link app-text">Digital Marketing</Link></p>
                                    <p className="my-2"><Link to="#" className="text-decoration-none wall-nav-link app-text">SEO Marketing</Link></p>
                                    <p className="my-2"><Link to="#" className="text-decoration-none wall-nav-link app-text">Travelling Trip</Link></p>
                                    <p className="my-2"><Link to="#" className="text-decoration-none wall-nav-link app-text">Website Design</Link></p> */}
                                </div>
                            </Accordion.Collapse>
                        </Accordion>
                    </Col>
                    <Col lg={8} >
                        <Row>
                            {
                                blogs.map(
                                    (item) => (
                                        <Col md={4} key={item.id} className="blog-articles">
                                            <BlogCard
                                                title={item.article_title}
                                                category={item.category._id}
                                                slug={item.slug}
                                                date={moment(item.createdAt).format("YYYY-MM-DD")}
                                                content={item.article_description}
                                                image={item.article_image}
                                                id={item.article_id}
                                            />
                                        </Col>
                                    )
                                )
                            }
                        </Row>
                        {blogs.length === 0 &&
                            <p className="text-center mt-5" style={{ fontSize: "27px" }}>No data found</p>}
                        {blogs.length !== 0 &&
                            <div className="test d-flex justify-content-center app-text blog-pagenation mt-5">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={total_count}
                                    pageRangeDisplayed={5}
                                    hideFirstLastPages={true}
                                    onChange={(e) => handlePageChange(e)}
                                />
                            </div>}
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
        {/* </LoadScript> */}
        </>
    )
}

export default Blog
