import React from "react";
import { Col, Row, Image } from "react-bootstrap";

// import reasources
import "../../../assets/css/brand.css";

import Brand3 from "../../../assets/images/logo/brands/3.png";
import Brand4 from "../../../assets/images/logo/brands/4.png";
import Brand5 from "../../../assets/images/logo/brands/2.png";
import Brand8 from "../../../assets/images/logo/brands/6.png";
import Brand7 from "../../../assets/images/logo/brands/9.png";
import Brand9 from "../../../assets/images/logo/brands/brand111.png";
// import Brand6 from "../../../assets/images/logo/brands/patch.png";
import Brand11 from "../../../assets/images/logo/brands/11.png";
import Brand13 from "../../../assets/images/logo/brands/13.png";
import Brand12 from "../../../assets/images/logo/brands/14.png";
import Brand15 from "../../../assets/images/logo/brands/15.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Brand() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className="brand-root brand_p">
      <Carousel
        swipeable={false}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={false}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-60-px"
      >
        {/* <Image src={Brand1} className="brand-logo" alt="img"></Image> */}
        <Image src={Brand3} className="brand-logo" alt="img"></Image>
        <Image src={Brand5} className="brand-logo" alt="img"></Image>
        {/* <Image src={Brand4} className="brand-logo" alt="img"></Image> */}
        <Image src={Brand7} className="brand-logo" alt="img"></Image>
        <Image src={Brand8} className="brand-logo" alt="img"></Image>
        <Image src={Brand9} className="brand-logo" alt="img"></Image>
        {/* <Image src={Brand6} className="brand-logo" alt="img"></Image> */}
        <Image src={Brand11} className="brand-logo" alt="img"></Image>
        <Image src={Brand12} className="brand-logo" alt="img"></Image>
        <Image src={Brand15} className="brand-logo bica-logo" alt="img"></Image>
        <Image
          src={Brand13}
          className="brand-logo property-whispers-logo"
          alt="img"
        ></Image>
      </Carousel>
      <div className="custom_spacing">
        <h2>Looking for where to find property reports?</h2>
        <p>
          Propti is your home of Property Reports—a one-stop shop for organising any property report across Australia. Whether you’re a buyer, conveyancer, buyer agent or homeowner, we will do the due diligence you need to streamline the property process. With our extensive network of surveyors, inspectors and valuers, we can help you organise prompt and comprehensive strata reports, building and pest inspections, valuations, surveyors and more.
        </p>
      </div>
    </div>
  );
}

export default Brand;
